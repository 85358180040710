<script setup>
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
const restoStore = useRestoGLobalStore()
</script>

<template>
  <ul role="list" class="-mx-2 space-y-1">
    <li v-for="category in restoStore.MENU_DATA?.categories" :key="category.category_id">
      <a
        :href="`#category-id-${category.category_id}`"
        :data-id="category.category_id"
        :class="[
          category.current
            ? 'bg-gray-50 dark:bg-gray-800 text-indigo-600 dark:text-indigo-400'
            : 'text-gray-700 dark:text-gray-300 hover:text-indigo-600 dark:hover:text-indigo-400 hover:bg-gray-50 dark:hover:bg-gray-800',
          'group flex gap-x-3 rounded-md p-2 pl-3 text-sm leading-6 font-semibold transition-colors duration-200'
        ]"
      >
        {{ category.category_name }}
      </a>
    </li>
  </ul>
</template>
