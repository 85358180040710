<script setup>
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { inject } from 'vue'
const restoStore = useRestoGLobalStore()
const IS_MOBILE_APP = inject('IS_MOBILE_APP')
</script>
<template>
  <div
    v-if="restoStore?.restoInfo?.active_website_offers.length > 0 && !IS_MOBILE_APP"
    class="md:p-3 p-2 bg-red-500 dark:bg-indigo-700 text-white md:text-lg text-base md:col-span-6 text-center rounded-md shadow-sm transition-colors duration-200"
  >
    <div v-for="(offer, index) in restoStore?.restoInfo?.active_website_offers" :key="index">
      {{ offer }}
    </div>
  </div>
  <div
    v-else-if="restoStore?.restoInfo?.active_mobileapp_offers.length > 0 && IS_MOBILE_APP"
    class="md:p-3 p-2 bg-red-500 dark:bg-indigo-700 text-white md:text-lg text-base md:col-span-6 text-center rounded-md shadow-sm transition-colors duration-200"
  >
    <div v-for="(offer, index) in restoStore?.restoInfo?.active_mobileapp_offers" :key="index">
      {{ offer }}
    </div>
  </div>

  <div class="text-sm md:p-3 p-2 bg-white dark:bg-gray-800 mb-3 border border-gray-200 dark:border-gray-700 rounded-md shadow-sm dark:text-gray-300 transition-colors duration-200">
    If you suffer from an allergy that could endanger your health we strongly advise that you
    contact us before placing your order. Please refer to the contact us page for our contact
    details.
  </div>
</template>
