<script>
import AppLayout from '@/layouts/AppLayout.vue'
import { ref, onBeforeMount } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { required, sameAs, minLength } from '@vuelidate/validators'
import { CheckCircleIcon, XCircleIcon, LockClosedIcon, ExclamationCircleIcon } from '@heroicons/vue/20/solid'
import { verifyTokenFn, resetPasswordFn } from '@/services/api/restApiCalls'
import { computed } from 'vue'
import LoadingSpinner from '@/components/common/LoadingSpinner/LoadingSpinner.vue'

export default {
  name: 'ResetPasswordView',
  components: {
    AppLayout,
    CheckCircleIcon,
    XCircleIcon,
    LockClosedIcon,
    ExclamationCircleIcon,
    LoadingSpinner
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const newPassword = ref('')
    const confirmPassword = ref('')
    const submitted = ref(false)
    const resetPassToken = ref(route.params.token)
    const invalidToken = ref(false)
    const passwordReset = ref(false)
    const errorMsg = ref('')
    const isLoading = ref(false)

    const validationRules = computed(() => {
      return {
        newPassword: {
          required,
          minLength: minLength(7)
        },
        confirmPassword: {
          required,
          sameAs: sameAs(newPassword)
        }
      }
    })

    const v$ = useVuelidate(validationRules, {
      newPassword,
      confirmPassword
    })

    const handleVerifyToken = async () => {
      let tokenFormData = new FormData()
      tokenFormData.append('token', resetPassToken.value)
      const verify_token_res = await verifyTokenFn(tokenFormData)
      if (verify_token_res?.status == 200) {
        invalidToken.value = false
      } else {
        invalidToken.value = true
      }
    }

    const handleResetPassword = async () => {
      submitted.value = true
      v$.value.$validate()
      if (v$.value.$invalid) {
        return
      }
      if (isLoading.value) return
      isLoading.value = true
      try {
        let resetPassFormData = new FormData()
        resetPassFormData.append('password', newPassword.value)
        resetPassFormData.append('confirm_password', confirmPassword.value)
        resetPassFormData.append('token', resetPassToken.value)
        const reset_password_res = await resetPasswordFn(resetPassFormData)
        if (reset_password_res?.status == 400) {
          errorMsg.value = reset_password_res?.status_msg
        } else {
          passwordReset.value = true
        }
      } catch (err) {
        console.error(err)
      }
      isLoading.value = false
    }

    onBeforeMount(() => {
      handleVerifyToken()
    })

    return {
      router,
      v$,
      newPassword,
      confirmPassword,
      submitted,
      invalidToken,
      passwordReset,
      handleResetPassword,
      errorMsg,
      isLoading
    }
  }
}
</script>
<template>
  <AppLayout>
    <div
      class="flex flex-col items-center justify-center sm:px-6 px-3 sm:py-12 py-6 mx-auto pt:mt-0"
    >
      <!-- Card -->
      <div class="w-full max-w-md p-8 bg-white dark:bg-gray-800 rounded-xl shadow-lg transform transition-all duration-200 hover:shadow-xl">
        <div v-if="passwordReset" class="text-center">
          <div class="mx-auto flex items-center justify-center h-20 w-20 rounded-full bg-green-100 dark:bg-green-900 mb-6">
            <CheckCircleIcon class="h-10 w-10 text-green-600 dark:text-green-300" aria-hidden="true" />
          </div>
          <h3 class="text-2xl font-bold text-gray-900 dark:text-gray-100">Success!</h3>
          <p class="mt-4 text-base text-gray-600 dark:text-gray-400">
            Your password has been successfully reset.
          </p>
          <div class="mt-8">
            <button
              type="button"
              class="w-full justify-center rounded-lg border border-transparent bg-violet-700 py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-violet-800 dark:hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 transition-all duration-200 transform hover:-translate-y-0.5"
              @click="$router.push({ name: 'login' })"
            >
              Sign In
            </button>
          </div>
        </div>

        <div v-if="invalidToken" class="text-center">
          <div class="mx-auto flex items-center justify-center h-20 w-20 rounded-full bg-red-100 dark:bg-red-900/30 mb-6">
            <XCircleIcon class="h-10 w-10 text-red-600 dark:text-red-400" aria-hidden="true" />
          </div>
          <h3 class="text-2xl font-bold text-gray-900 dark:text-gray-100">Invalid Link</h3>
          <p class="mt-4 text-base text-gray-600 dark:text-gray-400">
            This password reset link has expired or has already been used.
          </p>
          <div class="mt-8">
            <button
              type="button"
              class="w-full justify-center rounded-lg border border-transparent bg-violet-700 py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-violet-800 dark:hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 transition-all duration-200 transform hover:-translate-y-0.5"
              @click="$router.push({ name: 'forgotPassword' })"
            >
              Request New Link
            </button>
          </div>
        </div>

        <div v-if="!invalidToken && !passwordReset">
          <div class="text-center mb-8">
            <h2 class="text-3xl font-bold text-gray-900 dark:text-gray-100">Create New Password</h2>
            <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
              Your password must be different from previously used passwords
            </p>
          </div>

          <div v-if="errorMsg" class="mb-6 p-4 rounded-lg bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 dark:border-red-400 transition-all duration-200">
            <div class="flex items-start">
              <div class="flex-shrink-0 pt-0.5">
                <ExclamationCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div class="ml-3">
                <div class="text-sm font-medium text-red-600 dark:text-red-400">{{ errorMsg }}</div>
              </div>
            </div>
          </div>

          <form class="space-y-6" @submit.prevent="handleResetPassword">
            <div>
              <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                >New Password</label
              >
              <div class="relative">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <LockClosedIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                </div>
                <input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="••••••••"
                  autocomplete="new-password"
                  :class="{ 'border-red-600 dark:border-red-500 focus:border-red-600 focus:ring-red-200 dark:focus:ring-red-800/20': submitted && v$.newPassword.$error }"
                  class="block w-full appearance-none rounded-lg border border-gray-300 dark:border-gray-600 pl-10 px-4 py-3 placeholder-gray-400 dark:placeholder-gray-500 shadow-sm focus:border-violet-500 focus:ring focus:ring-violet-200 dark:focus:ring-violet-800/20 focus:ring-opacity-50 focus:outline-none text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 transition-all duration-200"
                  v-model="newPassword"
                />
              </div>
              <div
                v-for="(error, index) of v$.newPassword.$errors"
                :key="index"
                class="mt-1 text-sm font-medium text-red-600 dark:text-red-400"
              >
                {{ error.$message }}
              </div>
              <p v-if="!v$.newPassword.$error" class="mt-1 text-xs text-gray-500 dark:text-gray-400">
                Password must be at least 7 characters long
              </p>
            </div>
            
            <div>
              <label for="confirmPassword" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                >Confirm Password</label
              >
              <div class="relative">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <LockClosedIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                </div>
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  placeholder="••••••••"
                  autocomplete="new-password"
                  :class="{ 'border-red-600 dark:border-red-500 focus:border-red-600 focus:ring-red-200 dark:focus:ring-red-800/20': submitted && v$.confirmPassword.$error }"
                  class="block w-full appearance-none rounded-lg border border-gray-300 dark:border-gray-600 pl-10 px-4 py-3 placeholder-gray-400 dark:placeholder-gray-500 shadow-sm focus:border-violet-500 focus:ring focus:ring-violet-200 dark:focus:ring-violet-800/20 focus:ring-opacity-50 focus:outline-none text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 transition-all duration-200"
                  v-model="confirmPassword"
                />
              </div>
              <div
                v-for="(error, index) of v$.confirmPassword.$errors"
                :key="index"
                class="mt-1 text-sm font-medium text-red-600 dark:text-red-400"
              >
                {{ error.$message }}
              </div>
            </div>

            <div class="pt-2">
              <button
                type="submit"
                class="w-full justify-center rounded-lg border border-transparent bg-violet-700 py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-violet-800 dark:hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 transition-all duration-200 transform hover:-translate-y-0.5"
              >
                Reset Password
              </button>
            </div>

            <div class="text-center mt-4">
              <router-link
                class="inline-block w-full py-2 text-center font-medium text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white transition-colors duration-200"
                :to="{ name: 'login' }"
              >
                Cancel
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </AppLayout>
  <LoadingSpinner :isLoading="isLoading" />
</template>
