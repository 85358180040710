<script>
import { ref, computed } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { CheckCircleIcon } from '@heroicons/vue/20/solid'
import { UserIcon, EnvelopeIcon, ChatBubbleLeftRightIcon, PencilSquareIcon } from '@heroicons/vue/24/outline'
import { contactFn } from '@/services/api/restApiCalls'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useRouter } from 'vue-router'
import AppLayout from '@/layouts/AppLayout.vue'
export default {
  components: {
    CheckCircleIcon,
    UserIcon,
    EnvelopeIcon,
    ChatBubbleLeftRightIcon,
    PencilSquareIcon,
    AppLayout
  },
  props: ['bgcolor'],
  setup(props) {
    // const authStore = useAuthGlobalStore()
    const restoStore = useRestoGLobalStore()
    const router = useRouter()

    const name = ref('')
    const emailId = ref('')
    const subject = ref('')
    const message = ref('')
    const submitted = ref(false)
    const contactInfo = ref({})
    
    // Use a fallback color if props.bgcolor is not provided
    const btnBgColor = ref(props.bgcolor || '#4f46e5')

    const validationRules = computed(() => {
      let rules = {
        name: { required },
        emailId: {
          required,
          email
        },
        subject: { required },
        message: { required }
      }
      return rules
    })
    const v$ = useVuelidate(validationRules, {
      name,
      emailId,
      subject,
      message
    })
    const handleContactForm = async () => {
      submitted.value = true
      const isValid = await v$.value.$validate()
      if (!isValid) {
        return
      }
      const currentDate = new Date().toISOString().split('T')[0] //'YYYY-MM-DD' format
      const contactUsCaptcha = `beluga-contact-${currentDate}`
      const base64EncodedValue = btoa(contactUsCaptcha) //Encoded signupCaptcha
      const contactFormData = new FormData()
      contactFormData.append('name', name.value)
      contactFormData.append('email', emailId.value)
      contactFormData.append('subject', subject.value)
      contactFormData.append('message', message.value)
      contactFormData.append('beluga_captcha', base64EncodedValue)
      try {
        const contact_info = await contactFn(restoStore?.RESTO_ID, contactFormData)
        contactInfo.value = contact_info
      } catch (error) {
        console.error('Error submitting contact form:', error)
      }
    }

    return {
      name,
      emailId,
      subject,
      message,
      submitted,
      contactInfo,
      v$,
      router,
      restoStore,
      btnBgColor,
      handleContactForm
    }
  }
}
</script>
<template>
  <AppLayout>
  <div class="flex flex-col items-center justify-center mx-auto transition-colors duration-300 py-8">
    <div class="w-full max-w-2xl p-6 space-y-8 sm:p-8 bg-white dark:bg-gray-700 rounded-lg shadow-lg transition-colors duration-300 border border-gray-100 dark:border-gray-600">
      <div v-if="contactInfo?.status == 200" class="text-center py-8">
        <div class="inline-flex items-center justify-center h-20 w-20 rounded-full bg-green-100 dark:bg-green-900 mb-4">
          <CheckCircleIcon class="h-12 w-12 text-green-500 dark:text-green-400" aria-hidden="true" />
        </div>
        <h3 class="text-2xl font-bold text-gray-800 dark:text-gray-100 mt-4 mb-2 transition-colors duration-300">Thank you for getting in touch!</h3>
        <p class="text-gray-600 dark:text-gray-300">We will get back to you as soon as possible.</p>
      </div>
      <template v-else>
        <div class="sm:mx-auto sm:w-full">
          <div class="text-center mb-8">
            <h3 class="text-2xl font-bold text-gray-800 dark:text-gray-100 transition-colors duration-300">Contact Us</h3>
            <p class="mt-2 text-gray-600 dark:text-gray-300">We'd love to hear from you!</p>
          </div>
          <div class="bg-gray-50 dark:bg-gray-800 p-6 rounded-lg border border-gray-100 dark:border-gray-600">
            <form class="space-y-6" @submit.prevent="handleContactForm">
              <div>
                <label for="name" class="block text-sm font-medium text-gray-700 dark:text-gray-300 transition-colors duration-300 mb-1">Name</label>
                <div class="relative rounded-md shadow-sm">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <UserIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                  </div>
                  <input
                    id="name"
                    v-model="name"
                    name="name"
                    type="text"
                    autocomplete="name"
                    placeholder="Enter your name"
                    :class="{ 'border-red-600 dark:border-red-400 ring-1 ring-red-600 dark:ring-red-400': submitted && v$.name.$error }"
                    class="block w-full pl-10 appearance-none rounded-md border border-gray-300 dark:border-gray-600 px-3 py-3 placeholder-gray-400 dark:placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm text-gray-800 dark:text-white dark:bg-gray-600 transition-colors duration-300"
                  />
                </div>
                <div v-for="(error, index) of v$.name.$errors" :key="index" class="input-errors">
                  <div class="text-sm font-medium text-red-600 dark:text-red-400 transition-colors duration-300 mt-1">{{ error.$message }}</div>
                </div>
              </div>
              
              <div>
                <label for="email" class="block text-sm font-medium text-gray-700 dark:text-gray-300 transition-colors duration-300 mb-1"
                  >Email address</label
                >
                <div class="relative rounded-md shadow-sm">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <EnvelopeIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                  </div>
                  <input
                    id="email"
                    v-model="emailId"
                    name="email"
                    type="email"
                    autocomplete="email"
                    placeholder="Enter your email"
                    :class="{ 'border-red-600 dark:border-red-400 ring-1 ring-red-600 dark:ring-red-400': submitted && v$.emailId.$error }"
                    class="block w-full pl-10 appearance-none rounded-md border border-gray-300 dark:border-gray-600 px-3 py-3 placeholder-gray-400 dark:placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm text-gray-800 dark:text-white dark:bg-gray-600 transition-colors duration-300"
                  />
                </div>
                <div
                  v-for="(error, index) of v$.emailId.$errors"
                  :key="index"
                  class="input-errors"
                >
                  <div class="text-sm font-medium text-red-600 dark:text-red-400 transition-colors duration-300 mt-1">{{ error.$message }}</div>
                </div>
              </div>
              
              <div>
                <label for="subject" class="block text-sm font-medium text-gray-700 dark:text-gray-300 transition-colors duration-300 mb-1"
                  >Subject</label
                >
                <div class="relative rounded-md shadow-sm">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <ChatBubbleLeftRightIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                  </div>
                  <input
                    id="subject"
                    v-model="subject"
                    name="subject"
                    type="text"
                    autocomplete="subject"
                    placeholder="What is this regarding?"
                    :class="{ 'border-red-600 dark:border-red-400 ring-1 ring-red-600 dark:ring-red-400': submitted && v$.subject.$error }"
                    class="block w-full pl-10 appearance-none rounded-md border border-gray-300 dark:border-gray-600 px-3 py-3 placeholder-gray-400 dark:placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm text-gray-800 dark:text-white dark:bg-gray-600 transition-colors duration-300"
                  />
                </div>
                <div
                  v-for="(error, index) of v$.subject.$errors"
                  :key="index"
                  class="input-errors"
                >
                  <div class="text-sm font-medium text-red-600 dark:text-red-400 transition-colors duration-300 mt-1">{{ error.$message }}</div>
                </div>
              </div>

              <div>
                <label for="message" class="block text-sm font-medium text-gray-700 dark:text-gray-300 transition-colors duration-300 mb-1"
                  >Message</label
                >
                <div class="relative rounded-md shadow-sm">
                  <div class="absolute left-0 top-3 pl-3 flex items-start pointer-events-none">
                    <PencilSquareIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                  </div>
                  <textarea
                    id="message"
                    v-model="message"
                    name="message"
                    rows="5"
                    autocomplete="message"
                    placeholder="Tell us more about your inquiry..."
                    :class="{ 'border-red-600 dark:border-red-400 ring-1 ring-red-600 dark:ring-red-400': submitted && v$.message.$error }"
                    class="block w-full pl-10 appearance-none rounded-md border border-gray-300 dark:border-gray-600 px-3 py-3 placeholder-gray-400 dark:placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm text-gray-800 dark:text-white dark:bg-gray-600 transition-colors duration-300"
                  ></textarea>
                </div>
                <div
                  v-for="(error, index) of v$.message.$errors"
                  :key="index"
                  class="input-errors"
                >
                  <div class="text-sm font-medium text-red-600 dark:text-red-400 transition-colors duration-300 mt-1">{{ error.$message }}</div>
                </div>
              </div>
              
              <div class="flex justify-center mt-8">
                <button
                  type="submit"
                  class="w-full justify-center rounded-lg border border-transparent py-3 px-6 text-base font-medium text-white shadow-lg hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all duration-300 transform hover:scale-[1.02] active:scale-[0.98]"
                  :style="{ background: `${btnBgColor}` }"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </template>
    </div>
  </div>
</AppLayout>
</template>

<style scoped>
/* Ensure smooth transitions between themes */
.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

/* Custom focus styles that work in both themes */
input:focus, textarea:focus {
  border-color: var(--focus-ring-color, #4f46e5);
  box-shadow: 0 0 0 1px var(--focus-ring-color, #4f46e5);
}

/* Improve the submit button hover state */
button[type="submit"]:hover {
  filter: brightness(110%);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

/* Match text color with rest of the application */
label {
  @apply text-gray-700 dark:text-gray-300;
}

textarea, input {
  @apply bg-white dark:bg-gray-600 text-gray-800 dark:text-white border-gray-300 dark:border-gray-600;
}

/* Field glow effect on focus */
.relative:focus-within {
  @apply ring-2 ring-indigo-100 dark:ring-indigo-900 rounded-md;
}

/* Input transition effects */
input, textarea {
  transition: all 0.3s ease;
}

/* Success message animation */
@keyframes fadeScale {
  0% { opacity: 0; transform: scale(0.9); }
  100% { opacity: 1; transform: scale(1); }
}

div[v-if="contactInfo?.status == 200"] {
  animation: fadeScale 0.5s ease-out forwards;
}
</style>
