<script>
import AppLayout from '@/layouts/AppLayout.vue'
import SettingMenuLink from '@/views/account/setting/common/SettingMenuLink.vue'
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { required, sameAs, minLength } from '@vuelidate/validators'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { changePasswordFn } from '@/services/api/restApiCalls'
import { CheckCircleIcon, XCircleIcon, LockClosedIcon } from '@heroicons/vue/20/solid'
import LoadingSpinner from '@/components/common/LoadingSpinner/LoadingSpinner.vue'
import { notify } from 'notiwind'

export default {
  components: {
    AppLayout,
    CheckCircleIcon,
    XCircleIcon,
    LockClosedIcon,
    SettingMenuLink,
    LoadingSpinner
  },
  setup() {
    const restoStore = useRestoGLobalStore()
    const router = useRouter()
    const password = ref('')
    const newPassword = ref('')
    const confirmNewPassword = ref('')
    const passwordValid = ref(false)
    const submitted = ref(false)
    const passwordData = ref({})
    const isLoading = ref(false)

    const validationRules = {
      password: { required },
      newPassword: { required, minLength: minLength(5) },
      confirmNewPassword: { required, sameAs: sameAs(newPassword) }
    }
    const v$ = useVuelidate(validationRules, {
      password,
      newPassword,
      confirmNewPassword
    })
    const handleUpdatePassword = async () => {
      submitted.value = true
      v$.value.$validate()
      if (v$.value.$invalid) {
        return
      }
      if (isLoading.value) return
      isLoading.value = true
      let passwordFormData = new FormData()
      passwordFormData.append('password', password.value)
      passwordFormData.append('password_new', newPassword.value)
      passwordFormData.append('password_new_confirm', confirmNewPassword.value)
      const change_password = await changePasswordFn(passwordFormData)
      passwordData.value = change_password
      // passwordValid.value = true
      isLoading.value = false
      if (passwordData?.value.status == 200) {
        notify(
          {
            group: 'successmessage',
            title: 'Success',
            text: `Settings updated successfully`
          },
          2000
        )
      } else {
        notify(
          {
            group: 'errormessage',
            title: 'Error',
            text: `${passwordData?.value.status_msg}`
          },
          2000
        )
      }
    }
    watch([password, newPassword, confirmNewPassword], () => {
      passwordData.value = ''
    })
    return {
      restoStore,
      router,
      password,
      newPassword,
      confirmNewPassword,
      passwordValid,
      submitted,
      v$,
      passwordData,
      handleUpdatePassword,
      isLoading
    }
  }
}
</script>
<template>
  <AppLayout>
    <div
      class="space-y-8 sm:px-6 px-3 sm:py-6 py-4 bg-white dark:bg-gray-800 shadow-md rounded-lg transition-colors duration-200"
    >
    <div class="flex items-center justify-between mb-6 px-4 sm:px-0">
      <router-link
        to="/account/customer/setting"
        class="inline-flex items-center rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 py-2 px-4 text-sm font-medium text-gray-700 dark:text-gray-300 shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-all duration-200"
      >
        ← Back to Settings
      </router-link>
    </div>
      
      <div class="md:grid md:grid-cols-3 md:gap-8">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-xl font-bold leading-6 text-gray-900 dark:text-gray-100 transition-colors duration-200">Update Password</h3>
            <p class="mt-2 text-sm text-gray-600 dark:text-gray-400 transition-colors duration-200">
              Change your password for better security
            </p>
          </div>
        </div>
        <div class="mt-5 md:col-span-2 md:mt-0">
          <form @submit.prevent="handleUpdatePassword">
            <div class="overflow-hidden shadow-sm rounded-lg">
              <div class="bg-white dark:bg-gray-800 px-6 py-6 transition-colors duration-200">
                <div class="space-y-6">
                  <div v-if="passwordData?.status == 200" class="rounded-md bg-green-50 dark:bg-green-900/20 p-4">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
                      </div>
                      <div class="ml-3">
                        <h3 class="text-sm font-medium text-green-800 dark:text-green-300">
                          Password changed successfully
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div v-if="passwordData?.status == 400" class="rounded-md bg-red-50 dark:bg-red-900/20 p-4">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                      </div>
                      <div class="ml-3">
                        <h3 class="text-sm font-medium text-red-800 dark:text-red-300">
                          {{ passwordData?.status_msg }}
                        </h3>
                      </div>
                    </div>
                  </div>
                  
                  <div>
                    <label for="current-password" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2 transition-colors duration-200"
                      >Current Password</label
                    >
                    <div class="relative">
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <LockClosedIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                      </div>
                      <input
                        type="password"
                        name="current-password"
                        id="current-password"
                        autocomplete="current-password"
                        v-model="password"
                        :class="{ 'border-red-600 dark:border-red-500 focus:border-red-600 focus:ring-red-200 dark:focus:ring-red-800/20': submitted && v$.password.$error }"
                        class="block w-full appearance-none rounded-lg border border-gray-300 dark:border-gray-700 shadow-sm focus:border-violet-500 focus:ring focus:ring-violet-200 dark:focus:ring-violet-800/20 focus:ring-opacity-50 pl-10 px-4 py-3 placeholder-gray-400 dark:placeholder-gray-500 focus:outline-none text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 transition-all duration-200"
                      />
                    </div>
                    <div
                      v-for="(error, index) of v$.password.$errors"
                      :key="index"
                      class="mt-1"
                    >
                      <div class="text-sm font-medium text-red-600 dark:text-red-400 transition-colors duration-200">
                        {{ error.$message }}
                      </div>
                    </div>
                  </div>

                  <div>
                    <label for="new-password" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2 transition-colors duration-200"
                      >New Password</label
                    >
                    <div class="relative">
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <LockClosedIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                      </div>
                      <input
                        type="password"
                        name="new-password"
                        id="new-password"
                        autocomplete="new-password"
                        v-model="newPassword"
                        :class="{ 'border-red-600 dark:border-red-500 focus:border-red-600 focus:ring-red-200 dark:focus:ring-red-800/20': submitted && v$.newPassword.$error }"
                        class="block w-full appearance-none rounded-lg border border-gray-300 dark:border-gray-700 shadow-sm focus:border-violet-500 focus:ring focus:ring-violet-200 dark:focus:ring-violet-800/20 focus:ring-opacity-50 pl-10 px-4 py-3 placeholder-gray-400 dark:placeholder-gray-500 focus:outline-none text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 transition-all duration-200"
                      />
                    </div>
                    <div
                      v-for="(error, index) of v$.newPassword.$errors"
                      :key="index"
                      class="mt-1"
                    >
                      <div class="text-sm font-medium text-red-600 dark:text-red-400 transition-colors duration-200">
                        {{ error.$message }}
                      </div>
                    </div>
                    <p class="mt-1 text-xs text-gray-500 dark:text-gray-400 transition-colors duration-200">
                      Password must be at least 5 characters long
                    </p>
                  </div>

                  <div>
                    <label for="confirm-password" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2 transition-colors duration-200"
                      >Confirm New Password</label
                    >
                    <div class="relative">
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <LockClosedIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                      </div>
                      <input
                        type="password"
                        name="confirm-password"
                        id="confirm-password"
                        autocomplete="new-password"
                        v-model="confirmNewPassword"
                        :class="{ 'border-red-600 dark:border-red-500 focus:border-red-600 focus:ring-red-200 dark:focus:ring-red-800/20': submitted && v$.confirmNewPassword.$error }"
                        class="block w-full appearance-none rounded-lg border border-gray-300 dark:border-gray-700 shadow-sm focus:border-violet-500 focus:ring focus:ring-violet-200 dark:focus:ring-violet-800/20 focus:ring-opacity-50 pl-10 px-4 py-3 placeholder-gray-400 dark:placeholder-gray-500 focus:outline-none text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 transition-all duration-200"
                      />
                    </div>
                    <div
                      v-for="(error, index) of v$.confirmNewPassword.$errors"
                      :key="index"
                      class="mt-1"
                    >
                      <div class="text-sm font-medium text-red-600 dark:text-red-400 transition-colors duration-200">
                        {{ error.$message }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-gray-50 dark:bg-gray-700 px-6 py-4 text-center transition-colors duration-200">
                <button
                  type="submit"
                  class="inline-flex justify-center rounded-lg border border-transparent bg-violet-700 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-800 dark:hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 transition-all duration-200 transform hover:-translate-y-0.5 mr-3"
                >
                  Save
                </button>

                <router-link
                  :to="{ name: 'customerSetting' }"
                  class="inline-flex justify-center rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 py-3 px-6 text-sm font-medium text-gray-700 dark:text-gray-300 shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-all duration-200"
                >
                  Cancel
                </router-link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </AppLayout>
  <LoadingSpinner :isLoading="isLoading" />
</template>
