<script>
import { ref, reactive, computed } from 'vue'
import AppLayout from '@/layouts/AppLayout.vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email, sameAs, minLength, helpers, maxLength } from '@vuelidate/validators'
import { registerNewUserFn, isCustomerEmailAvailableFn } from '@/services/api/restApiCalls'
import { XCircleIcon, UserIcon, EnvelopeIcon, LockClosedIcon, PhoneIcon } from '@heroicons/vue/20/solid'
import { useAuthGlobalStore } from '@/stores/authGlobalStore'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useRouter } from 'vue-router'
import LoadingSpinner from '@/components/common/LoadingSpinner/LoadingSpinner.vue'
import { notify } from 'notiwind'

export default {
  components: {
    AppLayout,
    XCircleIcon,
    UserIcon,
    EnvelopeIcon,
    LockClosedIcon,
    PhoneIcon,
    LoadingSpinner
  },
  setup() {
    const authStore = useAuthGlobalStore()
    const restoGlobalStore = useRestoGLobalStore()
    const router = useRouter()
    const newUserInfo = ref({})
    const isLoading = ref(false)
    let userInitialValues = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      phoneNumber: ''
    }
    let user = reactive({ ...userInitialValues })
    let registerError = ref(false)
    const checkIfEmailAvailable = async (value) => {
      // TOODO: add api call to check if email is available here
      let formData = new FormData()
      formData.append('email', value)
      const response = await isCustomerEmailAvailableFn(formData)
      const isEmailAvailable = response.is_email_available || false
      return isEmailAvailable
    }

    const validationRules = computed(() => {
      let rules = {
        firstName: {
          required
        },
        lastName: { required },
        email: {
          required,
          email,
          $lazy: true,
          isUnique: helpers.withAsync(
            helpers.withMessage('Email not available', checkIfEmailAvailable)
          )
        },
        password: {
          required,
          minLength: minLength(5)
        },
        confirmPassword: { required, sameAs: sameAs(user.password) },
        phoneNumber: { minLength: minLength(10), maxLength: maxLength(15) }
      }
      return rules
    })

    const v$ = useVuelidate(validationRules, user)
    const submitted = ref(false)

    const handleRegistrationError = () => {
      Object.assign(user, userInitialValues)
      registerError.value = true
      v$.value.$reset()
    }

    const handleRegisterUser = async () => {
      // if (submitted.value) return
      submitted.value = true
      registerError.value = false
      v$.value.$validate()
      if (v$.value.$invalid) {
        return
      }
      if (isLoading.value) return
      isLoading.value = true
      const menuSlug = restoGlobalStore.restoInfo.menu_url
      const currentDate = new Date().toISOString().split('T')[0] //'YYYY-MM-DD' format
      const signupCaptcha = `beluga-signup-${currentDate}`
      const base64EncodedValue = btoa(signupCaptcha) //Encoded signupCaptcha
      let registerFormData = new FormData()
      registerFormData.append('first_name', user.firstName)
      registerFormData.append('last_name', user.lastName)
      registerFormData.append('email', user.email)
      registerFormData.append('password', user.password)
      registerFormData.append('phone_number', user.phoneNumber)
      registerFormData.append('beluga_captcha', base64EncodedValue)

      const new_user_data = await registerNewUserFn(registerFormData)
      newUserInfo.value = new_user_data
      if (newUserInfo.value.status == 400) {
        registerError.value = true
        isLoading.value = false
        notify(
          {
            group: 'errormessage',
            title: 'Error',
            text: `${newUserInfo.value.status_msg}`
          },
          2000
        )
        return
      }
      if (new_user_data.email === user.email) {
        authStore.login(user.email, user.password).then((isLoggedIn) => {
          if (isLoggedIn) {
            router.push({ name: 'menu', params: { menu_slug: menuSlug } })
          } else {
            // handleRegistrationError()
            registerError.value = true
          }
        })
      } else {
        // handleRegistrationError()
        registerError.value = true
      }
      isLoading.value = false
      submitted.value = false
    }
    return {
      router,
      authStore,
      registerError,
      submitted,
      user,
      v$,
      handleRegisterUser,
      newUserInfo,
      isLoading
    }
  }
}
</script>

<template>
  <AppLayout>
    <div
      class="flex flex-col items-center justify-center sm:px-6 px-3 sm:py-12 py-6 mx-auto pt:mt-0"
    >
      <div class="w-full max-w-2xl p-8 bg-white dark:bg-gray-800 rounded-xl shadow-lg transform transition-all duration-200 hover:shadow-xl">
        <div class="text-center mb-8">
          <h2 class="text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100">Create Your Account</h2>
          <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">Fill in the details below to get started</p>
        </div>

        <div v-if="registerError" class="mb-6 p-4 rounded-lg bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 dark:border-red-400 transition-all duration-200">
          <div class="flex items-start">
            <div class="flex-shrink-0 pt-0.5">
              <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div class="ml-3">
              <p class="text-sm font-medium text-red-800 dark:text-red-300">
                {{ newUserInfo?.status_msg || "There was an error with your registration. Please try again." }}
              </p>
            </div>
          </div>
        </div>

        <form @submit.prevent="handleRegisterUser()">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label for="firstName" class="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2"
                >First Name</label
              >
              <div class="relative">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <UserIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                </div>
                <input
                  id="firstName"
                  v-model="user.firstName"
                  name="firstName"
                  type="text"
                  autocomplete="given-name"
                  placeholder="John"
                  :class="{ 'border-red-600 dark:border-red-500 focus:border-red-600 focus:ring-red-200 dark:focus:ring-red-800/20': submitted && v$.firstName.$error }"
                  class="block w-full appearance-none rounded-lg border border-gray-300 dark:border-gray-600 pl-10 px-4 py-3 placeholder-gray-400 dark:placeholder-gray-500 shadow-sm focus:border-violet-500 focus:ring focus:ring-violet-200 dark:focus:ring-violet-800/20 focus:ring-opacity-50 focus:outline-none text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 transition-all duration-200"
                />
              </div>
              <div
                v-for="(error, index) of v$.firstName.$errors"
                :key="index"
                class="mt-1 text-sm font-medium text-red-600 dark:text-red-400"
              >
                {{ error.$message }}
              </div>
            </div>

            <div>
              <label for="lastName" class="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2"
                >Last Name</label
              >
              <div class="relative">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <UserIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                </div>
                <input
                  id="lastName"
                  v-model="user.lastName"
                  name="lastName"
                  type="text"
                  autocomplete="family-name"
                  placeholder="Doe"
                  :class="{ 'border-red-600 dark:border-red-500 focus:border-red-600 focus:ring-red-200 dark:focus:ring-red-800/20': submitted && v$.lastName.$error }"
                  class="block w-full appearance-none rounded-lg border border-gray-300 dark:border-gray-600 pl-10 px-4 py-3 placeholder-gray-400 dark:placeholder-gray-500 shadow-sm focus:border-violet-500 focus:ring focus:ring-violet-200 dark:focus:ring-violet-800/20 focus:ring-opacity-50 focus:outline-none text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 transition-all duration-200"
                />
              </div>
              <div
                v-for="(error, index) of v$.lastName.$errors"
                :key="index"
                class="mt-1 text-sm font-medium text-red-600 dark:text-red-400"
              >
                {{ error.$message }}
              </div>
            </div>
          </div>

          <div class="mb-6">
            <label for="email" class="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2"
              >Email address</label
            >
            <div class="relative">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <EnvelopeIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
              </div>
              <input
                @blur="v$.email.$touch"
                id="email"
                v-model="user.email"
                name="email"
                type="email"
                autocomplete="email"
                placeholder="john.doe@example.com"
                :class="{ 'border-red-600 dark:border-red-500 focus:border-red-600 focus:ring-red-200 dark:focus:ring-red-800/20': submitted && v$.email.$error }"
                class="block w-full appearance-none rounded-lg border border-gray-300 dark:border-gray-600 pl-10 px-4 py-3 placeholder-gray-400 dark:placeholder-gray-500 shadow-sm focus:border-violet-500 focus:ring focus:ring-violet-200 dark:focus:ring-violet-800/20 focus:ring-opacity-50 focus:outline-none text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 transition-all duration-200"
              />
            </div>
            <div v-for="(error, index) of v$.email.$errors" :key="index" class="mt-1 text-sm font-medium text-red-600 dark:text-red-400">
              {{ error.$message }}
            </div>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label for="password" class="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2"
                >Password</label
              >
              <div class="relative">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <LockClosedIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                </div>
                <input
                  id="password"
                  v-model="user.password"
                  name="password"
                  type="password"
                  autocomplete="new-password"
                  placeholder="••••••••"
                  :class="{ 'border-red-600 dark:border-red-500 focus:border-red-600 focus:ring-red-200 dark:focus:ring-red-800/20': submitted && v$.password.$error }"
                  class="block w-full appearance-none rounded-lg border border-gray-300 dark:border-gray-600 pl-10 px-4 py-3 placeholder-gray-400 dark:placeholder-gray-500 shadow-sm focus:border-violet-500 focus:ring focus:ring-violet-200 dark:focus:ring-violet-800/20 focus:ring-opacity-50 focus:outline-none text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 transition-all duration-200"
                />
              </div>
              <div
                v-for="(error, index) of v$.password.$errors"
                :key="index"
                class="mt-1 text-sm font-medium text-red-600 dark:text-red-400"
              >
                {{ error.$message }}
              </div>
              <p v-if="!v$.password.$error" class="mt-1 text-xs text-gray-500 dark:text-gray-400">
                Password must be at least 5 characters
              </p>
            </div>

            <div>
              <label for="confirmPassword" class="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2"
                >Confirm Password</label
              >
              <div class="relative">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <LockClosedIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                </div>
                <input
                  id="confirmPassword"
                  v-model="user.confirmPassword"
                  name="confirmPassword"
                  type="password"
                  autocomplete="new-password"
                  placeholder="••••••••"
                  :class="{ 'border-red-600 dark:border-red-500 focus:border-red-600 focus:ring-red-200 dark:focus:ring-red-800/20': submitted && v$.confirmPassword.$error }"
                  class="block w-full appearance-none rounded-lg border border-gray-300 dark:border-gray-600 pl-10 px-4 py-3 placeholder-gray-400 dark:placeholder-gray-500 shadow-sm focus:border-violet-500 focus:ring focus:ring-violet-200 dark:focus:ring-violet-800/20 focus:ring-opacity-50 focus:outline-none text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 transition-all duration-200"
                />
              </div>
              <div
                v-for="(error, index) of v$.confirmPassword.$errors"
                :key="index"
                class="mt-1 text-sm font-medium text-red-600 dark:text-red-400"
              >
                {{ error.$message }}
              </div>
            </div>
          </div>

          <div class="mb-8">
            <label for="phoneNumber" class="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2"
              >Phone Number (optional)</label
            >
            <div class="relative">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <PhoneIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
              </div>
              <input
                id="phoneNumber"
                v-model="user.phoneNumber"
                name="phoneNumber"
                type="text"
                autocomplete="tel"
                placeholder="0751234xxxx"
                :class="{ 'border-red-600 dark:border-red-500 focus:border-red-600 focus:ring-red-200 dark:focus:ring-red-800/20': submitted && v$.phoneNumber.$error }"
                class="block w-full appearance-none rounded-lg border border-gray-300 dark:border-gray-600 pl-10 px-4 py-3 placeholder-gray-400 dark:placeholder-gray-500 shadow-sm focus:border-violet-500 focus:ring focus:ring-violet-200 dark:focus:ring-violet-800/20 focus:ring-opacity-50 focus:outline-none text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 transition-all duration-200"
              />
            </div>
            <div
              v-for="(error, index) of v$.phoneNumber.$errors"
              :key="index"
              class="mt-1 text-sm font-medium text-red-600 dark:text-red-400"
            >
              {{ error.$message }}
            </div>
          </div>

          <div class="flex flex-col sm:flex-row gap-4">
            <button
              type="submit"
              class="flex-1 justify-center rounded-lg border border-transparent bg-violet-700 py-3 px-6 text-base font-medium text-white shadow-sm hover:bg-violet-800 dark:hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 transition-all duration-200 transform hover:-translate-y-0.5"
            >
              Create Account
            </button>
            <button
              type="button"
              class="flex-1 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 py-3 px-6 text-base font-medium text-gray-700 dark:text-gray-300 shadow-sm hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-all duration-200"
              @click="$router.push({ name: 'login' })"
            >
              Back to Login
            </button>
          </div>
        </form>
      </div>
    </div>
  </AppLayout>
  <LoadingSpinner :isLoading="isLoading" />
</template>
