<script setup>
import { ref } from 'vue'
import ComplexItem from '@/components/menu/items/ComplexItem.vue'
import MealItem from '@/components/menu/items/MealItem.vue'
import SimpleItem from '@/components/menu/items/SimpleItem.vue'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronUpIcon } from '@heroicons/vue/20/solid'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useCartGLobalStore } from '@/stores/cartGlobalStore'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'

const restoStore = useRestoGLobalStore()
const cartStore = useCartGLobalStore()
const itemData = ref(null)
const is_mobile = ref(false)
const handleItemModal = (product) => {
  if (cartStore.CART_DATA?.order_type == 'delivery' && !cartStore?.CART_DATA?.pc_data?.is_valid) {
    cartStore.SHOW_POSTCODE_MODAL = true
  } else {
    itemData.value = product
    restoStore.OPEN_MODAL = true
  }
}
</script>
<template>
  <div class="md:rounded-lg border border-gray-300 dark:border-gray-700 shadow-sm px-2 py-4 bg-white dark:bg-surface-dark md:mb-0 mb-24 transition-colors duration-200">
    <Disclosure
      v-for="category in restoStore.MENU_DATA?.categories"
      :id="`category-div-id-${category.category_id}`"
      :key="category.category_id"
      v-slot="{ open }"
      as="div"
      :default-open="!is_mobile"
    >
      <DisclosureButton
        :id="`category-id-${category.category_id}`"
        class="flex w-full justify-between rounded-lg bg-white dark:bg-surface-dark md:px-4 sm:px-3 px-2 py-2 text-left text-sm font-medium text-black-900 dark:text-text-dark hover:bg-gray-50 dark:hover:bg-gray-800 focus:outline-none focus-visible:ring focus-visible:ring-gray-50 dark:focus-visible:ring-gray-800 focus-visible:ring-opacity-75 transition-colors duration-200"
      >
        <h2
          class="text-lg lg:text-xl font-bold tracking-tight text-gray-900 dark:text-text-dark sm:text-1xl hover:text-indigo-600 dark:hover:text-indigo-400"
        >
          {{ category.category_name }}
        </h2>

        <ChevronUpIcon
          :class="open ? 'rotate-180 transform' : ''"
          class="h-10 w-10 text-black-800 dark:text-gray-400"
        />
      </DisclosureButton>
      <DisclosurePanel
        class="md:px-2 px-2 pt-0 md:pb-4 sm:pb-3 pb-2 text-sm text-gray-500 dark:text-gray-400 bg-white dark:bg-surface-dark transition-colors duration-200"
      >
        <p v-if="category.info" class="pb-3 text-md font-medium text-gray-600 dark:text-gray-300">
          {{ category.info }}
        </p>
        <div class="grid lg:grid-cols-2 grid-cols-1 gap-2">
          <template v-for="product in category.products" :key="product.product_id">
            <template v-if="product.items.length == 1">
              <ComplexItem
                v-if="product.items[0].is_complex_item"
                :complex-item-data="product.items[0]"
                :product-info="product.info"
                :item-count="product.items.length"
              ></ComplexItem>
              <MealItem
                v-else-if="product.items[0].is_meal"
                :meal-item-data="product.items[0]"
                :product-info="product.info"
                :item-count="product.items.length"
              ></MealItem>
              <SimpleItem
                v-else
                :simple-item-data="product.items[0]"
                :product-info="product.info"
                :item-count="product.items.length"
              ></SimpleItem>
            </template>
            <div
              v-else
              class="flex items-center rounded-lg border border-gray-300 dark:border-gray-700 p-2 cursor-pointer hover:border-gray-400 dark:hover:border-gray-500 bg-white dark:bg-surface-dark transition-colors duration-200"
              @click="handleItemModal(product)"
            >
              <div class="min-w-0 flex-1">
                <h3 as="h3" class="text-md font-medium leading-6 text-gray-900 dark:text-text-dark">
                  {{ product.product_name }}
                </h3>
                <p v-if="product.info" class="line-clamp-2 text-sm text-gray-500 dark:text-gray-400 mb-0">
                  {{ product.info }}
                </p>
              </div>
              <div v-if="product.menu_img_url" class="flex-shrink-0 bg-gray-500 dark:bg-gray-600">
                <div
                  class="h-14 w-14"
                  :style="{
                    backgroundImage: 'url(' + product?.menu_img_url + ')',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }"
                ></div>
              </div>
            </div>
          </template>
        </div>
      </DisclosurePanel>
    </Disclosure>
  </div>
  <TransitionRoot as="template" :show="restoStore.OPEN_MODAL">
    <Dialog as="div" class="relative z-20" @close="restoStore.hideModal()">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      </TransitionChild>

      <div class="fixed inset-0 z-20 overflow-y-auto">
        <div class="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform rounded-lg bg-white dark:bg-surface-dark text-left shadow-xl transition-all my-8 w-full max-w-md"
            >
              <div class="absolute top-0 right-0 pt-2 pr-2 block">
                <button
                  type="button"
                  class="rounded-full border border-e-violet p-1 bg-white dark:bg-gray-700 text-e-violet hover:text-e-violet focus:outline-none"
                  @click="restoStore.hideModal()"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-5 w-5 text-e-violet dark:text-indigo-400" aria-hidden="true" />
                </button>
              </div>
              <div class="max-h-90vh overflow-auto bg-white dark:bg-surface-dark transition-colors duration-200">
                <div
                  v-if="itemData?.menu_img_url"
                  class="mx-auto mb-2 h-60 bg-gray-200 dark:bg-gray-700"
                  :style="{
                    backgroundImage: 'url(' + itemData?.menu_img_url + ')',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }"
                ></div>

                <div class="px-5 py-6 dark:text-text-dark">
                  <div class="mb-3 text-center">
                    <h3 class="text-lg font-medium text-gray-900 dark:text-text-dark">{{ itemData.product_name }}</h3>
                    <p class="text-sm text-gray-500 dark:text-gray-400">{{ itemData.info }}</p>
                  </div>
                  <div v-for="item in itemData.items" :key="item.item_id" class="mt-3 mb-3 transition-colors duration-200">
                    <ComplexItem
                      v-if="item.is_complex_item"
                      :complex-item-data="item"
                    ></ComplexItem>
                    <MealItem v-else-if="item.is_meal" :meal-item-data="item"></MealItem>
                    <SimpleItem v-else :simple-item-data="item"></SimpleItem>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
