<script>
import { ref, computed } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { CheckCircleIcon } from '@heroicons/vue/20/solid'
import { UserIcon, EnvelopeIcon, ChatBubbleLeftRightIcon, PencilSquareIcon } from '@heroicons/vue/24/outline'
import { contactFn } from '@/services/api/restApiCalls'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useRouter } from 'vue-router'
import AppLayout from '@/layouts/AppLayout.vue'
export default {
  components: {
    CheckCircleIcon,
    UserIcon,
    EnvelopeIcon,
    ChatBubbleLeftRightIcon,
    PencilSquareIcon,
    AppLayout
  },
  props: ['bgcolor'],
  setup(props) {
    // const authStore = useAuthGlobalStore()
    const restoStore = useRestoGLobalStore()
    const router = useRouter()

    const name = ref('')
    const emailId = ref('')
    const subject = ref('')
    const message = ref('')
    const submitted = ref(false)
    const contactInfo = ref({})
    const btnBgColor = ref(props.bgcolor || '#4f46e5')

    const validationRules = computed(() => {
      let rules = {
        name: { required },
        emailId: {
          required,
          email
        },
        subject: { required },
        message: { required }
      }
      return rules
    })
    const v$ = useVuelidate(validationRules, {
      name,
      emailId,
      subject,
      message
    })
    const handleContactForm = async () => {
      submitted.value = true
      const isValid = await v$.value.$validate()
      if (!isValid) {
        return
      }
      const currentDate = new Date().toISOString().split('T')[0] //'YYYY-MM-DD' format
      const contactUsCaptcha = `beluga-contact-${currentDate}`
      const base64EncodedValue = btoa(contactUsCaptcha) //Encoded signupCaptcha
      const contactFormData = new FormData()
      contactFormData.append('name', name.value)
      contactFormData.append('email', emailId.value)
      contactFormData.append('subject', subject.value)
      contactFormData.append('message', message.value)
      contactFormData.append('beluga_captcha', base64EncodedValue)
      try {
        const contact_info = await contactFn(restoStore?.RESTO_ID, contactFormData)
        contactInfo.value = contact_info
      } catch (error) {
        console.error('Error submitting contact form:', error)
      }
    }

    return {
      name,
      emailId,
      subject,
      message,
      submitted,
      contactInfo,
      v$,
      router,
      restoStore,
      btnBgColor,
      handleContactForm
    }
  }
}
</script>
<template>
  <div class="flex flex-col items-center justify-center mx-auto transition-colors duration-300">
    <div class="w-full p-6 space-y-8 sm:p-8 bg-white dark:bg-gray-700 rounded-lg shadow-md transition-colors duration-300">
      <div v-if="contactInfo?.status == 200" class="text-center">
        <CheckCircleIcon class="h-14 w-14 text-green-500 mx-auto" aria-hidden="true" />
        <h3 class="text-xl font-semibold text-gray-700 dark:text-gray-200 my-1 transition-colors duration-300">Thank you for getting in touch!</h3>
      </div>
      <template v-else>
        <div class="sm:mx-auto sm:w-full sm:max-w-4xl">
          <div>
            <form class="space-y-4" @submit.prevent="handleContactForm">
              <div class="md:grid md:grid-cols-12 md:gap-x-6">
                <div class="md:col-span-12">
                  <label for="name" class="block text-sm font-medium text-gray-700 dark:text-gray-300 transition-colors duration-300">Name</label>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <UserIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                    </div>
                    <input
                      id="name"
                      v-model="name"
                      name="name"
                      type="text"
                      autocomplete="name"
                      placeholder="Enter name"
                      :class="{ 'border-red-600 dark:border-red-400': submitted && v$.name.$error }"
                      class="block w-full appearance-none rounded-md border border-gray-300 dark:border-gray-600 pl-10 px-3 py-2.5 placeholder-gray-400 dark:placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm text-gray-800 dark:text-white dark:bg-gray-600 transition-colors duration-300"
                    />
                  </div>
                  <div v-for="(error, index) of v$.name.$errors" :key="index" class="input-errors">
                    <div class="text-sm font-medium text-red-600 dark:text-red-400 transition-colors duration-300">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="md:grid md:grid-cols-12 md:gap-x-6">
                <div class="md:col-span-12">
                  <label for="email" class="block text-sm font-medium text-gray-700 dark:text-gray-300 transition-colors duration-300"
                    >Email address</label
                  >
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <EnvelopeIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                    </div>
                    <input
                      id="email"
                      v-model="emailId"
                      name="email"
                      type="email"
                      autocomplete="email"
                      placeholder="Enter email"
                      :class="{ 'border-red-600 dark:border-red-400': submitted && v$.emailId.$error }"
                      class="block w-full appearance-none rounded-md border border-gray-300 dark:border-gray-600 pl-10 px-3 py-2.5 placeholder-gray-400 dark:placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm text-gray-800 dark:text-white dark:bg-gray-600 transition-colors duration-300"
                    />
                  </div>
                  <div
                    v-for="(error, index) of v$.emailId.$errors"
                    :key="index"
                    class="input-errors"
                  >
                    <div class="text-sm font-medium text-red-600 dark:text-red-400 transition-colors duration-300">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="md:grid md:grid-cols-12 md:gap-x-6">
                <div class="md:col-span-12">
                  <label for="subject" class="block text-sm font-medium text-gray-700 dark:text-gray-300 transition-colors duration-300"
                    >Subject</label
                  >
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <ChatBubbleLeftRightIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                    </div>
                    <input
                      id="subject"
                      v-model="subject"
                      name="subject"
                      type="text"
                      autocomplete="subject"
                      placeholder="Enter subject"
                      :class="{ 'border-red-600 dark:border-red-400': submitted && v$.subject.$error }"
                      class="block w-full appearance-none rounded-md border border-gray-300 dark:border-gray-600 pl-10 px-3 py-2.5 placeholder-gray-400 dark:placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm text-gray-800 dark:text-white dark:bg-gray-600 transition-colors duration-300"
                    />
                  </div>
                  <div
                    v-for="(error, index) of v$.subject.$errors"
                    :key="index"
                    class="input-errors"
                  >
                    <div class="text-sm font-medium text-red-600 dark:text-red-400 transition-colors duration-300">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="md:grid md:grid-cols-12 md:gap-x-6">
                <div class="md:col-span-12">
                  <label for="message" class="block text-sm font-medium text-gray-700 dark:text-gray-300 transition-colors duration-300"
                    >Message</label
                  >
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <div class="absolute top-3 left-0 pl-3 flex items-start pointer-events-none">
                      <PencilSquareIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                    </div>
                    <textarea
                      id="message"
                      v-model="message"
                      name="message"
                      rows="4"
                      autocomplete="message"
                      placeholder="Write something here..."
                      :class="{ 'border-red-600 dark:border-red-400': submitted && v$.message.$error }"
                      class="block w-full appearance-none rounded-md border border-gray-300 dark:border-gray-600 pl-10 px-3 py-2.5 placeholder-gray-400 dark:placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm text-gray-800 dark:text-white dark:bg-gray-600 transition-colors duration-300"
                    ></textarea>
                  </div>
                  <div
                    v-for="(error, index) of v$.message.$errors"
                    :key="index"
                    class="input-errors"
                  >
                    <div class="text-sm font-medium text-red-600 dark:text-red-400 transition-colors duration-300">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="flex justify-center">
                <button
                  type="submit"
                  class="w-2/4 justify-center rounded-md border border-transparent py-2.5 px-4 text-sm font-medium text-white shadow-sm hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-300"
                  :style="{ background: `${btnBgColor}` }"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped>
/* Ensure smooth transitions between themes */
.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

/* Custom focus styles that work in both themes */
input:focus, textarea:focus {
  border-color: var(--focus-ring-color, #4f46e5);
  box-shadow: 0 0 0 1px var(--focus-ring-color, #4f46e5);
}

/* Improve the submit button hover state */
button[type="submit"]:hover {
  filter: brightness(110%);
}

/* Fix icon alignment in form fields */
.relative .absolute {
  pointer-events: none;
}

/* Input field hover effect */
input:hover, textarea:hover {
  border-color: #a5b4fc;
}

/* Add subtle shadow to inputs */
.shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
</style>
