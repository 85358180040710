<script>
import { useAuthGlobalStore } from '@/stores/authGlobalStore'
import { useRouter } from 'vue-router'
import { onMounted } from 'vue'

export default {
  setup() {
    const authStore = useAuthGlobalStore()
    const router = useRouter()

    onMounted(() => {
      authStore.logout()
      router.push({ name: 'login' })
    })
  }
}
</script>

<template>
  <div class="flex justify-center items-center h-screen bg-gray-50 dark:bg-gray-900 transition-colors duration-300">
    <div class="text-center p-8 max-w-md w-full bg-white dark:bg-gray-800 rounded-xl shadow-lg transform transition-all duration-500 hover:shadow-xl">
      <div class="animate-spin mx-auto mb-6 w-16 h-16 border-4 border-gray-300 dark:border-gray-600 border-t-violet-600 dark:border-t-violet-500 rounded-full"></div>
      <h1 class="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-2">Logging Out...</h1>
      <p class="text-gray-600 dark:text-gray-400">We're securely signing you out of your account.</p>
      <p class="mt-4 text-gray-500 dark:text-gray-500">You will be redirected to the login page shortly.</p>
    </div>
  </div>
</template>
