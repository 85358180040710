<script setup>
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useRouter } from 'vue-router'
import { onBeforeMount, inject } from 'vue'
import Template1 from '@/layouts/mobileAppSite/template1/Template1.vue'
import Template2 from '@/layouts/mobileAppSite/template2/Template2.vue'
import { useMobilePushNotification } from '@/composables/MobileNotification'
const restoStore = useRestoGLobalStore()
const IS_MOBILE_APP = inject('IS_MOBILE_APP')
const router = useRouter()
const { addListeners, registerNotifications } = useMobilePushNotification()

let mobileTemplate = getMobileTemplate()

function getMobileTemplate() {
  const restoStore = useRestoGLobalStore()
  // TODO : get template from API here
  const template = restoStore?.restoInfo?.dynamic_site_content?.mobile_template
  const templateMap = {
    1: Template1,
    2: Template2
  }
  return templateMap[template] || Template1
}

onBeforeMount(() => {
  if (IS_MOBILE_APP) {
    registerNotifications()
  } else {
    return router.push({ name: 'menu', params: { menu_slug: restoStore.MENU_URL } })
  }
})
</script>
<template><component :is="mobileTemplate"></component></template>
