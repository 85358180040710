<script setup>
import AppLayout from '@/layouts/AppLayout.vue'
import { ShoppingBagIcon, ArrowPathIcon, ArrowLeftIcon, ClipboardDocumentCheckIcon } from '@heroicons/vue/20/solid'
import { BanknotesIcon, CreditCardIcon, ClockIcon } from '@heroicons/vue/24/outline'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/20/solid'
import { getOrderStatusFn, reorderFn } from '@/services/api/restApiCalls'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { onBeforeUnmount, onMounted, ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import MobileAppPromotion from '@/components/MobileAppPromotion.vue'

const router = useRouter()
const route = useRoute()
const restoStore = useRestoGLobalStore()
const getOrderId = ref(route.params.order_id)
const getOrderData = ref({})
const orderStatusInterval = ref('')
const loading = ref(true)
const loadingText = ref('waiting confirmation')
const orderStatusMessages = [
  "Any moment now, your order will be approved...",
  "Counting down to culinary bliss...",
  "Preparing the ingredients for your special order...",
  "Securely transmitting your order details...",
  "Your order is being processed...",
  "Connecting with the restaurant's ordering system...",
  "We're getting everything ready for your meal...",
  "Almost there! Just a few more moments...",
  "Your taste buds will thank you for your patience...",
  "Processing your delicious selections...",
  "Your order is in queue to be confirmed...",
  "Creating a seamless dining experience for you...",
]
const currentMessageIndex = ref(0)

// Order details are shown if we have order data
const showOrderDetails = computed(() => {
  return getOrderData.value && Object.keys(getOrderData.value).length > 0
})

// Parse and format resto_note for cancelled orders
const formattedCancellationReason = computed(() => {
  if (!getOrderData.value?.resto_note) return null
  
  const noteText = getOrderData.value.resto_note
  const reasonMatch = noteText.match(/Reason:([^,]+)/)
  
  if (reasonMatch && reasonMatch[1]) {
    // Format the reason (convert from UPPER_SNAKE_CASE to readable text)
    const rawReason = reasonMatch[1].trim()
    return rawReason.split('_')
      .map(word => word.charAt(0) + word.slice(1).toLowerCase())
      .join(' ')
  }
  
  return null
})

const handleOrderStatus = async () => {
  if (getOrderId.value) {
    const order_status = await getOrderStatusFn(getOrderId.value, restoStore?.RESTO_ID)
    if (order_status) {
      getOrderData.value = order_status
      if (order_status.order_status !== 'AUTHORIZED') {
        loading.value = false
      }
    } else {
      router.push({ name: 'NotFound' })
    }
  }
}

const handleReorder = async () => {
  let reorderFormData = new FormData()
  reorderFormData.append('order_id', getOrderId.value)
  reorderFormData.append('resto_id', restoStore?.RESTO_ID)
  const order_data = await reorderFn(reorderFormData)
  if (order_data.status == 201) {
    router.push({ name: 'menu', params: { menu_slug: restoStore?.MENU_URL } })
  }
}

const rotateLoadingMessage = () => {
  currentMessageIndex.value = (currentMessageIndex.value + 1) % orderStatusMessages.length
}

onMounted(() => {
  handleOrderStatus()
  orderStatusInterval.value = setInterval(() => {
    if (getOrderData?.value.order_status == 'AUTHORIZED') {
      handleOrderStatus()
      rotateLoadingMessage() // Rotate messages while waiting
    } else {
      clearInterval(orderStatusInterval.value)
    }
  }, 10000)
  
  // Rotate messages more frequently for better user experience
  setInterval(() => {
    if (loading.value || getOrderData?.value?.order_status === 'AUTHORIZED') {
      rotateLoadingMessage()
    }
  }, 10000)
})

onBeforeUnmount(() => {
  clearInterval(orderStatusInterval.value)
})
</script>
<template>
  <AppLayout>
    <div
      class="px-4 sm:px-6 lg:px-8 space-y-4 sm:pt-2 px-2 pt-2 max-w-6xl mx-auto dark:bg-background-dark transition-colors duration-200"
    >
      <!-- Top Action Buttons -->
      <div class="mt-4 text-center">
        <div class="flex flex-wrap justify-center text-center px-2 max-w-3xl mx-auto gap-2 md:gap-3 mb-4">
          <button
            type="button"
            class="md:w-auto w-full flex-1 rounded-md border border-transparent bg-gray-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-500 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none focus:ring-offset-gray-50 transition-colors duration-200 flex items-center justify-center"
            @click="$router.push({ name: 'my_orders' })"
          >
            <ArrowLeftIcon class="h-5 w-5 mr-1.5" aria-hidden="true" />
            Back to Orders
          </button>
          <button
            type="button"
            class="md:w-auto w-full flex-1 justify-center rounded-md border border-transparent bg-violet-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-violet-800 dark:hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 transition-colors duration-200 flex items-center justify-center"
            @click="
              router.push({
                name: 'view_order',
                params: { order_id: `${getOrderData?.order_id}` }
              })
            "
          >
            <ClipboardDocumentCheckIcon class="h-5 w-5 mr-1.5" aria-hidden="true" />
            Show Order Details
          </button>
        </div>
      </div>
      
      <!-- Loading State with Fun Messages -->
      <div v-if="loading || getOrderData?.order_status === 'AUTHORIZED'" class="flex items-center justify-center mt-4">
        <div
          class="mx-auto max-w-3xl py-6 px-4 sm:px-6 w-full lg:py-8 lg:px-8 bg-white dark:bg-gray-800 rounded-xl shadow-md transition-all duration-300"
        >
          <!-- Order Number and Date -->
          <div class="text-center mb-4 border-b border-gray-200 dark:border-gray-700 pb-3">
            <h1 class="text-xl font-bold text-gray-900 dark:text-gray-100 mb-1 transition-colors duration-200">
              Order No: {{ getOrderData?.order_id }}
            </h1>
            <p class="text-gray-600 dark:text-gray-400 transition-colors duration-200">{{ getOrderData?.creation_datetime }}</p>
          </div>
          
          <div class="flex flex-col items-center gap-y-4 text-center w-full">
            <div class="border-b border-gray-200 dark:border-gray-700 pb-4 w-full">
              <h2 class="text-2xl font-bold tracking-tight sm:text-3xl dark:text-white transition-colors duration-200">
                <span class="block text-primary-600 dark:text-primary-400">
                  {{ loading ? 'Waiting for Order Confirmation' : 'Processing Your Order' }}
                </span>
              </h2>
              <div class="h-20 w-20 mx-auto my-4 relative">
                <svg
                  class="animate-slow-spin h-20 w-20 text-primary-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                <div class="absolute inset-0 flex items-center justify-center">
                  <span class="text-2xl">🍽️</span>
                </div>
              </div>
              
              <!-- Fun, rotating status messages -->
              <p class="my-4 text-lg leading-7 text-gray-700 dark:text-gray-300 font-medium italic transition-colors duration-200">
                {{ orderStatusMessages[currentMessageIndex] }}
              </p>
              
              <p class="text-base leading-6 text-gray-600 dark:text-gray-400 transition-colors duration-200">
                Please wait while we get confirmation
              </p>
              
              <!-- Fun animation elements -->
              <div class="flex justify-center gap-4 mt-3">
                <div class="w-2.5 h-2.5 bg-primary-300 dark:bg-primary-700 rounded-full animate-bounce delay-75"></div>
                <div class="w-2.5 h-2.5 bg-primary-400 dark:bg-primary-600 rounded-full animate-bounce delay-150"></div>
                <div class="w-2.5 h-2.5 bg-primary-500 dark:bg-primary-500 rounded-full animate-bounce delay-300"></div>
              </div>
            </div>
            
            <!-- Order Details Section -->
            <div v-if="showOrderDetails" class="w-full px-0 sm:px-2">
              <!-- Compact Order Info -->
              <div class="bg-gray-50 dark:bg-gray-700/50 p-3 rounded-lg mb-3">
                <div class="grid grid-cols-2 gap-x-3 gap-y-2">
                  <!-- Order Type -->
                  <div>
                    <h3 class="text-xs font-medium text-gray-500 dark:text-gray-400 mb-1">Order Type</h3>
                    <span
                      class="inline-flex items-center rounded-full px-2 py-0.5 text-sm font-medium transition-colors duration-200"
                      :class="[
                        getOrderData?.order_type == 'collection'
                          ? 'bg-violet-100 text-violet-800 dark:bg-violet-800 dark:text-violet-100'
                          : 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100'
                      ]"
                    >
                      <ShoppingBagIcon
                        v-if="getOrderData?.order_type == 'collection'"
                        class="h-3.5 w-3.5 mr-1 text-violet-600 dark:text-violet-200"
                        aria-hidden="true"
                      />
                      <svg
                        v-else
                        class="h-3.5 w-3.5 fill-current mr-1 text-green-600 dark:text-green-200"
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path
                          d="M20 11c-.18 0-.36.03-.53.05L17.41 9H20V6l-3.72 1.86L13.41 5H9v2h3.59l2 2H11l-4 2-2-2H0v2h4c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4l2 2h3l3.49-6.1 1.01 1.01c-.91.73-1.5 1.84-1.5 3.09 0 2.21 1.79 4 4 4s4-1.79 4-4-1.79-4-4-4zM4 17c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm16 0c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
                        ></path>
                      </svg>
                      <span class="capitalize">{{ getOrderData?.order_type }}</span>
                    </span>
                  </div>
                  
                  <!-- Payment Method -->
                  <div>
                    <h3 class="text-xs font-medium text-gray-500 dark:text-gray-400 mb-1">Payment</h3>
                    <span
                      class="inline-flex items-center rounded-full px-2 py-0.5 text-sm font-medium transition-colors duration-200"
                      :class="[
                        getOrderData?.payment_type == 'cash'
                          ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100'
                          : 'bg-purple-100 text-purple-800 dark:bg-purple-800 dark:text-purple-100'
                      ]"
                    >
                      <BanknotesIcon
                        v-if="getOrderData?.payment_type == 'cash'"
                        class="h-3.5 w-3.5 mr-1 text-yellow-600 dark:text-yellow-200"
                        aria-hidden="true"
                      />
                      <CreditCardIcon
                        v-else
                        class="h-3.5 w-3.5 mr-1 text-purple-600 dark:text-purple-200"
                        aria-hidden="true"
                      />
                      <span class="capitalize">{{ getOrderData?.payment_type }}</span>
                    </span>
                  </div>
                  
                  <!-- Order Amount -->
                  <div>
                    <h3 class="text-xs font-medium text-gray-500 dark:text-gray-400 mb-1">Total</h3>
                    <p class="text-base font-semibold text-gray-900 dark:text-gray-100 transition-colors duration-200">£{{ getOrderData?.amount }}</p>
                  </div>
                  
                  <!-- Order Status -->
                  <div v-if="getOrderData?.order_status">
                    <h3 class="text-xs font-medium text-gray-500 dark:text-gray-400 mb-1">Status</h3>
                    <span
                      class="inline-flex items-center rounded-full px-2 py-0.5 text-sm font-medium transition-colors duration-200"
                      :class="{
                        'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100': getOrderData?.order_status === 'SUCCESS',
                        'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100': ['FAILURE', 'CANCELLED'].includes(getOrderData?.order_status),
                        'bg-amber-100 text-amber-800 dark:bg-amber-800 dark:text-amber-100': getOrderData?.order_status === 'AUTHORIZED'
                      }"
                    >
                      {{ getOrderData?.order_status }}
                    </span>
                  </div>
                </div>
                
                <!-- Delivery Address (Condensed) -->
                <div v-if="getOrderData?.order_type === 'delivery' && getOrderData?.customer?.address" class="mt-3 border-t border-gray-200 dark:border-gray-700 pt-2">
                  <h3 class="text-xs font-medium text-gray-500 dark:text-gray-400 mb-1">Delivery Address</h3>
                  <p class="text-sm text-gray-700 dark:text-gray-300 transition-colors duration-200">
                    {{ getOrderData?.customer?.address?.address_line1 }}
                    <span v-if="getOrderData?.customer?.address?.address_line2">, {{ getOrderData?.customer?.address?.address_line2 }}</span>
                    <span class="font-medium text-gray-900 dark:text-gray-100">, {{ getOrderData?.customer?.address?.post_code }}</span>
                  </p>
                </div>
              </div>
              
              <!-- Delivery Instructions (if present) -->
              <div v-if="getOrderData?.order_type === 'delivery' && getOrderData?.delivery_instructions" class="bg-gray-50 dark:bg-gray-700/50 p-2 rounded-lg mb-3">
                <h3 class="text-xs font-medium text-gray-500 dark:text-gray-400 mb-0.5">Delivery Instructions</h3>
                <p class="text-sm italic text-gray-700 dark:text-gray-300 transition-colors duration-200">{{ getOrderData?.delivery_instructions }}</p>
              </div>
              
              <!-- Mobile App Promotion -->
              <MobileAppPromotion />
            </div>
          </div>
        </div>
      </div>
      
      <!-- Order Status Information when loaded -->
      <div v-else class="dark:text-gray-300">
        <div class="flex items-center justify-center mb-3">
          <div
            class="mx-auto max-w-3xl py-6 px-4 sm:px-6 w-full lg:py-8 lg:px-8 bg-white dark:bg-gray-800 rounded-xl shadow-md transition-all duration-300"
          >
            <!-- Order Number and Date -->
            <div class="text-center mb-4 border-b border-gray-200 dark:border-gray-700 pb-3">
              <h1 class="text-xl font-bold text-gray-900 dark:text-gray-100 mb-1 transition-colors duration-200">
                Order No: {{ getOrderData?.order_id }}
              </h1>
              <p class="text-gray-600 dark:text-gray-400 transition-colors duration-200">{{ getOrderData?.creation_datetime }}</p>
            </div>
            
            <div class="text-center">
              <!-- Success -->
              <div
                v-if="getOrderData?.order_status == 'SUCCESS'"
                class="flex flex-col justify-center items-center gap-y-2 py-3"
              >
                <CheckCircleIcon class="h-14 w-14 text-green-500" aria-hidden="true" />
                <h2 class="text-2xl font-bold tracking-tight sm:text-3xl dark:text-white transition-colors duration-200">
                  <span class="block text-primary-600 dark:text-primary-400">
                    Order Accepted!
                  </span>
                </h2>
                <div class="text-lg text-gray-900 dark:text-gray-200 flex items-center gap-2 transition-colors duration-200 mt-3">
                  <span>Will be ready in</span>
                  <div class="flex items-center gap-2">
                    <ClockIcon class="h-5 w-5 text-gray-700 dark:text-gray-300" />
                    <span class="font-bold">{{
                      getOrderData?.exp_collection_time_mins ||
                      getOrderData?.exp_delivery_time_mins
                    }} minutes</span>
                  </div>
                </div>
              </div>
              
              <!-- Failure -->
              <div
                v-if="getOrderData?.order_status == 'FAILURE'"
                class="flex flex-col justify-center items-center gap-y-2 py-3"
              >
                <XCircleIcon class="h-14 w-14 text-red-500" aria-hidden="true" />
                <h2 class="text-2xl font-bold tracking-tight sm:text-3xl dark:text-white transition-colors duration-200">
                  <span class="block text-red-600 dark:text-red-400">
                    Order Failed!
                  </span>
                </h2>
                <div class="text-gray-900 dark:text-gray-200 text-center max-w-md mx-auto transition-colors duration-200 mt-3">
                  <p>{{ getOrderData?.failure_reason }}</p>
                  <p class="text-gray-600 dark:text-gray-400 transition-colors duration-200">
                    (Your payment has not been processed)
                  </p>
                </div>
                <button
                  type="button"
                  class="mt-4 justify-center rounded-md border border-transparent bg-violet-700 py-2 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-800 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 dark:bg-violet-700 dark:hover:bg-violet-800 transition-colors duration-200"
                  @click="handleReorder()"
                >
                  <ArrowPathIcon class="h-5 w-5 mr-1.5 inline-block" aria-hidden="true" />
                  Try again
                </button>
              </div>
              
              <!-- Cancelled -->
              <div
                v-if="getOrderData?.order_status == 'CANCELLED'"
                class="flex flex-col justify-center items-center gap-y-2 py-3"
              >
                <XCircleIcon class="h-14 w-14 text-red-500" aria-hidden="true" />
                <h2 class="text-2xl font-bold tracking-tight sm:text-3xl dark:text-white transition-colors duration-200">
                  <span class="block text-red-600 dark:text-red-400">
                    Order Cancelled!
                  </span>
                </h2>
                <div class="text-gray-900 dark:text-gray-200 text-center max-w-md mx-auto transition-colors duration-200 mt-3">
                  <p v-if="formattedCancellationReason" class="text-lg font-medium">
                    {{ formattedCancellationReason }}
                  </p>
                  <p v-else-if="getOrderData?.cancel_reason">
                    {{ getOrderData?.cancel_reason }}
                  </p>
                  <p v-else class="text-lg">
                    The restaurant has cancelled this order
                  </p>
                  <p class="text-gray-600 dark:text-gray-400 transition-colors duration-200 mt-1">
                    (Your payment has not been processed)
                  </p>
                </div>
              </div>
            </div>
            
            <!-- Order Details Section - Reused -->
            <div v-if="showOrderDetails" class="w-full px-0 sm:px-2">
              <!-- Compact Order Info -->
              <div class="bg-gray-50 dark:bg-gray-700/50 p-3 rounded-lg mb-3">
                <div class="grid grid-cols-2 gap-x-3 gap-y-2">
                  <!-- Order Type -->
                  <div>
                    <h3 class="text-xs font-medium text-gray-500 dark:text-gray-400 mb-1">Order Type</h3>
                    <span
                      class="inline-flex items-center rounded-full px-2 py-0.5 text-sm font-medium transition-colors duration-200"
                      :class="[
                        getOrderData?.order_type == 'collection'
                          ? 'bg-violet-100 text-violet-800 dark:bg-violet-800 dark:text-violet-100'
                          : 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100'
                      ]"
                    >
                      <ShoppingBagIcon
                        v-if="getOrderData?.order_type == 'collection'"
                        class="h-3.5 w-3.5 mr-1 text-violet-600 dark:text-violet-200"
                        aria-hidden="true"
                      />
                      <svg
                        v-else
                        class="h-3.5 w-3.5 fill-current mr-1 text-green-600 dark:text-green-200"
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path
                          d="M20 11c-.18 0-.36.03-.53.05L17.41 9H20V6l-3.72 1.86L13.41 5H9v2h3.59l2 2H11l-4 2-2-2H0v2h4c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4l2 2h3l3.49-6.1 1.01 1.01c-.91.73-1.5 1.84-1.5 3.09 0 2.21 1.79 4 4 4s4-1.79 4-4-1.79-4-4-4zM4 17c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm16 0c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
                        ></path>
                      </svg>
                      <span class="capitalize">{{ getOrderData?.order_type }}</span>
                    </span>
                  </div>
                  
                  <!-- Payment Method -->
                  <div>
                    <h3 class="text-xs font-medium text-gray-500 dark:text-gray-400 mb-1">Payment</h3>
                    <span
                      class="inline-flex items-center rounded-full px-2 py-0.5 text-sm font-medium transition-colors duration-200"
                      :class="[
                        getOrderData?.payment_type == 'cash'
                          ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100'
                          : 'bg-purple-100 text-purple-800 dark:bg-purple-800 dark:text-purple-100'
                      ]"
                    >
                      <BanknotesIcon
                        v-if="getOrderData?.payment_type == 'cash'"
                        class="h-3.5 w-3.5 mr-1 text-yellow-600 dark:text-yellow-200"
                        aria-hidden="true"
                      />
                      <CreditCardIcon
                        v-else
                        class="h-3.5 w-3.5 mr-1 text-purple-600 dark:text-purple-200"
                        aria-hidden="true"
                      />
                      <span class="capitalize">{{ getOrderData?.payment_type }}</span>
                    </span>
                  </div>
                  
                  <!-- Order Amount -->
                  <div>
                    <h3 class="text-xs font-medium text-gray-500 dark:text-gray-400 mb-1">Total</h3>
                    <p class="text-base font-semibold text-gray-900 dark:text-gray-100 transition-colors duration-200">£{{ getOrderData?.amount }}</p>
                  </div>
                  
                  <!-- Order Status -->
                  <div v-if="getOrderData?.order_status">
                    <h3 class="text-xs font-medium text-gray-500 dark:text-gray-400 mb-1">Status</h3>
                    <span
                      class="inline-flex items-center rounded-full px-2 py-0.5 text-sm font-medium transition-colors duration-200"
                      :class="{
                        'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100': getOrderData?.order_status === 'SUCCESS',
                        'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100': ['FAILURE', 'CANCELLED'].includes(getOrderData?.order_status),
                        'bg-amber-100 text-amber-800 dark:bg-amber-800 dark:text-amber-100': getOrderData?.order_status === 'AUTHORIZED'
                      }"
                    >
                      {{ getOrderData?.order_status }}
                    </span>
                  </div>
                </div>
                
                <!-- Delivery Address (Condensed) -->
                <div v-if="getOrderData?.order_type === 'delivery' && getOrderData?.customer?.address" class="mt-3 border-t border-gray-200 dark:border-gray-700 pt-2">
                  <h3 class="text-xs font-medium text-gray-500 dark:text-gray-400 mb-1">Delivery Address</h3>
                  <p class="text-sm text-gray-700 dark:text-gray-300 transition-colors duration-200">
                    {{ getOrderData?.customer?.address?.address_line1 }}
                    <span v-if="getOrderData?.customer?.address?.address_line2">, {{ getOrderData?.customer?.address?.address_line2 }}</span>
                    <span class="font-medium text-gray-900 dark:text-gray-100">, {{ getOrderData?.customer?.address?.post_code }}</span>
                  </p>
                </div>
              </div>
              
              <!-- Delivery Instructions (if present) -->
              <div v-if="getOrderData?.order_type === 'delivery' && getOrderData?.delivery_instructions" class="bg-gray-50 dark:bg-gray-700/50 p-2 rounded-lg mb-3">
                <h3 class="text-xs font-medium text-gray-500 dark:text-gray-400 mb-0.5">Delivery Instructions</h3>
                <p class="text-sm italic text-gray-700 dark:text-gray-300 transition-colors duration-200">{{ getOrderData?.delivery_instructions }}</p>
              </div>
              
              <!-- Mobile App Promotion -->
              <MobileAppPromotion />
            </div>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<style>
  @keyframes slow-spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .animate-slow-spin {
    animation: slow-spin 3s linear infinite;
  }
</style>
