<script>
import { onMounted, ref, watch, inject } from 'vue'
import { useRouter } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { XCircleIcon } from '@heroicons/vue/20/solid'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useCartGLobalStore } from '@/stores/cartGlobalStore'
import {
  confirmDetailsFn,
  setOrderOptionsFn,
  placeCashOrderSCAFn
} from '@/services/api/restApiCalls'
import StripeCardPayment from './StripeCardPayment.vue'
import LoadingSpinner from '@/components/common/LoadingSpinner/LoadingSpinner.vue'
import { notify } from 'notiwind'

export default {
  components: {
    StripeCardPayment,
    LoadingSpinner,
    XCircleIcon
  },
  setup(props) {
    const IS_MOBILE_APP = inject('IS_MOBILE_APP')
    const router = useRouter()
    const restoStore = useRestoGLobalStore()
    const cartStore = useCartGLobalStore()
    const phoneNumber = ref('')
    const addressLine1 = ref('')
    const addressLine2 = ref('')
    const city = ref('')
    const postCode = ref('')
    const noteForResto = ref('')
    const submitted = ref(false)
    const formError = ref('')
    const handleStripeFn = ref(null)
    const btnConfirmDisable = ref(false)
    const isLoading = ref(false)
    const validationRules = {
      phoneNumber: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(15)
      },
      addressLine1: {
        required
      },
      postCode: {
        required
      }
    }
    const v$ = useVuelidate(validationRules, {
      phoneNumber,
      addressLine1,
      postCode
    })
    const handleDeliveryOrder = async () => {
      formError.value = ''
      submitted.value = true
      v$.value.$touch()
      if (v$.value.$invalid) {
        return
      }
      if (isLoading.value) return
      isLoading.value = true
      try {
        let deliveryOrderFormData = new FormData()
        deliveryOrderFormData.append('resto_id', restoStore?.RESTO_ID)
        deliveryOrderFormData.append('phone_number', phoneNumber.value)
        deliveryOrderFormData.append('address_line1', addressLine1.value)
        deliveryOrderFormData.append('post_code', postCode.value)
        deliveryOrderFormData.append('address_line2', addressLine2.value)
        deliveryOrderFormData.append('city', city.value)

        let orderOptionFormData = new FormData()
        orderOptionFormData.append('resto_id', restoStore?.RESTO_ID)
        orderOptionFormData.append('requested_order_dt', cartStore.CART_DATA?.requested_order_dt)
        orderOptionFormData.append('order_note', noteForResto?.value)
        if (IS_MOBILE_APP) {
          orderOptionFormData.append('is_mobile_app', 1)
        }
        let order_option = await setOrderOptionsFn(orderOptionFormData)

        // Retry if order_option is not set
        if (order_option?.status != 200) {
          order_option = await setOrderOptionsFn(orderOptionFormData)
        }

        if (order_option?.status == 200) {
          const confirm_detail = await confirmDetailsFn(deliveryOrderFormData)
          if (confirm_detail?.status == 200) {
            if (cartStore.CART_DATA?.order_payment_type === 'cash') {
              isLoading.value = true
              btnConfirmDisable.value = true
              handlePlaceOrder()
              isLoading.value = false
              return
            } else {
              handleStripeFn.value.handleStripePayment()
              return
            }
          } else {
            isLoading.value = false
            formError.value = confirm_detail?.status_msg
            cartStore.SHOW_POSTCODE_MODAL = true
            notify(
              {
                group: 'errormessage',
                title: 'Error',
                text: `${formError.value}`
              },
              2000
            )
          }
        } else {
          isLoading.value = false
          router.push({
            name: 'menu',
            params: { menu_slug: restoStore?.MENU_URL }
          })
        }
      } catch (error) {
        console.error('Order Error:', error)
        isLoading.value = false
      }
      isLoading.value = false
    }
    const handlePlaceOrder = async () => {
      let placeOrderFromData = new FormData()
      placeOrderFromData.append('resto_id', restoStore?.RESTO_ID)
      if (cartStore.CART_DATA?.order_payment_type === 'cash') {
        const place_order = await placeCashOrderSCAFn(placeOrderFromData)
        if (place_order.status == 201) {
          let orderId = place_order?.order_id
          router.push({
            name: 'order_status',
            params: { order_id: `${orderId}` }
          })
          cartStore.CART_DATA = ''
        }
      }
    }
    onMounted(async () => {
      isLoading.value = false
      phoneNumber.value = restoStore?.CUSTOMER_INFO?.phone_number || ''
      addressLine1.value = restoStore?.CUSTOMER_INFO?.address.address_line1 || ''
      addressLine2.value = restoStore?.CUSTOMER_INFO?.address.address_line2 || ''
      city.value = restoStore?.CUSTOMER_INFO?.address.city || ''
      postCode.value = cartStore?.CART_DATA?.pc_data?.post_code || ''
      noteForResto.value = cartStore?.CART_DATA.order_note || ''
    })
    watch([phoneNumber, addressLine1, addressLine2, postCode, city, noteForResto], () => {
      formError.value = ''
    })
    watch(
      () => handleStripeFn.value?.payButtonDisabled,
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          isLoading.value = false
        }
      }
    )
    return {
      phoneNumber,
      addressLine1,
      addressLine2,
      city,
      postCode,
      noteForResto,
      submitted,
      formError,
      handleDeliveryOrder,
      v$,
      restoStore,
      router,
      cartStore,
      handleStripeFn,
      isLoading,
      btnConfirmDisable
    }
  }
}
</script>
<template>
  <!-- <div class="text-sm font-medium text-red-600 capitalize">{{ formError }}</div> -->
  <div v-if="formError" class="rounded-md bg-red-50 dark:bg-red-900/20 px-4 py-2">
    <div class="flex justify-center items-center">
      <div class="flex-shrink-0">
        <XCircleIcon class="lg:h-8 lg:w-8 h-6 w-6 text-red-400 dark:text-red-500" aria-hidden="true" />
      </div>
      <div class="ml-2">
        <p class="lg:text-md text-sm lg:font-medium text-red-800 dark:text-red-400">{{ formError }}</p>
      </div>
    </div>
  </div>
  <div>
    <label for="phoneNumber" class="block text-sm font-medium text-gray-900 dark:text-gray-300">Phone Number</label>
    <div class="mt-1">
      <input
        id="phoneNumber"
        v-model="phoneNumber"
        name="phoneNumber"
        type="text"
        autocomplete="phoneNumber"
        :class="{ 'border-red-600': submitted && v$.phoneNumber.$error }"
        class="block w-full appearance-none rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 placeholder-gray-400 dark:placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-200"
      />
    </div>
    <div
      v-for="(error, index) of v$.phoneNumber.$errors"
      :key="index"
      class="input-errors text-sm font-medium text-red-600 dark:text-red-400"
    >
      {{ error.$message }}
    </div>
  </div>
  <div>
    <label for="addressLine1" class="block text-sm font-medium text-gray-900 dark:text-gray-300"
      >Delivery Address Line 1</label
    >
    <div class="mt-1">
      <input
        id="addressLine1"
        v-model="addressLine1"
        name="addressLine1"
        type="text"
        autocomplete="addressLine1"
        :class="{ 'border-red-600': submitted && v$.addressLine1.$error }"
        class="block w-full appearance-none rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 placeholder-gray-400 dark:placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-200"
      />
    </div>
    <div v-for="(error, index) of v$.addressLine1.$errors" :key="index" class="input-errors">
      <div class="text-sm font-medium text-red-600 dark:text-red-400">{{ error.$message }}</div>
    </div>
  </div>
  <div>
    <label for="addressLine2" class="block text-sm font-medium text-gray-900 dark:text-gray-300"
      >Delivery Address Line 2</label
    >
    <div class="mt-1">
      <input
        id="addressLine2"
        v-model="addressLine2"
        name="addressLine2"
        type="text"
        autocomplete="addressLine2"
        class="block w-full appearance-none rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 placeholder-gray-400 dark:placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-200"
      />
    </div>
  </div>
  <div>
    <label for="city" class="block text-sm font-medium text-gray-900 dark:text-gray-300">City</label>
    <div class="mt-1">
      <input
        id="city"
        v-model="city"
        name="city"
        type="text"
        autocomplete="city"
        class="block w-full appearance-none rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 placeholder-gray-400 dark:placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-200"
      />
    </div>
  </div>
  <div>
    <label for="postCode" class="block text-sm font-medium text-gray-900 dark:text-gray-300">Post code</label>
    <div class="mt-1">
      <input
        id="postCode"
        v-model="postCode"
        name="postCode"
        type="text"
        autocomplete="postCode"
        :class="{ 'border-red-600': submitted && v$.postCode.$error }"
        class="block w-full appearance-none rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 placeholder-gray-400 dark:placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-200"
      />
    </div>
    <div v-for="(error, index) of v$.postCode.$errors" :key="index" class="input-errors">
      <div class="text-sm font-medium text-red-600 dark:text-red-400">{{ error.$message }}</div>
    </div>
  </div>
  <div>
    <label for="comment" class="block text-sm font-medium text-gray-900 dark:text-gray-300">Note For Restaurant</label>
    <div class="mt-1">
      <textarea
        id="comment"
        v-model="noteForResto"
        rows="4"
        name="comment"
        placeholder="Add instruction about food or mention any allergy"
        class="block w-full rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 placeholder-gray-400 dark:placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-200"
      ></textarea>
    </div>
  </div>
  <template v-if="cartStore.CART_DATA?.order_payment_type === 'card'">
    <StripeCardPayment ref="handleStripeFn"></StripeCardPayment>
  </template>
  <div v-if="formError" class="rounded-md bg-red-50 dark:bg-red-900/20 px-4 py-2">
    <div class="flex justify-center items-center">
      <div class="flex-shrink-0">
        <XCircleIcon class="lg:h-8 lg:w-8 h-6 w-6 text-red-400 dark:text-red-500" aria-hidden="true" />
      </div>
      <div class="ml-2">
        <p class="lg:text-md text-sm lg:font-medium text-red-800 dark:text-red-400">{{ formError }}</p>
      </div>
    </div>
  </div>
  <button
    type="submit"
    class="w-full justify-center rounded-md border border-transparent bg-violet-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-violet-800 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 disabled:bg-gray-300 disabled:text-gray-400 dark:disabled:bg-gray-700 dark:disabled:text-gray-500"
    @click.prevent.stop="handleDeliveryOrder()"
    :disabled="handleStripeFn?.payButtonDisabled"
  >
    Confirm Payment
  </button>
  <button
    type="submit"
    class="w-full rounded-md border border-transparent bg-gray-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-offset-gray-50 dark:bg-gray-600 dark:hover:bg-gray-700"
    @click="
      $router.push({
        name: 'menu',
        params: { menu_slug: restoStore?.MENU_URL }
      })
    "
  >
    Change Order
  </button>
  <LoadingSpinner :isLoading="isLoading" />
</template>
