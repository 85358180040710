import { useAuthGlobalStore } from '@/stores/authGlobalStore'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'

export function useAppNavigation() {
  const authStore = useAuthGlobalStore()
  const restoStore = useRestoGLobalStore()

  const userSettingsNavLinks = [
    { name: 'Settings', route_name: 'setting' },
    // { name: 'Update Email', route_name: 'updateEmail' },
    { name: 'Update Password', route_name: 'updatePassword' }
  ]

  const giftVoucherNavLink = [{ name: 'Gift Vouchers', route_name: 'giftVoucher' }]
  const reservationNavLink = [{ name: 'Reservation', route_name: 'reservation' }]
  const navLinks = [
    {
      name: 'Menu',
      route_name: 'menu',
      params: { menu_slug: restoStore.MENU_URL },
      query: {}
    },
    ...(restoStore?.restoInfo?.is_gift_voucher_active ? giftVoucherNavLink : []),
    ...(restoStore?.restoInfo?.is_reservation_active ? reservationNavLink : []),
    { name: 'Signup', route_name: 'signup' },
    { name: 'Login', route_name: 'login' }
  ]
  const loggedNavLinks = [
    {
      name: 'Menu',
      route_name: 'menu',
      params: { menu_slug: 'test-resto-london' },
      query: {}
    },
    { name: 'My Orders', route_name: 'my_orders' },
    ...(restoStore?.restoInfo?.is_gift_voucher_active ? giftVoucherNavLink : []),
    ...(restoStore?.restoInfo?.is_reservation_active ? reservationNavLink : []),
    { name: 'Settings', route_name: 'setting' },
  ]

  const getNavLinks = () => {
    return authStore.isLoggedIn ? loggedNavLinks : navLinks
  }

  // display these links as sublinks in the user settings page
  const getUserSettingsNavLinks = () => {
    return authStore.isLoggedIn ? userSettingsNavLinks : []
  }

  return {
    getNavLinks,
    getUserSettingsNavLinks
  }
}
