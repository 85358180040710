<script setup>
import { ref } from 'vue'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronUpIcon } from '@heroicons/vue/20/solid'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useCartGLobalStore } from '@/stores/cartGlobalStore'
import { addSimpleItemFn } from '@/services/api/restApiCalls'
import { notify } from 'notiwind'
import giftVoucherBgImg from '@/assets/images/gift-voucher-bg.png'

const restoStore = useRestoGLobalStore()
const cartStore = useCartGLobalStore()
const itemQuantity = ref(1)

const handleAddGiftVoucher = async (giftItem) => {
  console.log('simpleItem', giftItem)
  let giftItemData = {}
  if (giftItem.is_complex_item) {
    giftItemData = {
      action: 'add_complex_item',
      resto_id: restoStore?.RESTO_ID,
      item_id: giftItem?.item_id,
      optional_extras: [],
      accesory_extras: [],
      quantity: itemQuantity.value
    }
  } else if (giftItem.is_meal) {
    giftItemData = {
      action: 'add_meal_item',
      resto_id: restoStore.RESTO_ID,
      item_id: giftItem?.item_id,
      meal_parts: [],
      quantity: itemQuantity.value
    }
  } else {
    giftItemData = {
      action: 'add',
      resto_id: restoStore?.RESTO_ID,
      item_id: giftItem.item_id
    }
  }
  let giftItemFormData = new FormData()
  giftItemFormData.append('cart_data', JSON.stringify(giftItemData))
  const cart_info = await addSimpleItemFn(giftItemFormData)
  cartStore.CART_DATA = cart_info[restoStore?.RESTO_ID]
  cartStore.CAN_DO_CHECKOUT = cart_info?.can_do_checkout
  // restoStore.hideModal()
  notify(
    {
      group: 'success',
      title: 'Added',
      text: `${giftItem?.item_name} added to cart`
    },
    2000
  )
}
</script>
<template>
  <div class="md:rounded-lg border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 md:mb-0 mb-24">
    <template v-for="category in restoStore.MENU_DATA?.categories" :key="category.category_id">
      <template v-if="category.category_name === 'Gift Voucher'">
        <div
          class="flex w-full justify-between rounded-lg bg-white dark:bg-gray-800 md:px-4 sm:px-3 px-2 py-10 mb-4 text-left text-sm font-medium text-black-900 dark:text-gray-100 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-gray-50 dark:focus-visible:ring-gray-700 focus-visible:ring-opacity-75"
          :style="{
            backgroundImage: `url(${giftVoucherBgImg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }"
        >
          <h2
            class="text-lg lg:text-xl font-bold tracking-tight text-white sm:text-2xl hover:text-indigo-600 dark:hover:text-indigo-400"
          >
            GIFT VOUCHERS
          </h2>
        </div>
        <div class="md:px-4 sm:px-3 px-2 pt-0 md:pb-4 sm:pb-3 pb-2 text-sm text-gray-500 dark:text-gray-400 bg-white dark:bg-gray-800">
          <div class="grid lg:grid-cols-2 grid-cols-1 gap-2">
            <template v-for="product in category.products" :key="product.product_id">
              <template v-for="item in product.items" :key="item.item_id">
                <div
                  v-if="item?.menu_img_url"
                  class="flex items-center rounded-lg border border-gray-300 dark:border-gray-700 p-3 cursor-pointer hover:border-gray-400 dark:hover:border-gray-600 bg-white dark:bg-gray-800"
                  @click="handleAddGiftVoucher(item)"
                >
                  <div class="min-w-0 flex-1">
                    <p class="text-md font-medium text-gray-900 dark:text-gray-100">
                      {{ item.item_name_full }}
                    </p>
                    <p v-if="item.info" class="truncate text-sm text-gray-500 dark:text-gray-400 mb-0">
                      {{ item.info }}
                    </p>
                    <div class="mt-1 text-base text-gray-900 dark:text-gray-100">
                      <span> £{{ item.price }}</span>
                    </div>
                  </div>
                  <div v-if="item?.menu_img_url" class="flex-shrink-0 bg-gray-500 dark:bg-gray-600 rounded">
                    <img
                      class="h-14 w-14"
                      :src="item?.menu_img_url"
                      loading="lazy"
                      decoding="async"
                      alt=""
                    />
                  </div>
                </div>
                <div
                  v-else
                  class="flex items-center rounded-lg border border-gray-300 dark:border-gray-700 p-3 cursor-pointer hover:border-gray-400 dark:hover:border-gray-600 bg-white dark:bg-gray-800"
                  @click="handleAddGiftVoucher(item)"
                >
                  <div class="min-w-0 flex-1">
                    <p class="text-md font-medium text-gray-900 dark:text-gray-100">
                      {{ item.item_name_full }}
                    </p>
                    <p v-if="item.info" class="truncate text-sm text-gray-500 dark:text-gray-400 mb-0">
                      {{ item.info }}
                    </p>
                  </div>
                  <div class="ml-3 text-gray-900 dark:text-gray-100">
                    <span> £{{ item.price }}</span>
                  </div>
                  <div v-if="item.imageUrl" class="flex-shrink-0">
                    <img class="h-12 w-12 rounded-full" :src="item.imageUrl" alt="" />
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>
