<script setup>
import { MinusCircleIcon, PlusCircleIcon, ShoppingCartIcon } from '@heroicons/vue/20/solid'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useCartGLobalStore } from '@/stores/cartGlobalStore'
import {
  addSimpleItemFn,
  removeSimpleItemFn,
  addComplexItemFn,
  removeComplexItemFn,
  addMealItemFn,
  removeMealItemFn
} from '@/services/api/restApiCalls'
import { notify } from 'notiwind'

const restoStore = useRestoGLobalStore()
const cartStore = useCartGLobalStore()

const addSimpleItem = async (item) => {
  const simpleItemObj = {
    action: 'add',
    resto_id: restoStore?.RESTO_ID,
    item_id: item?.item_id
  }
  var simpleItemFormData = new FormData()
  simpleItemFormData.append('cart_data', JSON.stringify(simpleItemObj))
  const cart_info = await addSimpleItemFn(simpleItemFormData)
  cartStore.CART_DATA = cart_info[restoStore?.RESTO_ID]
  cartStore.CAN_DO_CHECKOUT = cart_info?.can_do_checkout
  notify(
    {
      group: 'success',
      title: 'Added',
      text: `${item?.item_name} added to cart`
    },
    2000
  )
}
const removeSimpleItem = async (item) => {
  const simpleItemObj = {
    action: 'remove',
    resto_id: restoStore?.RESTO_ID,
    item_id: item?.item_id
  }
  var simpleItemFormData = new FormData()
  simpleItemFormData.append('cart_data', JSON.stringify(simpleItemObj))
  const cart_info = await removeSimpleItemFn(simpleItemFormData)
  cartStore.CART_DATA = cart_info[restoStore?.RESTO_ID]
  cartStore.CAN_DO_CHECKOUT = cart_info?.can_do_checkout
  notify(
    {
      group: 'error',
      title: 'Removed',
      text: `${item?.item_name} removed from cart`
    },
    2000
  )
}
const addComplexItem = async (item, citem_id) => {
  const complexItemData = {
    action: 'add_complex_item',
    citem_id: citem_id,
    resto_id: restoStore?.RESTO_ID,
    item_id: item?.item_id
  }
  var complexItemFormData = new FormData()
  complexItemFormData.append('cart_data', JSON.stringify(complexItemData))
  const cart_info = await addComplexItemFn(complexItemFormData)
  cartStore.CART_DATA = cart_info[restoStore?.RESTO_ID]
  cartStore.CAN_DO_CHECKOUT = cart_info?.can_do_checkout
  notify(
    {
      group: 'success',
      title: 'Added',
      text: `${item?.item_name} added to cart`
    },
    2000
  )
}
const removeComplexItem = async (item, citem_id) => {
  const complexItemData = {
    action: 'remove_complex_item',
    citem_id: citem_id,
    resto_id: restoStore?.RESTO_ID,
    item_id: item?.item_id
  }
  var complexItemFormData = new FormData()
  complexItemFormData.append('cart_data', JSON.stringify(complexItemData))
  const cart_info = await removeComplexItemFn(complexItemFormData)
  cartStore.CART_DATA = cart_info[restoStore?.RESTO_ID]
  cartStore.CAN_DO_CHECKOUT = cart_info?.can_do_checkout
  notify(
    {
      group: 'error',
      title: 'Removed',
      text: `${item?.item_name} removed from cart`
    },
    2000
  )
}
const addMealItem = async (item, mitem_id) => {
  const mealItemData = {
    action: 'add_meal_item',
    mitem_id: mitem_id,
    resto_id: restoStore?.RESTO_ID,
    item_id: item?.item_id
  }
  var mealItemFormData = new FormData()
  mealItemFormData.append('cart_data', JSON.stringify(mealItemData))
  const cart_info = await addMealItemFn(mealItemFormData)
  cartStore.CART_DATA = cart_info[restoStore?.RESTO_ID]
  cartStore.CAN_DO_CHECKOUT = cart_info?.can_do_checkout
  notify(
    {
      group: 'success',
      title: 'Added',
      text: `${item?.item_name} added to cart`
    },
    2000
  )
}
const removeMealItem = async (item, mitem_id) => {
  const mealItemData = {
    action: 'remove_meal_item',
    mitem_id: mitem_id,
    resto_id: restoStore?.RESTO_ID,
    item_id: item?.item_id
  }
  var mealItemFormData = new FormData()
  mealItemFormData.append('cart_data', JSON.stringify(mealItemData))
  const cart_info = await removeMealItemFn(mealItemFormData)
  cartStore.CART_DATA = cart_info[restoStore?.RESTO_ID]
  cartStore.CAN_DO_CHECKOUT = cart_info?.can_do_checkout
  notify(
    {
      group: 'error',
      title: 'Removed',
      text: `${item?.item_name} removed from cart`
    },
    2000
  )
}
</script>
<template>
  <!-- <div class="text-right" v-if="cartStore?.CART_DATA?.order_total_item_quantity">
    <button
      type="button"
      class="border border-transparent shadow-sm focus:outline-none focus:ring-2"
      @click="removeAllItems()"
    >
      <TrashIcon class="h-5 w-5 text-violet-700 mx-auto" aria-hidden="true" />
    </button>
  </div> -->
  <div
    v-if="cartStore?.CART_DATA?.order_total_item_quantity == 0"
    class="w-full h-full py-5 flex items-center justify-center flex-col gap-3"
  >
    <ShoppingCartIcon class="h-24 w-24 text-gray-300 dark:text-gray-700" aria-hidden="true" />
    <h3 class="mt-2 text-md font-medium text-gray-900 dark:text-text-dark">Your cart is empty</h3>
    <p class="mt-1 text-sm text-gray-500 dark:text-gray-400">Add items to your cart to start ordering</p>
  </div>
  <!-- <template v-if="cartStore?.CART_DATA?.items">
    <div
      v-for="item in Object.keys(cartStore?.CART_DATA?.items)"
      :key="item"
      class="text-sm lg:text-md flex justify-between mb-2"
    >
      <div class="flex items-center">
        <p class="text-sm font-medium text-gray-900">
          {{ cartStore?.CART_DATA?.items[item]?.item?.item_name }}
        </p>
      </div>
      <div class="flex items-center justify-between w-28">
        <button
          type="button"
          class="rounded-full border border-transparent text-white shadow-sm focus:outline-none focus:ring-2"
          @click="removeSimpleItem(cartStore?.CART_DATA?.items[item]?.item)"
        >
          <MinusCircleIcon class="h-6 w-6 text-red-500 mx-auto" aria-hidden="true" />
        </button>
        <span class="text-sm lg:text-md mx-1">{{
          cartStore?.CART_DATA?.items[item]?.quantity
        }}</span>
        <button
          type="button"
          class="rounded-full border border-transparent text-white shadow-sm focus:outline-none focus:ring-2"
          @click="addSimpleItem(cartStore?.CART_DATA?.items[item]?.item)"
        >
          <PlusCircleIcon class="h-6 w-6 text-emerald-600 mx-auto" aria-hidden="true" />
        </button>
        <span class="text-sm lg:text-md ml-1"
          >£{{ cartStore?.CART_DATA?.items[item]?.total_price }}</span
        >
      </div>
    </div>
  </template>
  <template v-if="cartStore?.CART_DATA?.c_items">
    <div
      v-for="item in Object.keys(cartStore?.CART_DATA?.c_items)"
      :key="item"
      class="text-sm lg:text-md flex justify-between mb-2"
    >
      <div class="mr-3">
        <div class="flex items-center">
          <p class="text-sm font-medium text-gray-900">
            {{ cartStore?.CART_DATA?.c_items[item]?.item?.item_name }}
          </p>
        </div>
        <div
          v-if="cartStore?.CART_DATA?.c_items[item]?.accesory_extras.length"
          class="w-full ml-2 text-gray-600"
        >
          +<template
            v-for="(acc_extra, index) in cartStore?.CART_DATA?.c_items[item]?.accesory_extras"
            :key="acc_extra.extra_id"
            >{{ acc_extra.extra_name
            }}<span v-if="index < cartStore?.CART_DATA?.c_items[item]?.accesory_extras.length - 1"
              >,&nbsp;</span
            ></template
          >
        </div>
        <div
          v-if="cartStore?.CART_DATA?.c_items[item]?.optional_extras.length"
          class="w-full ml-2 text-gray-600"
        >
          +<template
            v-for="(opt_extra, index) in cartStore?.CART_DATA?.c_items[item]?.optional_extras"
            :key="opt_extra.extra_id"
            >{{
              opt_extra.quantity +
              'x' +
              opt_extra.extra.extra_name +
              ' - £' +
              opt_extra.total_price
            }}<span v-if="index < cartStore?.CART_DATA?.c_items[item]?.optional_extras.length - 1"
              >,&nbsp;</span
            ></template
          >
        </div>
      </div>
      <div class="flex items-center justify-between w-28">
        <button
          type="button"
          class="rounded-full border border-transparent text-white shadow-sm focus:outline-none focus:ring-2"
          @click="
            removeComplexItem(
              cartStore?.CART_DATA?.c_items[item]?.item,
              cartStore?.CART_DATA?.c_items[item]?.citem_id
            )
          "
        >
          <MinusCircleIcon class="h-6 w-6 text-red-500 mx-auto" aria-hidden="true" />
        </button>
        <span class="text-sm lg:text-md mx-1">{{
          cartStore?.CART_DATA?.c_items[item]?.quantity || 1
        }}</span>

        <button
          type="button"
          class="rounded-full border border-transparent text-white shadow-sm focus:outline-none focus:ring-2"
          @click="
            addComplexItem(
              cartStore?.CART_DATA?.c_items[item]?.item,
              cartStore?.CART_DATA?.c_items[item]?.citem_id
            )
          "
        >
          <PlusCircleIcon class="h-6 w-6 text-emerald-600 mx-auto" aria-hidden="true" />
        </button>
        <span class="text-sm lg:text-md ml-1"
          >£{{ cartStore?.CART_DATA?.c_items[item]?.total_price }}</span
        >
      </div>
    </div>
  </template>
  <template v-if="cartStore?.CART_DATA?.m_items">
    <div
      v-for="item in Object.keys(cartStore?.CART_DATA?.m_items)"
      :key="item"
      class="text-sm lg:text-md flex justify-between mb-2"
    >
      <div class="mr-3">
        <div class="flex items-center">
          <p class="text-sm font-medium text-gray-900">
            {{ cartStore?.CART_DATA?.m_items[item]?.item?.item_name }}
          </p>
        </div>
        <div
          v-for="mealPart in cartStore?.CART_DATA?.m_items[item]?.meal_parts"
          :key="mealPart.meal_item.item_id"
          class="w-full mb-1 ml-4"
        >
          <p>{{ mealPart?.meal_item.item_name }}</p>
          <div v-if="mealPart?.accesory_extras" class="w-full text-gray-600">
            +<template
              v-for="(acc_extra, index) in mealPart?.accesory_extras"
              :key="acc_extra.extra_id"
              >{{ acc_extra.extra_name
              }}<span v-if="index < mealPart?.accesory_extras.length - 1">,&nbsp;</span></template
            >
          </div>
          <div v-if="mealPart?.optional_extras.length" class="w-full text-gray-600">
            +<template v-for="opt_extra in mealPart?.optional_extras" :key="opt_extra.extra_id">{{
              opt_extra.quantity + 'x' + opt_extra.extra.extra_name + ' - £' + opt_extra.total_price
            }}</template>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between w-28">
        <button
          type="button"
          class="rounded-full border border-transparent text-white shadow-sm focus:outline-none focus:ring-2"
          @click="
            removeMealItem(
              cartStore?.CART_DATA?.m_items[item]?.item,
              cartStore?.CART_DATA?.m_items[item]?.mitem_id
            )
          "
        >
          <MinusCircleIcon class="h-6 w-6 text-red-500 mx-auto" aria-hidden="true" />
        </button>
        <span class="text-sm lg:text-md mx-1">{{
          cartStore?.CART_DATA?.m_items[item]?.quantity || 1
        }}</span>

        <button
          type="button"
          class="rounded-full border border-transparent text-white shadow-sm focus:outline-none focus:ring-2"
          @click="
            addMealItem(
              cartStore?.CART_DATA?.m_items[item]?.item,
              cartStore?.CART_DATA?.m_items[item]?.mitem_id
            )
          "
        >
          <PlusCircleIcon class="h-6 w-6 text-emerald-600 mx-auto" aria-hidden="true" />
        </button>
        <span class="text-sm lg:text-md ml-1"
          >£{{ cartStore?.CART_DATA?.m_items[item]?.total_price }}</span
        >
      </div>
    </div>
  </template> -->

  <template v-if="cartStore?.CART_DATA?.all_items">
    <div
      v-for="item in cartStore?.CART_DATA?.all_items"
      :key="item"
      class="text-sm lg:text-md flex justify-between items-start mb-2"
    >
      <div class="mr-3">
        <div class="flex items-center">
          <p class="text-sm font-medium text-gray-900 dark:text-text-dark">
            {{ item?.item?.item_name }}
          </p>
        </div>
        <div v-if="item?.accesory_extras?.length" class="w-full ml-2 text-gray-600 dark:text-gray-400">
          +<template v-for="(acc_extra, index) in item?.accesory_extras" :key="acc_extra.extra_id"
            >{{ acc_extra?.extra_name }}
            <span v-if="acc_extra?.price > 0"> - £{{ acc_extra?.price }}</span>
            <span v-if="index < item?.accesory_extras.length - 1">,&nbsp;</span>
          </template>
        </div>
        <div v-if="item?.optional_extras?.length" class="w-full ml-2 text-gray-600 dark:text-gray-400">
          <div v-for="(opt_extra, index) in item?.optional_extras" :key="opt_extra.extra_id">
            +{{
              opt_extra.quantity + 'x' + opt_extra.extra.extra_name + ' - £' + opt_extra.total_price
            }}
          </div>
        </div>
        <div
          v-for="mealPart in item?.meal_parts"
          :key="mealPart.meal_item.item_id"
          class="w-full mb-1 ml-4 dark:text-text-dark"
        >
          <p>{{ mealPart?.meal_item.item_name }}</p>
          <div v-if="mealPart?.accesory_extras" class="w-full text-gray-600 dark:text-gray-400">
            +<template
              v-for="(acc_extra, index) in mealPart?.accesory_extras"
              :key="acc_extra.extra_id"
              >{{ acc_extra.extra_name
              }}<span v-if="index < mealPart?.accesory_extras.length - 1">,&nbsp;</span></template
            >
          </div>
          <div v-if="mealPart?.optional_extras.length" class="w-full text-gray-600 dark:text-gray-400">
            <div v-for="opt_extra in mealPart?.optional_extras" :key="opt_extra.extra_id">
              +{{
                opt_extra.quantity +
                'x' +
                opt_extra.extra.extra_name +
                ' - £' +
                opt_extra.total_price
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between w-28">
        <button
          v-if="item?.item?.is_complex_item"
          type="button"
          class="rounded-full border border-transparent text-white shadow-sm focus:outline-none focus:ring-2"
          @click="removeComplexItem(item?.item, item?.citem_id)"
        >
          <MinusCircleIcon class="h-6 w-6 text-red-500 dark:text-red-400 mx-auto" aria-hidden="true" />
        </button>
        <button
          v-else-if="item?.item?.is_meal"
          type="button"
          class="rounded-full border border-transparent text-white shadow-sm focus:outline-none focus:ring-2"
          @click="removeMealItem(item?.item, item?.mitem_id)"
        >
          <MinusCircleIcon class="h-6 w-6 text-red-500 dark:text-red-400 mx-auto" aria-hidden="true" />
        </button>
        <button
          v-else
          type="button"
          class="rounded-full border border-transparent text-white shadow-sm focus:outline-none focus:ring-2"
          @click="removeSimpleItem(item?.item)"
        >
          <MinusCircleIcon class="h-6 w-6 text-red-500 dark:text-red-400 mx-auto" aria-hidden="true" />
        </button>
        <span class="text-sm lg:text-md mx-1 text-gray-900 dark:text-text-dark">{{ item?.quantity }}</span>
        <button
          v-if="item?.item?.is_complex_item"
          type="button"
          class="rounded-full border border-transparent text-white shadow-sm focus:outline-none focus:ring-2"
          @click="addComplexItem(item?.item, item?.citem_id)"
        >
          <PlusCircleIcon class="h-6 w-6 text-emerald-600 dark:text-emerald-500 mx-auto" aria-hidden="true" />
        </button>
        <button
          v-else-if="item?.item?.is_meal"
          type="button"
          class="rounded-full border border-transparent text-white shadow-sm focus:outline-none focus:ring-2"
          @click="addMealItem(item?.item, item?.mitem_id)"
        >
          <PlusCircleIcon class="h-6 w-6 text-emerald-600 dark:text-emerald-500 mx-auto" aria-hidden="true" />
        </button>
        <button
          v-else
          type="button"
          class="rounded-full border border-transparent text-white shadow-sm focus:outline-none focus:ring-2"
          @click="addSimpleItem(item?.item)"
        >
          <PlusCircleIcon class="h-6 w-6 text-emerald-600 dark:text-emerald-500 mx-auto" aria-hidden="true" />
        </button>
        <span class="text-sm lg:text-md ml-1 text-gray-900 dark:text-text-dark">£{{ item?.total_price }}</span>
      </div>
    </div>
  </template>
</template>
