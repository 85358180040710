<script setup>
import { onMounted, onBeforeUnmount, watch, ref } from 'vue'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import '@/assets/css/smartbanner.css'
import playstoreIcon from '@/assets/images/playstore-icon.svg'
import appstoreIcon from '@/assets/images/appstore-icon.svg'
import SmartBanner from '@/assets/js/smartbanner.js'

const restoStore = useRestoGLobalStore()
const smartbannerInstance = ref(null)

// Function to handle theme changes
const applyDarkModeClasses = () => {
  // Only apply if banner exists
  if (!smartbannerInstance.value) return
  
  const isDarkMode = document.documentElement.classList.contains('dark')
  const bannerElement = document.querySelector('.smartbanner')
  
  if (!bannerElement) return
  
  // Apply or remove dark mode classes
  if (isDarkMode) {
    bannerElement.classList.add('smartbanner--dark')
    bannerElement.classList.add('transition-colors')
    bannerElement.classList.add('duration-200')
  } else {
    bannerElement.classList.remove('smartbanner--dark')
  }
}

// Initialize the SmartBanner
const initSmartBanner = () => {
  let apple_app_id = restoStore.restoInfo?.mobileapp_ids.itunes_app_id || ''
  let google_app_id = restoStore.restoInfo?.mobileapp_ids.playstore_app_id || ''
  let enabledPlatforms = []
  
  if (apple_app_id) enabledPlatforms.push('ios')
  if (google_app_id) enabledPlatforms.push('android')

  try {
    const options = {
      title: restoStore.restoInfo?.name || '',
      author: 'OnlyGulp',
      buttonUrlGoogle: google_app_id
        ? 'https://play.google.com/store/apps/details?id=' + google_app_id
        : '',
      buttonUrlApple: apple_app_id ? 'https://apps.apple.com/app/' + apple_app_id : '',
      iconGoogle: playstoreIcon,
      iconApple: appstoreIcon,
      button: 'Install',
      price: 'Download for FREE',
      enabledPlatforms: enabledPlatforms.join(',')
    }
    
    // Only initialize if there is at least one platform enabled
    if (enabledPlatforms.length > 0) {
      smartbannerInstance.value = new SmartBanner(options)
      if (smartbannerInstance.value.apiEnabled) {
        window.smartbanner = smartbannerInstance.value
      } else {
        smartbannerInstance.value.publish()
      }
      
      // Apply dark mode classes immediately after initialization
      setTimeout(applyDarkModeClasses, 100)
    }
  } catch (error) {
    console.error('Error initializing SmartBanner:', error.message)
  }
}

// Watch for theme changes (this is a simplified approach, you may need to adjust based on how themes are toggled)
const setupThemeWatcher = () => {
  // Create a mutation observer to watch for class changes on the html element
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.attributeName === 'class') {
        applyDarkModeClasses()
      }
    })
  })
  
  // Start observing
  observer.observe(document.documentElement, { attributes: true })
  
  // Return cleanup function
  return () => observer.disconnect()
}

onMounted(() => {
  initSmartBanner()
  const cleanup = setupThemeWatcher()
  
  // Cleanup when component is unmounted
  onBeforeUnmount(cleanup)
})
</script>

<template>
  <div id="smartbanner" class="transition-colors duration-200"></div>
</template>

<style>
/* Base styles */
.smartbanner {
  transition: background-color 200ms ease, color 200ms ease, box-shadow 200ms ease;
}

/* Dark mode class that will be added/removed by our code */
.smartbanner.smartbanner--dark {
  background: #2d3748;
  color: #e2e8f0;
}

.smartbanner.smartbanner--dark .smartbanner__exit::before,
.smartbanner.smartbanner--dark .smartbanner__exit::after {
  background: #a0aec0;
}

.smartbanner.smartbanner--dark .smartbanner__info {
  color: #e2e8f0;
}

.smartbanner.smartbanner--dark .smartbanner__info__title {
  color: #f7fafc;
}

.smartbanner.smartbanner--dark .smartbanner__button {
  background: #4a5568;
  color: #63b3ed;
}

/* Android specific dark mode */
.smartbanner.smartbanner--android.smartbanner--dark {
  background: #1a202c;
  box-shadow: inset 0 4px 0 #6366f1;
}

.smartbanner.smartbanner--android.smartbanner--dark .smartbanner__info {
  color: #e2e8f0;
  text-shadow: none;
}

.smartbanner.smartbanner--android.smartbanner--dark .smartbanner__info__title {
  color: #f7fafc;
}

.smartbanner.smartbanner--android.smartbanner--dark .smartbanner__button__label {
  background: #4c1d95;
  background: linear-gradient(to bottom, #6366f1, #4c1d95);
}

.smartbanner.smartbanner--android.smartbanner--dark .smartbanner__button__label:active,
.smartbanner.smartbanner--android.smartbanner--dark .smartbanner__button__label:hover {
  background: #7c3aed;
}
</style>
