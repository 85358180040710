import { defineStore } from 'pinia'
import { ref, watch, onMounted } from 'vue'

export const useThemeStore = defineStore('theme', () => {
  // State
  const theme = ref<'light' | 'dark'>('light')

  // Get theme from localStorage or system preference
  const getThemePreference = (): 'light' | 'dark' => {
    if (typeof localStorage === 'undefined') return 'light'
    
    const storedTheme = localStorage.getItem('theme')
    
    if (storedTheme) {
      try {
        const themeObj = JSON.parse(storedTheme)
        return themeObj.theme === 'dark' ? 'dark' : 'light'
      } catch (e) {
        console.error('Error parsing theme from localStorage, fallback to light mode')
        return 'light'
      }
    } else {
      // If no stored preference, check system preference
      const prefersDark = window.matchMedia && 
        window.matchMedia('(prefers-color-scheme: dark)').matches
      return prefersDark ? 'dark' : 'light'
    }
  }

  // Methods
  const setTheme = (newTheme: 'light' | 'dark') => {
    theme.value = newTheme
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('theme', JSON.stringify({ theme: newTheme }))
    }
    applyTheme()
  }

  const toggleTheme = () => {
    const newTheme = theme.value === 'light' ? 'dark' : 'light'
    setTheme(newTheme)
  }

  const applyTheme = () => {
    if (typeof document !== 'undefined') {
      if (theme.value === 'dark') {
        document.documentElement.classList.add('dark')
      } else {
        document.documentElement.classList.remove('dark')
      }
    }
  }

  // Initialize theme 
  const init = () => {
    if (typeof window === 'undefined') return // Skip if not in browser
    
    // Get theme preference
    const themePreference = getThemePreference()
    theme.value = themePreference
    
    // Store the theme in the correct format
    localStorage.setItem('theme', JSON.stringify({ theme: themePreference }))
    
    // Apply theme to document
    applyTheme()

    // Listen for system preference changes
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e) => {
        // Only update if user hasn't explicitly set a preference
        if (!localStorage.getItem('theme')) {
          const newTheme = e.matches ? 'dark' : 'light'
          theme.value = newTheme
          localStorage.setItem('theme', JSON.stringify({ theme: newTheme }))
          applyTheme()
        }
      })
  }

  // Run initialization immediately if in browser
  if (typeof window !== 'undefined') {
    init()
    // Add a watch to persist changes
    watch(theme, () => {
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem('theme', JSON.stringify({ theme: theme.value }))
      }
      applyTheme()
    })
  }

  return {
    theme,
    setTheme,
    toggleTheme,
    init
  }
}, {
  // Configure persistence options
  persist: false // We're handling persistence manually
}) 