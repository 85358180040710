import { ref, inject } from 'vue'
import { PushNotifications } from '@capacitor/push-notifications'
import { addDeviceTokenFn, removeDeviceTokenFn } from '@/services/api/restApiCalls'
import { useAuthGlobalStore } from '@/stores/authGlobalStore'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'

export function useMobilePushNotification() {
  const authStore = useAuthGlobalStore()
  const restoGlobalStore = useRestoGLobalStore()
  const deviceToken = ref('')

  const addListeners = async () => {
    console.log('token listener registered')
    PushNotifications.addListener('registration', async (tokenData) => {
      console.log('Push registration success:', tokenData.value)
      if (authStore.isLoggedIn) {
        if (!deviceToken.value) {
          deviceToken.value = tokenData.value
          let token = await handleAddDeviceToken(tokenData.value)
        } else {
          console.log('Device token already registered on server:', deviceToken.value)
        }
      }
    })

    PushNotifications.addListener('registrationError', (err) => {
      console.error('Push registration error:', err.error)
      return err.error
    })

    PushNotifications.addListener('pushNotificationReceived', (notification) => {
      console.log('Notification received:', notification)
      return notification
    })

    PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
      console.log('Notification action performed:', notification)
      return notification
    })
  }

  const removeAllListeners = () => {
    PushNotifications.removeAllListeners()
  }

  const registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions()

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions()
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!')
    }

    await PushNotifications.register()
  }

  // send device token
  const handleAddDeviceToken = async (token) => {
    let deviceTokenFormData = new FormData()
    deviceTokenFormData.append('device_token', token)
    try {
      const add_device_token_res = await addDeviceTokenFn(
        restoGlobalStore?.RESTO_ID,
        deviceTokenFormData
      )
      console.log('Device token sent successfully:')
    } catch (error) {
      console.error('Failed to send device token:', error)
      deviceToken.value = ''
    }
    return token
  }

  // Remove device token
  const handleRemoveDeviceToken = async () => {
    let deviceTokenFormData = new FormData()
    deviceTokenFormData.append('device_token', deviceToken.value)
    try {
      const remove_device_token_res = await removeDeviceTokenFn(
        restoGlobalStore?.RESTO_ID,
        deviceTokenFormData
      )
      console.log('Device token removed:')
    } catch (error) {
      console.error('Failed to send device token:', error)
    }
    deviceToken.value = ''
  }

  return {
    addListeners,
    registerNotifications,
    handleRemoveDeviceToken,
    removeAllListeners
  }
}
