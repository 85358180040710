<script>
import AppLayout from '@/layouts/AppLayout.vue'
import SettingMenuLink from '@/views/account/setting/common/SettingMenuLink.vue'
import { ref, computed, onMounted, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength, maxLength } from '@vuelidate/validators'
import { CheckCircleIcon, XCircleIcon, UserIcon, EnvelopeIcon, PhoneIcon, HomeIcon, MapPinIcon } from '@heroicons/vue/20/solid'
import { updateCustomerInfoFn, getCustomerInfoFn } from '@/services/api/restApiCalls'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useRouter } from 'vue-router'
import LoadingSpinner from '@/components/common/LoadingSpinner/LoadingSpinner.vue'
import { notify } from 'notiwind'

export default {
  components: {
    AppLayout,
    CheckCircleIcon,
    XCircleIcon,
    UserIcon,
    EnvelopeIcon,
    PhoneIcon,
    HomeIcon,
    MapPinIcon,
    SettingMenuLink,
    LoadingSpinner
  },
  setup() {
    // const authStore = useAuthGlobalStore()
    const restoStore = useRestoGLobalStore()
    const router = useRouter()

    const firstName = ref('')
    const lastName = ref('')
    const emailId = ref('')
    const phoneNumber = ref('')
    const addressLine1 = ref('')
    const addressLine2 = ref('')
    const postCode = ref('')
    const city = ref('')
    const submitted = ref(false)
    const emailDuplicate = ref(false)
    const emailInvalid = ref(false)
    const emailValid = ref(false)
    const isLoading = ref(false)
    const validationRules = computed(() => {
      let rules = {
        firstName: { required },
        lastName: { required },
        emailId: {
          required,
          email
        },
        phoneNumber: { required, minLength: minLength(10), maxLength: maxLength(15) },
        addressLine1: { required },
        postCode: { required }
      }
      return rules
    })
    const v$ = useVuelidate(validationRules, {
      firstName,
      lastName,
      emailId,
      phoneNumber,
      addressLine1,
      postCode
    })
    const handleUpdateCustomer = async () => {
      submitted.value = true
      v$.value.$validate()
      if (v$.value.$invalid) {
        return
      }
      if (isLoading.value) return
      isLoading.value = true
      let userUpdateFormData = new FormData()
      userUpdateFormData.append('first_name', firstName.value)
      userUpdateFormData.append('last_name', lastName.value)
      // userUpdateFormData.append('email', emailId.value)
      userUpdateFormData.append('phone_number', phoneNumber.value)
      userUpdateFormData.append('address_line1', addressLine1.value)
      userUpdateFormData.append('post_code', postCode.value)
      userUpdateFormData.append('address_line2', addressLine2.value)
      userUpdateFormData.append('city', city.value)
      const update_info = await updateCustomerInfoFn(userUpdateFormData)
      if (!update_info?.error_code) {
        emailValid.value = true
        notify(
          {
            group: 'successmessage',
            title: 'Success',
            text: `Settings updated successfully`
          },
          2000
        )
      }
      isLoading.value = false
    }
    const getCustomerInfo = async () => {
      const customer_info = await getCustomerInfoFn()
      firstName.value = customer_info?.first_name
      lastName.value = customer_info?.last_name
      emailId.value = customer_info?.email
      phoneNumber.value = customer_info?.phone_number
      addressLine1.value = customer_info?.address?.address_line1
      addressLine2.value = customer_info?.address?.address_line2
      postCode.value = customer_info?.address?.post_code
      city.value = customer_info?.address?.city
    }

    onMounted(() => {
      getCustomerInfo()
    })
    watch(
      [firstName, lastName, emailId, phoneNumber, addressLine1, addressLine2, postCode, city],
      () => {
        emailValid.value = false
      }
    )
    return {
      firstName,
      lastName,
      emailId,
      phoneNumber,
      addressLine1,
      addressLine2,
      postCode,
      city,
      submitted,
      emailDuplicate,
      emailInvalid,
      emailValid,
      v$,
      router,
      restoStore,
      handleUpdateCustomer,
      isLoading
    }
  }
}
</script>
<template>
  <AppLayout>
    <div class="space-y-8 sm:px-6 px-3 sm:py-6 py-4 bg-white dark:bg-gray-800 shadow-md rounded-lg transition-colors duration-200">
      <div class="md:grid md:grid-cols-3 md:gap-8">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-xl font-bold leading-6 text-gray-900 dark:text-gray-100 transition-colors duration-200">Profile</h3>
            <p class="mt-2 text-sm text-gray-600 dark:text-gray-400 transition-colors duration-200">
              Update your personal information here.
            </p>
          </div>
        </div>
        <div class="mt-5 md:col-span-2 md:mt-0">
          <form @submit.prevent="handleUpdateCustomer">
            <div class="overflow-hidden shadow-sm rounded-lg">
              <div class="bg-white dark:bg-gray-800 px-6 py-6 transition-colors duration-200">
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 sm:col-span-3">
                    <label for="first-name" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2 transition-colors duration-200"
                      >First name</label
                    >
                    <div class="relative">
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <UserIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                      </div>
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        v-model="firstName"
                        :class="{ 'border-red-600 dark:border-red-500 focus:border-red-600 focus:ring-red-200 dark:focus:ring-red-800/20': submitted && v$.firstName.$error }"
                        autocomplete="given-name"
                        class="block w-full appearance-none rounded-lg border border-gray-300 dark:border-gray-700 shadow-sm focus:border-violet-500 focus:ring focus:ring-violet-200 dark:focus:ring-violet-800/20 focus:ring-opacity-50 pl-10 px-4 py-3 placeholder-gray-400 dark:placeholder-gray-500 focus:outline-none text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 transition-all duration-200"
                      />
                    </div>
                    <div
                      v-for="(error, index) of v$.firstName.$errors"
                      :key="index"
                      class="mt-1"
                    >
                      <div class="text-sm font-medium text-red-600 dark:text-red-400 transition-colors duration-200">
                        {{ error.$message }}
                      </div>
                    </div>
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label for="last-name" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2 transition-colors duration-200"
                      >Last name</label
                    >
                    <div class="relative">
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <UserIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                      </div>
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        v-model="lastName"
                        :class="{ 'border-red-600 dark:border-red-500 focus:border-red-600 focus:ring-red-200 dark:focus:ring-red-800/20': submitted && v$.lastName.$error }"
                        autocomplete="family-name"
                        class="block w-full appearance-none rounded-lg border border-gray-300 dark:border-gray-700 shadow-sm focus:border-violet-500 focus:ring focus:ring-violet-200 dark:focus:ring-violet-800/20 focus:ring-opacity-50 pl-10 px-4 py-3 placeholder-gray-400 dark:placeholder-gray-500 focus:outline-none text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 transition-all duration-200"
                      />
                    </div>
                    <div
                      v-for="(error, index) of v$.lastName.$errors"
                      :key="index"
                      class="mt-1"
                    >
                      <div class="text-sm font-medium text-red-600 dark:text-red-400 transition-colors duration-200">
                        {{ error.$message }}
                      </div>
                    </div>
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label for="email" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2 transition-colors duration-200"
                      >Email</label
                    >
                    <div class="relative">
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <EnvelopeIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                      </div>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        autocomplete="email"
                        v-model="emailId"
                        disabled
                        :class="{ 'border-red-600 dark:border-red-500 focus:border-red-600 focus:ring-red-200 dark:focus:ring-red-800/20': submitted && v$.emailId.$error }"
                        class="block w-full appearance-none rounded-lg border border-gray-300 dark:border-gray-700 shadow-sm focus:border-violet-500 focus:ring focus:ring-violet-200 dark:focus:ring-violet-800/20 focus:ring-opacity-50 pl-10 px-4 py-3 placeholder-gray-400 dark:placeholder-gray-500 focus:outline-none text-sm bg-gray-100 dark:bg-gray-600 text-gray-900 dark:text-gray-200 transition-all duration-200"
                      />
                    </div>
                    <div
                      v-for="(error, index) of v$.emailId.$errors"
                      :key="index"
                      class="mt-1"
                    >
                      <div class="text-sm font-medium text-red-600 dark:text-red-400 transition-colors duration-200">
                        {{ error.$message }}
                      </div>
                    </div>
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label for="phone-number" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2 transition-colors duration-200"
                      >Phone</label
                    >
                    <div class="relative">
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <PhoneIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                      </div>
                      <input
                        type="text"
                        name="phone-number"
                        id="phone-number"
                        autocomplete="phone-number"
                        v-model="phoneNumber"
                        :class="{ 'border-red-600 dark:border-red-500 focus:border-red-600 focus:ring-red-200 dark:focus:ring-red-800/20': submitted && v$.phoneNumber.$error }"
                        class="block w-full appearance-none rounded-lg border border-gray-300 dark:border-gray-700 shadow-sm focus:border-violet-500 focus:ring focus:ring-violet-200 dark:focus:ring-violet-800/20 focus:ring-opacity-50 pl-10 px-4 py-3 placeholder-gray-400 dark:placeholder-gray-500 focus:outline-none text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 transition-all duration-200"
                      />
                    </div>
                    <div
                      v-for="(error, index) of v$.phoneNumber.$errors"
                      :key="index"
                      class="mt-1"
                    >
                      <div class="text-sm font-medium text-red-600 dark:text-red-400 transition-colors duration-200">
                        {{ error.$message }}
                      </div>
                    </div>
                  </div>

                  <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label for="address-line1" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2 transition-colors duration-200"
                      >Address Line 1</label
                    >
                    <div class="relative">
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <HomeIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                      </div>
                      <input
                        type="text"
                        name="address-line1"
                        id="address-line1"
                        autocomplete="address-line1"
                        v-model="addressLine1"
                        :class="{ 'border-red-600 dark:border-red-500 focus:border-red-600 focus:ring-red-200 dark:focus:ring-red-800/20': submitted && v$.addressLine1.$error }"
                        class="block w-full appearance-none rounded-lg border border-gray-300 dark:border-gray-700 shadow-sm focus:border-violet-500 focus:ring focus:ring-violet-200 dark:focus:ring-violet-800/20 focus:ring-opacity-50 pl-10 px-4 py-3 placeholder-gray-400 dark:placeholder-gray-500 focus:outline-none text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 transition-all duration-200"
                      />
                    </div>
                    <div
                      v-for="(error, index) of v$.addressLine1.$errors"
                      :key="index"
                      class="mt-1"
                    >
                      <div class="text-sm font-medium text-red-600 dark:text-red-400 transition-colors duration-200">
                        {{ error.$message }}
                      </div>
                    </div>
                  </div>

                  <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label for="address-line2" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2 transition-colors duration-200"
                      >Address Line 2</label
                    >
                    <div class="relative">
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <HomeIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                      </div>
                      <input
                        type="text"
                        name="address-line2"
                        id="address-line2"
                        autocomplete="address-line2"
                        v-model="addressLine2"
                        class="block w-full appearance-none rounded-lg border border-gray-300 dark:border-gray-700 shadow-sm focus:border-violet-500 focus:ring focus:ring-violet-200 dark:focus:ring-violet-800/20 focus:ring-opacity-50 pl-10 px-4 py-3 placeholder-gray-400 dark:placeholder-gray-500 focus:outline-none text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 transition-all duration-200"
                      />
                    </div>
                  </div>

                  <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label for="postcode" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2 transition-colors duration-200"
                      >Postal code</label
                    >
                    <div class="relative">
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <MapPinIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                      </div>
                      <input
                        type="text"
                        name="postcode"
                        id="postcode"
                        autocomplete="postcode"
                        v-model="postCode"
                        :class="{ 'border-red-600 dark:border-red-500 focus:border-red-600 focus:ring-red-200 dark:focus:ring-red-800/20': submitted && v$.postCode.$error }"
                        class="block w-full appearance-none rounded-lg border border-gray-300 dark:border-gray-700 shadow-sm focus:border-violet-500 focus:ring focus:ring-violet-200 dark:focus:ring-violet-800/20 focus:ring-opacity-50 pl-10 px-4 py-3 placeholder-gray-400 dark:placeholder-gray-500 focus:outline-none text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 transition-all duration-200"
                      />
                    </div>
                    <div
                      v-for="(error, index) of v$.postCode.$errors"
                      :key="index"
                      class="mt-1"
                    >
                      <div class="text-sm font-medium text-red-600 dark:text-red-400 transition-colors duration-200">
                        {{ error.$message }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-gray-50 dark:bg-gray-700 px-6 py-4 text-center transition-colors duration-200">
                <button
                  type="submit"
                  class="inline-flex justify-center rounded-lg border border-transparent bg-violet-700 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-800 dark:hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 transition-all duration-200 transform hover:-translate-y-0.5"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="md:grid md:grid-cols-3 md:gap-8">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-xl font-bold leading-6 text-gray-900 dark:text-gray-100 transition-colors duration-200">Account security</h3>
            <p class="mt-2 text-sm text-gray-600 dark:text-gray-400 transition-colors duration-200">
              Manage your account security settings
            </p>
          </div>
        </div>
        <div class="mt-5 md:col-span-2 md:mt-0">
          <div class="overflow-hidden shadow-sm rounded-lg">
            <div class="bg-white dark:bg-gray-800 px-6 py-6 transition-colors duration-200">
              <div class="space-y-5">
                <div class="border border-gray-300 dark:border-gray-700 rounded-lg p-5 hover:border-gray-400 dark:hover:border-gray-600 hover:shadow-md transition-all duration-200">
                  <div class="flex justify-between items-center">
                    <div class="flex flex-col">
                      <h3 class="text-md font-semibold text-gray-900 dark:text-gray-100 transition-colors duration-200">Password</h3>
                      <p class="text-sm text-gray-500 dark:text-gray-400 transition-colors duration-200">
                        Update your password for better security
                      </p>
                    </div>
                    <div>
                      <router-link
                        :to="{ name: 'updatePassword' }"
                        class="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-base font-medium text-white bg-violet-700 hover:bg-violet-800 dark:hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 transition-all duration-200 transform hover:-translate-y-0.5"
                      >
                        Change
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="border border-gray-300 dark:border-gray-700 rounded-lg p-5 hover:border-gray-400 dark:hover:border-gray-600 hover:shadow-md transition-all duration-200">
                  <div class="flex justify-between items-center">
                    <div class="flex flex-col">
                      <h3 class="text-md font-semibold text-gray-900 dark:text-gray-100 transition-colors duration-200">Logout</h3>
                      <p class="text-sm text-gray-500 dark:text-gray-400 transition-colors duration-200">Sign out of your account</p>
                    </div>
                    <div>
                      <router-link
                        :to="{ name: 'logout' }"
                        class="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-base font-medium text-white bg-violet-700 hover:bg-violet-800 dark:hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 transition-all duration-200 transform hover:-translate-y-0.5"
                      >
                        Logout
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
  <LoadingSpinner :isLoading="isLoading" />
</template>
