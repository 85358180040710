<script setup>
import { useCartGLobalStore } from '@/stores/cartGlobalStore'
const cartStore = useCartGLobalStore()
</script>
<template>
  <div
    v-if="cartStore?.RESTO_STATUS?.is_open && cartStore?.RESTO_STATUS?.resto_is_offline != null"
    class="p-2 mb-1 text-center text-lg font-semibold bg-red-500 dark:bg-indigo-700 text-white rounded-md shadow-sm transition-colors duration-200"
  >
    {{ cartStore?.RESTO_STATUS?.resto_is_offline_message }}
  </div>
</template>
