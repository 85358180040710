<script setup>
import { onMounted, inject } from 'vue'
import AppLayout from '@/layouts/AppLayout.vue'
import ShoppingCart from '@/components/cart/ShoppingCart.vue'
import GiftVoucherList from '@/components/giftVoucher/GiftVoucherList.vue'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useCartGLobalStore } from '@/stores/cartGlobalStore'
import { useRouter } from 'vue-router'

const restoStore = useRestoGLobalStore()
const cartStore = useCartGLobalStore()
const router = useRouter()

const IS_MOBILE_APP = inject('IS_MOBILE_APP')
const isGiftVoucherActive = restoStore?.restoInfo?.is_gift_voucher_active
if (!isGiftVoucherActive) {
  router.push('/')
}

onMounted(() => {
  cartStore.fetchCartInfo()  
})
</script>

<template>
  <AppLayout>
    <div class="sm:px-4 lg:px-5 px-3 sm:py-3 lg:py-4 py-3 dark:bg-background-dark transition-colors duration-200">
      <div class="econtainer mx-auto">
        <div class="relative lg:gap-4 gap-3 md:grid md:grid-cols-12">
          <div class="md:col-span-8">
            <GiftVoucherList></GiftVoucherList>
          </div>
          <div class="md:col-span-4">
            <ShoppingCart></ShoppingCart>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:footer v-if="IS_MOBILE_APP">&nbsp;</template>
  </AppLayout>
</template>
