<script>
import AppLayout from '@/layouts/AppLayout.vue'
import SettingMenuLink from '@/views/account/setting/common/SettingMenuLink.vue'
import { ref, computed, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, email, minLength } from '@vuelidate/validators'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { updateEmailFn, isCustomerEmailAvailableFn } from '@/services/api/restApiCalls'
import { CheckCircleIcon, XCircleIcon, EnvelopeIcon, LockClosedIcon } from '@heroicons/vue/20/solid'
import LoadingSpinner from '@/components/common/LoadingSpinner/LoadingSpinner.vue'

export default {
  components: {
    AppLayout,
    CheckCircleIcon,
    XCircleIcon,
    EnvelopeIcon,
    LockClosedIcon,
    SettingMenuLink,
    LoadingSpinner
  },
  setup() {
    const restoStore = useRestoGLobalStore()
    const router = useRouter()
    const emailInvalid = ref(false)
    const emailValid = ref(false)
    const submitted = ref(false)
    const updateEmailData = ref({})
    const isLoading = ref(false)
    const errorMsg = ref('')
    
    const userInitialValues = {
      emailId: '',
      newEmail: '',
      password: ''
    }
    let user = reactive({ ...userInitialValues })

    const checkIfEmailAvailable = async (value) => {
      let formData = new FormData()
      formData.append('email', value)
      const response = await isCustomerEmailAvailableFn(formData)
      const isEmailAvailable = response.is_email_available || false
      return isEmailAvailable
    }
    
    const validationRules = computed(() => {
      let rules = {
        emailId: { required, email },
        newEmail: {
          required,
          email,
          $lazy: true,
          isUnique: helpers.withAsync(
            helpers.withMessage('Email not available', checkIfEmailAvailable)
          )
        },
        password: {
          required,
          minLength: minLength(5)
        }
      }
      return rules
    })
    
    const v$ = useVuelidate(validationRules, user)
    
    const handleUpdateEmail = async () => {
      submitted.value = true
      v$.value.$validate()
      if (v$.value.$invalid) {
        return
      }
      
      if (isLoading.value) return
      isLoading.value = true
      
      try {
        let updateEmailFormData = new FormData()
        updateEmailFormData.append('email', user.newEmail)
        updateEmailFormData.append('password', user.password)
        
        const update_email = await updateEmailFn(updateEmailFormData)
        updateEmailData.value = update_email
        
        if (update_email?.status === 200) {
          emailValid.value = true
          emailInvalid.value = false
        } else {
          emailInvalid.value = true
          errorMsg.value = update_email?.status_msg || 'Failed to update email'
        }
      } catch (error) {
        emailInvalid.value = true
        errorMsg.value = 'An error occurred while updating email'
        console.error(error)
      } finally {
        isLoading.value = false
        submitted.value = false
      }
    }
    
    return {
      user,
      emailInvalid,
      emailValid,
      submitted,
      v$,
      router,
      restoStore,
      updateEmailData,
      handleUpdateEmail,
      errorMsg,
      isLoading
    }
  }
}
</script>
<template>
  <AppLayout>
    <div
      class="space-y-8 sm:px-6 px-3 sm:py-6 py-4 bg-white dark:bg-gray-800 shadow-md rounded-lg transition-colors duration-200"
    >
      <div class="flex items-center justify-between mb-6 px-4 sm:px-0">
        <router-link
          :to="{ name: 'setting' }"
          class="inline-flex items-center rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 py-2 px-4 text-sm font-medium text-gray-700 dark:text-gray-300 shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-all duration-200"
        >
          ← Back to Settings
        </router-link>
      </div>
      
      <div class="md:grid md:grid-cols-3 md:gap-8">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-xl font-bold leading-6 text-gray-900 dark:text-gray-100 transition-colors duration-200">Update Email</h3>
            <p class="mt-2 text-sm text-gray-600 dark:text-gray-400 transition-colors duration-200">
              Change your email address for this account.
            </p>
          </div>
        </div>
        <div class="mt-5 md:col-span-2 md:mt-0">
          <form @submit.prevent="handleUpdateEmail">
            <div class="overflow-hidden shadow-sm rounded-lg">
              <div class="bg-white dark:bg-gray-800 px-6 py-6 transition-colors duration-200">
                <div class="space-y-6">
                  <div v-if="updateEmailData?.status == 400 || emailInvalid" class="rounded-md bg-red-50 dark:bg-red-900/20 p-4">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                      </div>
                      <div class="ml-3">
                        <h3 class="text-sm font-medium text-red-800 dark:text-red-300">
                          {{ updateEmailData?.status_msg || errorMsg }}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div v-if="updateEmailData?.status == 200 || emailValid" class="rounded-md bg-green-50 dark:bg-green-900/20 p-4">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
                      </div>
                      <div class="ml-3">
                        <h3 class="text-sm font-medium text-green-800 dark:text-green-300">Email Updated successfully</h3>
                      </div>
                    </div>
                  </div>

                  <div>
                    <label for="current-email" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2 transition-colors duration-200"
                      >Current Email</label
                    >
                    <div class="relative">
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <EnvelopeIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                      </div>
                      <input
                        type="email"
                        name="current-email"
                        id="current-email"
                        autocomplete="email"
                        v-model="user.emailId"
                        disabled
                        class="block w-full appearance-none rounded-lg border border-gray-300 dark:border-gray-700 shadow-sm focus:border-violet-500 focus:ring focus:ring-violet-200 dark:focus:ring-violet-800/20 focus:ring-opacity-50 pl-10 px-4 py-3 placeholder-gray-400 dark:placeholder-gray-500 focus:outline-none text-sm bg-gray-100 dark:bg-gray-600 text-gray-900 dark:text-gray-200 transition-all duration-200"
                      />
                    </div>
                  </div>

                  <div>
                    <label for="new-email" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2 transition-colors duration-200"
                      >New Email</label
                    >
                    <div class="relative">
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <EnvelopeIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                      </div>
                      <input
                        type="email"
                        name="email"
                        id="new-email"
                        autocomplete="email"
                        v-model="user.newEmail"
                        :class="{ 'border-red-600 dark:border-red-500 focus:border-red-600 focus:ring-red-200 dark:focus:ring-red-800/20': submitted && v$.newEmail.$error }"
                        class="block w-full appearance-none rounded-lg border border-gray-300 dark:border-gray-700 shadow-sm focus:border-violet-500 focus:ring focus:ring-violet-200 dark:focus:ring-violet-800/20 focus:ring-opacity-50 pl-10 px-4 py-3 placeholder-gray-400 dark:placeholder-gray-500 focus:outline-none text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 transition-all duration-200"
                      />
                    </div>
                    <div
                      v-for="(error, index) of v$.newEmail.$errors"
                      :key="index"
                      class="mt-1"
                    >
                      <div class="text-sm font-medium text-red-600 dark:text-red-400 transition-colors duration-200">
                        {{ error.$message }}
                      </div>
                    </div>
                  </div>

                  <div>
                    <label for="password" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2 transition-colors duration-200"
                      >Password</label
                    >
                    <div class="relative">
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <LockClosedIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                      </div>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        autocomplete="current-password"
                        v-model="user.password"
                        :class="{ 'border-red-600 dark:border-red-500 focus:border-red-600 focus:ring-red-200 dark:focus:ring-red-800/20': submitted && v$.password.$error }"
                        class="block w-full appearance-none rounded-lg border border-gray-300 dark:border-gray-700 shadow-sm focus:border-violet-500 focus:ring focus:ring-violet-200 dark:focus:ring-violet-800/20 focus:ring-opacity-50 pl-10 px-4 py-3 placeholder-gray-400 dark:placeholder-gray-500 focus:outline-none text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 transition-all duration-200"
                      />
                    </div>
                    <div
                      v-for="(error, index) of v$.password.$errors"
                      :key="index"
                      class="mt-1"
                    >
                      <div class="text-sm font-medium text-red-600 dark:text-red-400 transition-colors duration-200">
                        {{ error.$message }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-gray-50 dark:bg-gray-700 px-6 py-4 text-center transition-colors duration-200">
                <button
                  type="submit"
                  class="inline-flex justify-center rounded-lg border border-transparent bg-violet-700 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-800 dark:hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 transition-all duration-200 transform hover:-translate-y-0.5 mr-3"
                >
                  Save
                </button>

                <router-link
                  :to="{ name: 'customerSetting' }"
                  class="inline-flex justify-center rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 py-3 px-6 text-sm font-medium text-gray-700 dark:text-gray-300 shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-all duration-200"
                >
                  Cancel
                </router-link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </AppLayout>
  <LoadingSpinner :isLoading="isLoading" />
</template>
