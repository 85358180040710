<script setup>
import { useRestoGLobalStore } from '@/stores/restoGlobalStore';
import playstoreImgLight from '@/assets/images/playstore-img.png';
import appstoreImgLight from '@/assets/images/appstore-img.png';
import { computed } from 'vue';

const restoStore = useRestoGLobalStore();

// Generate app store links from store data
const playStoreLink = computed(() => {
  const appId = restoStore?.restoInfo?.mobileapp_ids?.playstore_app_id;
  return appId ? `https://play.google.com/store/apps/details?id=${appId}` : null;
});

const appStoreLink = computed(() => {
  const appId = restoStore?.restoInfo?.mobileapp_ids?.itunes_app_id;
  return appId ? `https://apps.apple.com/app/${appId}` : null;
});

// We'll use the same images for both dark and light mode but apply different styling
const playstoreImg = playstoreImgLight;
const appstoreImg = appstoreImgLight;

// Check if we should show the promotion based on available links
const showPromotion = computed(() => {
  return playStoreLink.value || appStoreLink.value;
});
</script>

<template>
  <div v-if="showPromotion" class="mt-3 pt-3 border-t border-gray-200 dark:border-gray-700 transition-colors duration-200">
    <div class="text-center">
      <p class="text-gray-900 dark:text-gray-100 mb-2 text-xs transition-colors duration-200">
        Get the mobile apps for special discount offers & better experience
      </p>

      <div class="flex justify-center gap-3">
        <a
          v-if="playStoreLink"
          :href="playStoreLink"
          target="_blank"
          rel="noopener noreferrer"
          class="flex items-center transition"
        >
          <img
            :src="playstoreImg"
            alt="Play Store"
            class="md:h-9 h-8 bg-gray-100 hover:bg-white dark:bg-gray-400 dark:hover:bg-gray-600 rounded-md shadow-md transition-colors duration-200"
          />
        </a>

        <a
          v-if="appStoreLink"
          :href="appStoreLink"
          target="_blank"
          rel="noopener noreferrer"
          class="flex items-center transition"
        >
          <img
            :src="appstoreImg"
            alt="App Store"
            class="md:h-9 h-8 bg-gray-100 hover:bg-white dark:bg-gray-400 dark:hover:bg-gray-600 rounded-md shadow-md transition-colors duration-200"
          />
        </a>
      </div>
    </div>
  </div>
</template> 