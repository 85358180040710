<script setup>
import { ref, onBeforeMount } from 'vue'
import AppLayout from '@/layouts/AppLayout.vue'
import OrderItems from '@/views/payment/common/OrderItems.vue'
import DeliveryOrder from '@/views/payment/common/DeliveryOrder.vue'
import CollectionOrder from '@/views/payment/common/CollectionOrder.vue'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useCartGLobalStore } from '@/stores/cartGlobalStore'
import { getCartInfoFn } from '@/services/api/restApiCalls'

const cartStore = useCartGLobalStore()
const restoStore = useRestoGLobalStore()
const dataLoaded = ref(false)

onBeforeMount(async () => {
  const cart_info = await getCartInfoFn(restoStore?.RESTO_ID)
  cartStore.CART_DATA = cart_info[restoStore?.RESTO_ID]
  restoStore.CUSTOMER_INFO = cart_info.customer_info
  dataLoaded.value = true
})
</script>
<template>
  <AppLayout>
    <div class="container mx-auto md:pt-12 pt-8 pb-5 dark:bg-background-dark">
      <div class="sm:flex sm:items-center mb-2">
        <div class="sm:flex-auto text-center">
          <h1 class="md:text-2xl text-xl font-semibold text-gray-900 dark:text-gray-100 mb-3">
            Placing
            <span
              class="capitalize font-bold italic"
              :class="[
                cartStore?.CART_DATA?.order_type == 'delivery'
                  ? 'text-color-delivery dark:text-color-delivery-dark'
                  : 'text-color-collection dark:text-color-collection-dark'
              ]"
              >{{ cartStore?.CART_DATA?.order_type }}</span
            >
            order of
            <span
              class="capitalize font-bold italic"
              :class="[
                cartStore?.CART_DATA?.order_type == 'delivery'
                  ? 'text-color-delivery dark:text-color-delivery-dark'
                  : 'text-color-collection dark:text-color-collection-dark'
              ]"
              >£{{ cartStore?.CART_DATA?.order_total }}</span
            >
          </h1>
        </div>
      </div>
      <div class="mt-2">
        <div class="md:px-6 sm:px-4 px-2 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-0">
          <div
            class="lg:col-span-6 lg:mt-0 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-sm sm:p-6 p-4 md:mb-0 mb-4"
          >
            <div class="space-y-4">
              <template v-if="cartStore.CART_DATA?.order_type == 'delivery'">
                <DeliveryOrder v-if="dataLoaded"></DeliveryOrder>
              </template>
              <template v-if="cartStore.CART_DATA?.order_type == 'collection'">
                <CollectionOrder v-if="dataLoaded"></CollectionOrder>
              </template>
            </div>
          </div>
          <OrderItems></OrderItems>
        </div>
      </div>
    </div>
  </AppLayout>
</template>
