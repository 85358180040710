<script setup>
import { defineProps } from 'vue'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useCartGLobalStore } from '@/stores/cartGlobalStore'
import { addSimpleItemFn } from '@/services/api/restApiCalls'
import { notify } from 'notiwind'

const restoStore = useRestoGLobalStore()
const cartStore = useCartGLobalStore()
const props = defineProps(['simpleItemData', 'productInfo', 'itemCount'])
const handleAddSimpleItem = async (simpleItem) => {
  if (cartStore.CART_DATA?.order_type == 'delivery' && !cartStore?.CART_DATA?.pc_data?.is_valid) {
    cartStore.SHOW_POSTCODE_MODAL = true
  } else {
    const simpleItemData = {
      action: 'add',
      resto_id: restoStore?.RESTO_ID,
      item_id: simpleItem.item_id
    }
    let simpleItemFormData = new FormData()
    simpleItemFormData.append('cart_data', JSON.stringify(simpleItemData))
    const cart_info = await addSimpleItemFn(simpleItemFormData)
    cartStore.CART_DATA = cart_info[restoStore?.RESTO_ID]
    cartStore.CAN_DO_CHECKOUT = cart_info?.can_do_checkout
    restoStore.hideModal()
    notify(
      {
        group: 'success',
        title: 'Added',
        text: `${simpleItem?.item_name} added to cart`
      },
      2000
    )
  }
}
</script>

<template>
  <div
    v-if="simpleItemData?.menu_img_url"
    class="flex items-center rounded-lg border border-gray-300 dark:border-gray-700 p-2 cursor-pointer hover:border-gray-400 dark:hover:border-gray-500 bg-white dark:bg-surface-dark transition-colors duration-200"
    @click="handleAddSimpleItem(simpleItemData)"
  >
    <div class="min-w-0 flex-1">
      <h3 as="h3" class="text-md font-medium leading-6 text-gray-900 dark:text-text-dark">
        {{ simpleItemData?.item_name_full }}
      </h3>
      <div v-if="itemCount == 1 && productInfo">
        <p class="line-clamp-2 text-sm text-gray-500 dark:text-gray-400 mb-0">{{ productInfo }}</p>
      </div>
      <div class="mt-1 text-base text-gray-900 dark:text-text-dark">
        <span> £{{ simpleItemData?.price }} </span>
      </div>
    </div>
    <div v-if="simpleItemData?.menu_img_url" class="flex-shrink-0 bg-gray-500 dark:bg-gray-600">
      <div
        class="h-14 w-14"
        :style="{
          backgroundImage: 'url(' + simpleItemData?.menu_img_url + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }"
      ></div>
    </div>
  </div>
  <div
    v-else
    class="rounded-lg border border-gray-300 dark:border-gray-700 p-2 cursor-pointer hover:border-gray-400 dark:hover:border-gray-500 bg-white dark:bg-surface-dark transition-colors duration-200"
    @click="handleAddSimpleItem(simpleItemData)"
  >
    <div class="flex justify-between items-center">
      <h3 as="h3" class="text-md font-medium leading-6 text-gray-900 dark:text-text-dark">
        {{ simpleItemData?.item_name_full }}
      </h3>
      <div class="ml-3 text-gray-900 dark:text-text-dark">
        <span> £{{ simpleItemData?.price }} </span>
      </div>
    </div>
    <div v-if="itemCount == 1 && productInfo">
      <p class="line-clamp-2 text-sm text-gray-500 dark:text-gray-400 mb-0">{{ productInfo }}</p>
    </div>
  </div>
</template>
