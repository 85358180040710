<script setup>
import { onMounted, onBeforeUnmount, ref, onBeforeMount, inject, watch } from 'vue'
import { useRoute } from 'vue-router'
import WOW from 'wow.js'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import Contact from '@/components/contact/Contact.vue'
import SmartBanner from '@/components/smartBanner/SmartBanner.vue'
import { XMarkIcon } from '@heroicons/vue/20/solid'
import { MoonIcon, SunIcon } from '@heroicons/vue/24/outline'

const route = useRoute()
const IS_MOBILE_APP = inject('IS_MOBILE_APP')
const restoStore = useRestoGLobalStore()
const dynamicContent = restoStore?.restoInfo?.dynamic_site_content
const address = `${restoStore?.restoInfo?.address}`
const name = `${restoStore?.restoInfo?.name}`
const query = `${name} ${address}`
const mapSrc = `https://maps.google.com/maps?q=` + encodeURI(query) + `&output=embed`

const date = new Date()
const currentYear = date.getFullYear()
const isSticky = ref(false)
const showMobileHeader = ref(false)
const bgColor1 = ref('')
const menuIconColourClass = ref('text-slate-900 dark:text-gray-100')

// Theme handling
const isDarkMode = ref(false)

// Check if user has a theme preference in localStorage or prefers dark mode
const initializeTheme = () => {
  // Try to get theme from localStorage using the new JSON structure
  const storedTheme = localStorage.getItem('theme')
  let themePreference = null
  
  if (storedTheme) {
    try {
      const themeObj = JSON.parse(storedTheme)
      themePreference = themeObj.theme
    } catch (e) {
      // Handle parsing error - fall back to light mode as default
      console.error('Error parsing theme from localStorage')
      themePreference = 'light'
    }
  } else {
    // Check system preference if no stored preference
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches
    themePreference = prefersDark ? 'dark' : 'light'
  }
  
  // Apply theme based on preference
  if (themePreference === 'dark') {
    isDarkMode.value = true
    document.documentElement.classList.add('dark')
  } else {
    isDarkMode.value = false
    document.documentElement.classList.remove('dark')
  }
  
  // Always save the current theme preference
  if (!storedTheme || themePreference !== JSON.parse(storedTheme)?.theme) {
    localStorage.setItem('theme', JSON.stringify({ theme: themePreference }))
  }
}

// Toggle between light and dark mode
const toggleDarkMode = () => {
  isDarkMode.value = !isDarkMode.value
  
  // Update localStorage with JSON structure and document classes
  if (isDarkMode.value) {
    document.documentElement.classList.add('dark')
    localStorage.setItem('theme', JSON.stringify({ theme: 'dark' }))
  } else {
    document.documentElement.classList.remove('dark')
    localStorage.setItem('theme', JSON.stringify({ theme: 'light' }))
  }
}

// Watch for route changes to re-sync theme
watch(() => window.location.href, () => {
  initializeTheme()
}, { immediate: true })

const handleScroll = () => {
  isSticky.value = window.scrollY > 50
}

const handleShowHeader = () => {
  showMobileHeader.value = !showMobileHeader.value
}

const handleNavLinkClick = () => {
  showMobileHeader.value = false
}

onBeforeMount(() => {
  initializeTheme()
  
  if (dynamicContent?.style == 1) {
    import('@/layouts/dynamicSite/template1/css/style1.css')
    bgColor1.value = '#991b1b'
  } else if (dynamicContent?.style == 2) {
    import('@/layouts/dynamicSite/template1/css/style2.css')
    bgColor1.value = '#fbaf32'
  } else if (dynamicContent?.style == 3) {
    import('@/layouts/dynamicSite/template1/css/style3.css')
    bgColor1.value = '#49a003'
  } else {
    import('@/layouts/dynamicSite/template1/css/style4.css')
    bgColor1.value = '#cda45e'
  }
})

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
  new WOW().init()
  
  // Ensure theme is always correctly applied on mount
  requestAnimationFrame(() => {
    initializeTheme()
  })
  
  const favicon = document.querySelector('link[rel="icon"]')
  if (favicon) {
    favicon.href = `${dynamicContent?.info?.logo}`
  } else {
    const newFavicon = document.createElement('link')
    newFavicon.rel = 'icon'
    newFavicon.type = 'image/x-icon'
    newFavicon.href = `${dynamicContent?.info?.logo}`
    document.head.appendChild(newFavicon)
  }
  
  // Listen for system theme changes
  const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
  mediaQuery.addEventListener('change', initializeTheme)
  
  // Add storage event listener to sync theme across tabs
  window.addEventListener('storage', (event) => {
    if (event.key === 'theme') {
      initializeTheme()
    }
  })
})

// Watch for route changes to ensure theme stays consistent
watch(() => route.fullPath, () => {
  requestAnimationFrame(() => {
    initializeTheme()
  })
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
  
  // Remove event listener for theme changes
  const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
  mediaQuery.removeEventListener('change', initializeTheme)
  
  // Remove storage event listener
  window.removeEventListener('storage', (event) => {
    if (event.key === 'theme') {
      initializeTheme()
    }
  })
})
</script>
<template>
  <div class="temp-w1 transition-colors duration-300 bg-white dark:bg-gray-900">
    <!-- SmartBanner -->
    <SmartBanner v-if="!IS_MOBILE_APP"></SmartBanner>
    <!-- Header -->
    <header
      :class="{ 'sticky-header': isSticky }"
      class="bg-white dark:bg-gray-800 text-gray-800 dark:text-white sticky top-0 z-50 relative pt-safe transition-colors duration-300 shadow-md"
    >
      <div class="container mx-auto flex items-center justify-between py-4 px-2">
        <!-- Logo -->
        <div class="flex items-center">
          <img :src="dynamicContent?.info?.logo" alt="Logo" class="h-12 w-12 mr-2" />
        </div>

        <!-- Navigation Links -->
        <nav class="hidden md:flex items-center space-x-8">
          <a href="#home" class="uppercase text-gray-800 hover:text-gray-900 transition-colors duration-200 dark:text-gray-300 dark:hover:text-white">Home</a>
          <a href="#aboutUs" class="uppercase text-gray-800 hover:text-gray-900 transition-colors duration-200 dark:text-gray-300 dark:hover:text-white">About Us</a>
          <router-link
            class="uppercase text-gray-800 hover:text-gray-900 transition-colors duration-200 dark:text-gray-300 dark:hover:text-white"
            :to="{ name: 'menu', params: { menu_slug: restoStore?.MENU_URL } }"
            >Online Order</router-link
          >
          <a href="#gallery" class="uppercase text-gray-800 hover:text-gray-900 transition-colors duration-200 dark:text-gray-300 dark:hover:text-white">Gallery</a>
          <a href="#contact" class="uppercase text-gray-800 hover:text-gray-900 transition-colors duration-200 dark:text-gray-300 dark:hover:text-white">Contact</a>
          <router-link
            v-if="dynamicContent?.info?.is_reservation_active"
            class="btn mr-2 px-5 py-1 rounded-sm text-white md:text-xl text-base transition-colors duration-200"
            :to="{ name: 'reservation', params: { resto_id: restoStore?.RESTO_ID } }"
            >Book Table</router-link
          >
        </nav>
        
        <!-- Theme Toggle Button -->
        <button 
          @click="toggleDarkMode" 
          class="hidden md:flex items-center justify-center p-2 rounded-full bg-gray-200 dark:bg-gray-700 transition-colors duration-300 hover:bg-gray-300 dark:hover:bg-gray-600 mr-3"
          aria-label="Toggle dark mode"
        >
          <SunIcon v-if="isDarkMode" class="h-5 w-5 text-yellow-400" />
          <MoonIcon v-else class="h-5 w-5 text-gray-700" />
        </button>

        <!-- Mobile Menu Button -->
        <div class="md:hidden flex items-center">
          <button 
            @click="toggleDarkMode" 
            class="p-2 rounded-full bg-gray-200 dark:bg-gray-700 transition-colors duration-300 hover:bg-gray-300 dark:hover:bg-gray-600 mr-3"
            aria-label="Toggle dark mode"
          >
            <SunIcon v-if="isDarkMode" class="h-5 w-5 text-yellow-400" />
            <MoonIcon v-else class="h-5 w-5 text-gray-700" />
          </button>
          
          <button class="focus:outline-none" @click="handleShowHeader()">
            <svg
              class="h-6 w-6"
              :class="menuIconColourClass"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              v-if="!showMobileHeader"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
            <XMarkIcon v-if="showMobileHeader" class="h-6 w-6 text-black dark:text-white transition-colors duration-200" aria-hidden="true" />
          </button>
        </div>

        <!-- Mobile Menu -->
        <div
          :class="[showMobileHeader ? 'block' : 'hidden']"
          class="mobile-menu bg-white dark:bg-gray-800 absolute left-0 right-0 top-20 pb-4 transition-colors duration-300 shadow-md border-t border-gray-200 dark:border-gray-700"
        >
          <a href="#home" class="block uppercase py-2 px-4 text-gray-800 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white transition-colors duration-200" @click="handleNavLinkClick">Home</a>
          <a href="#aboutUs" class="block uppercase py-2 px-4 text-gray-800 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white transition-colors duration-200" @click="handleNavLinkClick">About Us</a>
          <router-link
            class="block uppercase py-2 px-4 text-gray-800 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white transition-colors duration-200"
            :to="{ name: 'menu', params: { menu_slug: restoStore?.MENU_URL } }"
            >Online Order</router-link
          >
          <a href="#gallery" class="block uppercase py-2 px-4 text-gray-800 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white transition-colors duration-200" @click="handleNavLinkClick">Gallery</a>
          <a href="#contact" class="block uppercase py-2 px-4 text-gray-800 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white transition-colors duration-200" @click="handleNavLinkClick">Contact</a>
          <router-link
            v-if="dynamicContent?.info?.is_reservation_active"
            class="btn rounded-lg text-white text-sm uppercase py-2 px-4 mx-4 mt-3 transition-colors duration-200"
            :to="{ name: 'reservation', params: { resto_id: restoStore?.RESTO_ID } }"
            >Book Table</router-link
          >
        </div>
      </div>
    </header>
    <!-- Offer text -->
    <div
      v-if="dynamicContent?.content?.rsc_resto_note_customer_message"
      class="container-fluid top-notice pt-1 px-3 bg-gray-700 dark:bg-gray-800 border-b border-gray-600 dark:border-gray-700 shadow-sm transition-colors duration-300"
    >
      <div class="top-notice-bar">
        <div class="flex">
          <div class="flex-none w-1/6 md:w-1/12 lg:w-1/12 xl:w-1/12 text-white font-semibold">
            Notice!
          </div>
          <div class="flex-grow w-5/6 md:w-11/12 lg:w-11/12 xl:w-11/12 text-white">
            <marquee
              class="hover:cursor-pointer customer-message"
              onmouseover="this.stop();"
              onmouseout="this.start();"
            >
              {{ dynamicContent?.content?.rsc_resto_note_customer_message }}
            </marquee>
          </div>
        </div>
      </div>
    </div>
    <main class="px-safe dark:text-gray-200 transition-colors duration-300">
      <div id="home" class="carousel slide top_slider" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div
              class="slider-item slider_1 bg-cover bg-center h-screen"
              :style="{
                background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${dynamicContent?.content?.rsc_slider_dynamicsite_background_image_url})`
              }"
            ></div>
          </div>
        </div>

        <div class="container">
          <div class="flex justify-center items-center absolute top-20 bottom-0 mx-auto w-full">
            <div
              class="content-wrapper text-center font-sans text-white wow zoomIn general_grow animated"
            >
              <p class="subtitle md:text-2xl text-xl italic">Welcome to</p>
              <h1 class="mb-4 sm:text-6xl md:text-7xl text-4xl font-bold antialiased italic">
                {{ dynamicContent?.info?.name }}
              </h1>
              <div v-if="dynamicContent?.info?.active_website_offers">
                <h2
                  v-for="(offer, index) in dynamicContent?.info?.active_website_offers"
                  :key="index"
                  class="my-2 lg:mb-1 md:text-xl text-lg italic"
                >
                  {{ offer }}
                </h2>
              </div>
              <div class="btn-wrapper mt-6">
                <router-link
                  class="btn mr-4 px-5 py-2 rounded-sm text-white md:text-xl text-base transition-colors duration-200"
                  :to="{ name: 'menu', params: { menu_slug: restoStore?.MENU_URL } }"
                  >Order Now</router-link
                >
                <router-link
                  v-if="dynamicContent?.info?.is_reservation_active"
                  class="btn mr-2 px-5 py-2 rounded-sm text-white md:text-xl text-base transition-colors duration-200"
                  :to="{ name: 'reservation', params: { resto_id: restoStore?.RESTO_ID } }"
                  >Book Table</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- About us-1 Content -->
      <section
        v-if="dynamicContent?.content?.rsc_about_us_text_1"
        id="aboutUs"
        class="container-fluid dark:bg-gray-900 py-16 eabout-section transition-colors duration-300"
      >
        <div class="container mx-auto">
          <div class="relative md:mx-0 mx-3">
            <div
              class="text-gray-800 dark:text-gray-200 bg-white dark:bg-gray-800 lg:px-8 px-6 lg:py-8 py-6 content wow bounceInDown general_down animated shadow-md rounded-lg transition-colors duration-300"
            >
              <h2 class="md:text-4xl text-3xl font-bold antialiased italic mb-4 section-title !text-gray-900 !dark:text-white">
                About us
              </h2>
              <p
                class="lg:text-lg text-base !text-gray-700 !dark:text-gray-300"
                v-html="dynamicContent?.content?.rsc_about_us_text_1"
              ></p>
            </div>
          </div>
        </div>
      </section>


      <!-- Gallery section start here -->
      <section
        v-if="dynamicContent?.content?.rsc_gallery_image_1_link"
        id="gallery"
        class="container-fluid mx-auto md:py-20 py-12 bg-white dark:bg-gray-900 transition-colors duration-300"
      >
        <h2
          class="md:text-4xl text-3xl font-bold antialiased italic md:mb-10 mb-8 section-title text-center text-gray-900 dark:text-white"
        >
          Gallery
        </h2>
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 md:mx-0 mx-3 gap-2">
          <div
            v-if="dynamicContent?.content?.rsc_gallery_image_1_link"
            class="relative overflow-hidden bg-gray-300 dark:bg-gray-700 gallery-img wow zoomIn general_grow animated transition-colors duration-300"
          >
            <img
              :src="`${dynamicContent?.content?.rsc_gallery_image_1_link}`"
              alt="Gallery Image 1"
              class="object-cover w-full h-80"
            />
            <div class="absolute inset-0 flex items-center justify-center gallery-img-overlay">
              <p class="text-white font-semibold capitalize">
                {{ dynamicContent?.content?.rsc_gallery_image_1_text }}
              </p>
            </div>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_gallery_image_2_link"
            class="relative overflow-hidden bg-gray-300 dark:bg-gray-700 gallery-img wow zoomIn general_grow animated transition-colors duration-300"
          >
            <img
              :src="`${dynamicContent?.content?.rsc_gallery_image_2_link}`"
              alt="Gallery Image 1"
              class="object-cover w-full h-80"
            />
            <div class="absolute inset-0 flex items-center justify-center gallery-img-overlay">
              <p class="text-white font-semibold capitalize">
                {{ dynamicContent?.content?.rsc_gallery_image_2_text }}
              </p>
            </div>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_gallery_image_3_link"
            class="relative overflow-hidden bg-gray-300 dark:bg-gray-700 gallery-img wow zoomIn general_grow animated transition-colors duration-300"
          >
            <img
              :src="`${dynamicContent?.content?.rsc_gallery_image_3_link}`"
              alt="Gallery Image 1"
              class="object-cover w-full h-80"
            />
            <div class="absolute inset-0 flex items-center justify-center gallery-img-overlay">
              <p class="text-white font-semibold capitalize">
                {{ dynamicContent?.content?.rsc_gallery_image_3_text }}
              </p>
            </div>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_gallery_image_4_link"
            class="relative overflow-hidden bg-gray-300 dark:bg-gray-700 gallery-img wow zoomIn general_grow animated transition-colors duration-300"
          >
            <img
              :src="`${dynamicContent?.content?.rsc_gallery_image_4_link}`"
              alt="Gallery Image 1"
              class="object-cover w-full h-80"
            />
            <div class="absolute inset-0 flex items-center justify-center gallery-img-overlay">
              <p class="text-white font-semibold capitalize">
                {{ dynamicContent?.content?.rsc_gallery_image_4_text }}
              </p>
            </div>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_gallery_image_5_link"
            class="relative overflow-hidden bg-gray-300 dark:bg-gray-700 gallery-img wow zoomIn general_grow animated transition-colors duration-300"
          >
            <img
              :src="`${dynamicContent?.content?.rsc_gallery_image_5_link}`"
              alt="Gallery Image 1"
              class="object-cover w-full h-80"
            />
            <div class="absolute inset-0 flex items-center justify-center gallery-img-overlay">
              <p class="text-white font-semibold capitalize">
                {{ dynamicContent?.content?.rsc_gallery_image_5_text }}
              </p>
            </div>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_gallery_image_6_link"
            class="relative overflow-hidden bg-gray-300 dark:bg-gray-700 gallery-img wow zoomIn general_grow animated transition-colors duration-300"
          >
            <img
              :src="`${dynamicContent?.content?.rsc_gallery_image_6_link}`"
              alt="Gallery Image 1"
              class="object-cover w-full h-80"
            />
            <div class="absolute inset-0 flex items-center justify-center gallery-img-overlay">
              <p class="text-white font-semibold capitalize">
                {{ dynamicContent?.content?.rsc_gallery_image_6_text }}
              </p>
            </div>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_gallery_image_7_link"
            class="relative overflow-hidden bg-gray-300 dark:bg-gray-700 gallery-img wow zoomIn general_grow animated transition-colors duration-300"
          >
            <img
              :src="`${dynamicContent?.content?.rsc_gallery_image_7_link}`"
              alt="Gallery Image 1"
              class="object-cover w-full h-80"
            />
            <div class="absolute inset-0 flex items-center justify-center gallery-img-overlay">
              <p class="text-white font-semibold capitalize">
                {{ dynamicContent?.content?.rsc_gallery_image_7_text }}
              </p>
            </div>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_gallery_image_8_link"
            class="relative overflow-hidden bg-gray-300 dark:bg-gray-700 gallery-img wow zoomIn general_grow animated transition-colors duration-300"
          >
            <img
              :src="`${dynamicContent?.content?.rsc_gallery_image_8_link}`"
              alt="Gallery Image 1"
              class="object-cover w-full h-80"
            />
            <div class="absolute inset-0 flex items-center justify-center gallery-img-overlay">
              <p class="text-white font-semibold capitalize">
                {{ dynamicContent?.content?.rsc_gallery_image_8_text }}
              </p>
            </div>
          </div>
        </div>
      </section>

      <!-- Videos section start here -->
      <section
        v-if="dynamicContent?.content?.rsc_vgallery_link_1"
        id="videos"
        class="container-fluid mx-auto md:py-20 py-12 bg-white dark:bg-gray-900 transition-colors duration-300"
      >
        <div class="container mx-auto relative">
          <h2
            class="md:text-4xl text-3xl font-bold antialiased italic md:mb-10 mb-8 section-title text-center text-gray-900 dark:text-white"
          >
            Our Videos
          </h2>
          <div class="flex justify-center flex-wrap">
            <div
              v-if="dynamicContent?.content?.rsc_vgallery_link_1"
              class="relative overflow-hidden sm:w-2/4 w-full px-2 mb-4 wow zoomIn general_grow animated"
            >
              <iframe width="100%" height="315" :src="dynamicContent?.content?.rsc_vgallery_link_1">
              </iframe>
            </div>
            <div
              v-if="dynamicContent?.content?.rsc_vgallery_link_2"
              class="relative overflow-hidden sm:w-2/4 w-full px-2 mb-4 wow zoomIn general_grow animated"
            >
              <iframe width="100%" height="315" :src="dynamicContent?.content?.rsc_vgallery_link_2">
              </iframe>
            </div>
            <div
              v-if="dynamicContent?.content?.rsc_vgallery_link_3"
              class="relative overflow-hidden sm:w-2/4 w-full px-2 mb-4 wow zoomIn general_grow animated"
            >
              <iframe width="100%" height="315" :src="dynamicContent?.content?.rsc_vgallery_link_3">
              </iframe>
            </div>
            <div
              v-if="dynamicContent?.content?.rsc_vgallery_link_4"
              class="relative overflow-hidden sm:w-2/4 w-full px-2 mb-4 wow zoomIn general_grow animated"
            >
              <iframe width="100%" height="315" :src="dynamicContent?.content?.rsc_vgallery_link_4">
              </iframe>
            </div>
          </div>
        </div>
      </section>

      <!-- Contact us section start here -->
      <section id="contact" class="bg-gray-100 dark:bg-gray-800 container-fluid mx-auto md:py-18 py-12 transition-colors duration-300">
        <div class="container mx-auto">
          <h2
            class="md:text-4xl text-3xl font-bold antialiased italic md:mb-10 mb-8 section-title text-center text-gray-900 dark:text-white"
          >
            Contact Us
          </h2>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-1/2 p-4 wow zoomIn general_grow animated">
              <Contact :bgcolor="bgColor1"></Contact>
            </div>

            <div class="lg:block lg:w-1/2 w-full p-4 wow zoomIn general_grow animated">
              <iframe
                width="100%"
                height="490"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                :src="mapSrc"
                class="dark:opacity-80 transition-opacity duration-300"
              >
              </iframe>
            </div>
          </div>
        </div>
      </section>

      <section
        v-if="dynamicContent?.content?.rsc_food_hygiene_rating > 3"
        id="allergyInfo"
        class="container-fluid rating_section py-12 bg-white dark:bg-gray-900 transition-colors duration-300"
      >
        <div class="container mx-auto">
          <div class="text-center mx-auto wow zoomIn general_grow animated">
            <div>
              <img
                v-if="dynamicContent?.content?.rsc_food_hygiene_rating == 3"
                src="./../common/images/food-rating-3.svg"
                class="mb-4 inline-block w-1/3"
              />
              <img
                v-else-if="dynamicContent?.content?.rsc_food_hygiene_rating == 4"
                src="./../common/images/food-rating-4.svg"
                class="mb-4 inline-block w-1/3"
              />
              <img
                v-else
                src="./../common/images/food-rating-5.svg"
                class="mb-4 inline-block w-1/3"
              />
            </div>
            <div v-if="dynamicContent?.content?.rsc_allergy_text_1" class="">
              <p class="font-semibold text-xl text-gray-900 dark:text-white">
                {{ dynamicContent?.content?.rsc_allergy_text_1 }}
              </p>
              <p class="font-semibold text-xl text-gray-900 dark:text-white">{{ dynamicContent?.content?.rsc_allergy_text_2 }}</p>
              <a
                class="font-semibold inline-block btn mt-5 px-5 py-2 rounded-sm text-white md:text-xl text-base transition-colors duration-200"
                href="https://ratings.food.gov.uk/"
                target="_blank"
                >For more details</a
              >
            </div>
          </div>
        </div>
      </section>
    </main>
    <!-- Footer section start here -->
    <footer class="bg-white dark:bg-gray-900 text-gray-800 dark:text-white md:pt-14 pt-12 pb-4 px-3 pb-safe transition-colors duration-300 border-t border-gray-200 dark:border-gray-800">
      <div class="container mx-auto">
        <div class="md:grid md:grid-cols-3 gap-8">
          <div class="w-full md:mb-4 mb-8 wow zoomIn general_grow animated">
            <div class="mb-4">
              <img
                :src="dynamicContent?.info?.logo"
                alt="Company logo"
                class="rounded-md shadow-md mb-4"
              />
              <div class="flex space-x-4">
                <a
                  v-if="dynamicContent?.content?.rsc_social_media_link_facebook !== ''"
                  :href="dynamicContent?.content?.rsc_social_media_link_facebook"
                  target="_blank"
                  class="text-gray-600 dark:text-white hover:text-gray-900 dark:hover:text-gray-200 text-3xl cursor-pointer transition-colors duration-200"
                >
                  <i class="fa fa-facebook-square" aria-hidden="true"></i>
                </a>
                <a
                  v-if="dynamicContent?.content?.rsc_social_media_link_instagram !== ''"
                  :href="dynamicContent?.content?.rsc_social_media_link_instagram"
                  target="_blank"
                  class="text-gray-600 dark:text-white hover:text-gray-900 dark:hover:text-gray-200 text-3xl cursor-pointer transition-colors duration-200"
                >
                  <i class="fa fa-instagram" aria-hidden="true"></i>
                </a>
                <a
                  v-if="dynamicContent?.content?.rsc_social_media_link_twitter !== ''"
                  :href="dynamicContent?.content?.rsc_social_media_link_twitter"
                  target="_blank"
                  class="text-gray-600 dark:text-white hover:text-gray-900 dark:hover:text-gray-200 text-3xl cursor-pointer transition-colors duration-200"
                >
                  <i class="fa fa-twitter-square" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="w-full md:mb-4 mb-8 wow zoomIn general_grow animated">
            <div class="mb-4">
              <h3 class="text-xl font-bold mb-2 text-gray-900 dark:text-white">Find Us</h3>
              <div class="flex flex-col space-y-2">
                <div class="flex items-center">
                  <i class="fa fa-map-marker mr-2 text-gray-500 dark:text-gray-300"></i>
                  <span class="text-gray-600 dark:text-gray-300">
                    {{ dynamicContent?.info?.address }}
                  </span>
                </div>
                <div class="flex items-center">
                  <i class="fa fa-phone mr-2 text-gray-500 dark:text-gray-300"></i>
                  <a :href="`tel:${dynamicContent?.info?.phone_number}`" class="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white transition-colors duration-200">
                    {{ dynamicContent?.info?.phone_number }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full md:mb-4 mb-8 wow zoomIn general_grow animated">
            <div class="flex items-center">
              <i class="fa fa-clock-o mr-2 text-xl text-gray-500 dark:text-gray-300"></i>
              <h3 class="text-xl font-bold mb-2 text-gray-900 dark:text-white">Opening Hours</h3>
            </div>
            <ul
              v-for="openTime in dynamicContent?.info?.opening_times"
              :key="openTime?.day_number"
              class="list-disc ml-4 text-gray-600 dark:text-gray-300"
            >
              <li class="font-bold text-gray-800 dark:text-white">
                {{ openTime.day_title }}:
                <span
                  class="font-normal italic text-gray-600 dark:text-gray-300"
                  v-for="(time, index) in openTime.times"
                  :key="index"
                  >{{ time }},&nbsp;</span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr class="border-gray-200 dark:border-gray-700 my-6 transition-colors duration-300" />
      <div class="container mx-auto py-4">
        <div class="md:grid md:grid-cols-6 gap-8">
          <div class="col-span-2 mb-4 txt-copyRight wow zoomIn general_grow animated">
            <p class="mb-0 text-gray-500 dark:text-gray-400 text-center">&copy; {{ currentYear }} - All rights reserved</p>
          </div>
        </div>
      </div>
    </footer>

    <a
      id="back-to-top"
      href="#"
      class="fixed bottom-4 right-4 rounded-full p-2 bg-white dark:bg-gray-700 shadow-md hover:bg-gray-100 dark:hover:bg-gray-600 hover:animate-bounce transition-colors duration-300"
    >
      <i class="fa fa-chevron-up text-gray-700 dark:text-gray-200 transition-colors duration-300" aria-hidden="true"></i>
    </a>
  </div>
</template>

<style scoped>
@import '@/layouts/dynamicSite/template1/css/responsive.css';
@import 'https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css';
@import '@/layouts/dynamicSite/common/css/animate.css';

/* Add dark mode support classes */
:root {
  color-scheme: light dark;
}

/* Dark mode transitions */
.temp-w1 {
  @apply transition-colors duration-300;
}

/* Preserve theme colors for buttons */
.btn {
  @apply transition-colors duration-300;
}

/* Make sure theme transitions apply to all relevant elements */
* {
  @apply transition-colors duration-300;
}

/* Header styling */
.sticky-header {
  @apply shadow-lg bg-white bg-opacity-95 dark:bg-gray-800 dark:bg-opacity-95 backdrop-blur-sm;
  transition: all 0.3s ease;
}

/* Notice bar styling */
.top-notice-bar {
  font-weight: 500;
}

/* Add a pulse animation to the Notice text to make it stand out */
@keyframes subtle-pulse {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.8; }
}

.top-notice-bar .flex-none {
  animation: subtle-pulse 2s infinite ease-in-out;
}

/* Customer Message Styling */
.top-notice {
  @apply bg-gray-700 dark:bg-gray-800 transition-colors duration-300;
}

.top-notice-bar .flex-none {
  @apply text-white font-semibold;
}

.top-notice-bar .flex-grow {
  @apply text-white;
}

.customer-message {
  @apply text-white transition-colors duration-300;
  font-weight: 500;
  letter-spacing: 0.01em;
}

/* Additional slider background settings to ensure no repetition */
.slider-item {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

/* Fix for dark mode text visibility */
.dark .section-title {
  color: white !important;
}

.dark p {
  color: #d1d5db !important; /* gray-300 */
}

.dark .text-gray-300,
.dark .text-gray-200 {
  color: #e5e7eb !important; /* gray-200 */
}

/* Footer styling fixes for both light and dark mode */
footer {
  @apply bg-white dark:bg-gray-900 text-gray-800 dark:text-white transition-colors duration-300;
}

footer h3 {
  @apply text-gray-900 dark:text-white transition-colors duration-300;
}

footer span,
footer a {
  @apply text-gray-600 dark:text-gray-300 transition-colors duration-300;
}

footer a:hover {
  @apply text-gray-900 dark:text-white transition-colors duration-200;
}

footer .fa {
  @apply text-gray-600 dark:text-white transition-colors duration-300;
}

/* Social media icons */
footer a .fa {
  @apply text-gray-600 dark:text-white hover:text-gray-900 dark:hover:text-gray-200 transition-colors duration-300;
}

/* Fix for About Us section text */
#aboutUs .content h2 {
  color: #111827 !important; /* gray-900 in light mode */
}

#aboutUs .content p {
  color: #374151 !important; /* gray-700 in light mode */
}

.dark #aboutUs .content h2 {
  color: white !important;
}

.dark #aboutUs .content p {
  color: #d1d5db !important; /* gray-300 in dark mode */
}

.about2-section p {
  color: #374151 !important; /* gray-700 in light mode */
}

.dark .about2-section p {
  color: #d1d5db !important; /* gray-300 in dark mode */
}

/* Copyright text */
.txt-copyRight p {
  @apply text-center;
}
</style>
