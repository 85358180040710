<script>
import AppLayout from '@/layouts/AppLayout.vue'
import { useRouter } from 'vue-router'

export default {
  components: {
    AppLayout
  },
  setup() {
    const router = useRouter()

    return {
      router
    }
  }
}
</script>
<template>
  <AppLayout>
    <div
      class="flex flex-col items-center justify-center sm:px-6 px-3 sm:py-8 py-4 mx-auto pt:mt-0"
    >
      <!-- Card -->
      <div class="w-full max-w-4xl p-6 mb-6 sm:p-8 bg-white rounded-lg shadow dark:bg-gray-800">
        <div class="text-center mb-7">
          <h1 class="text-3xl font-semibold mb-1">Welcome to Partner Centre</h1>
          <p class="text-lg">Partner Centre - manage your restaurant business online.</p>
        </div>
        <div class="w-full max-w-sm mx-auto text-center">
          <a
            type="button"
            href="https://www.onlygulp.co.uk/client/login"
            target="_blank"
            class="w-full justify-center rounded-md border border-transparent bg-violet-700 py-2 px-4 mb-1 text-lg font-medium text-white text-center shadow-sm hover:bg-violet-800 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2"
          >
            Client login
          </a>
          <div class="ml-3 text-right mb-4">
            <router-link
              class="font-medium text-violet-700 text-base dark:text-white hover:text-violet-800"
              :to="{ name: 'clientForgotPassword' }"
              >Forgot password?</router-link
            >
          </div>
          <button
            type="submit"
            class="w-full rounded-md border border-transparent bg-gray-400 py-2 px-4 text-lg font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-offset-gray-50"
            @click="$router.push({ path: '/' })"
          >
            Back to home
          </button>
        </div>
      </div>
      <div class="w-full max-w-4xl p-6 sm:p-8 bg-white rounded-lg shadow dark:bg-gray-800">
        <div class="text-center mb-7">
          <h1 class="text-3xl font-semibold mb-3">Our Products & Services</h1>
          <p class="text-base text-left">
            We deliver straight-forward, technology solutions aligned tightly with each client's
            unique business objectives. Through a focus on business process and a commitment to
            relationships, we help you execute core business strategies that speed return on
            investment.
          </p>
        </div>
        <div class="mb-4">
          <h2 class="text-2xl font-normal mb-2">Restaurant Order Management System</h2>
          <ul class="list-disc pl-8 text-base">
            <li>Free static website for restaurants</li>
            <li>Accept delivery and collection orders</li>
            <li>Integrated payment gateways that accepts all major cards</li>
            <li>Create promotional discount offers such as Christmas, Black Friday</li>
            <li>Responsive website that compatible with tablets and smart phones</li>
          </ul>
        </div>
        <div>
          <h2 class="text-2xl font-normal mb-2">Search Engine Optimization (SEO)</h2>
          <ul class="list-disc pl-8 text-base">
            <li>Search Engine Optimization (SEO) Service</li>
            <li>Link Building Services</li>
            <li>Directory Submissions</li>
            <li>Website Optimization Services</li>
            <li>Keyword Research</li>
          </ul>
        </div>
      </div>
    </div>
  </AppLayout>
</template>
