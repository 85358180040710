<script setup>
import AppLayout from '@/layouts/AppLayout.vue'
import { onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { ShoppingBagIcon, ArrowPathIcon, ArrowLeftIcon, ClipboardDocumentCheckIcon } from '@heroicons/vue/20/solid'
import { BanknotesIcon, CreditCardIcon } from '@heroicons/vue/24/outline'
import { reorderFn, getOrderStatusFn } from '@/services/api/restApiCalls'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'

const restoStore = useRestoGLobalStore()
const router = useRouter()
const route = useRoute()
const orderId = ref(route.params.order_id)
const orderDetailData = ref({})

const getOrderDetail = async () => {
  if (orderId.value) {
    const order_status = await getOrderStatusFn(orderId.value, restoStore?.RESTO_ID)
    if (order_status.order_id == orderId.value) {
      orderDetailData.value = order_status
    } else {
      router.push({ name: 'my_orders' })
    }
  } else {
    router.push({ name: 'menu', params: { menu_slug: restoStore.MENU_URL } })
  }
}
const handleReorder = async () => {
  let reorderFormData = new FormData()
  reorderFormData.append('order_id', orderId.value)
  reorderFormData.append('resto_id', restoStore?.RESTO_ID)
  const order_data = await reorderFn(reorderFormData)
  if (order_data?.status == 201) {
    router.push({ name: 'menu', params: { menu_slug: restoStore.MENU_URL } })
  }
}
onMounted(() => {
  getOrderDetail()
})
</script>
<template>
  <AppLayout>
    <div class="container mx-auto md:pt-12 pt-8 dark:bg-background-dark transition-colors duration-200">
      <!-- Order Header with Title and Date -->
      <div class="mb-4">
        <div class="sm:flex-auto text-center md:mb-6 mb-4 px-2">
          <h1 class="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-2 transition-colors duration-200">
            Your Order No : {{ orderDetailData?.order_id }}
          </h1>
          <p class="text-gray-600 dark:text-gray-400 transition-colors duration-200">Placed on: {{ orderDetailData?.creation_datetime }}</p>
        </div>
        
        <!-- Action Buttons -->
        <div class="flex flex-wrap justify-center text-center px-2 max-w-3xl mx-auto gap-2 md:gap-3">
          <button
            type="button"
            class="md:w-auto w-full flex-1 rounded-md border border-transparent bg-gray-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-500 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none focus:ring-offset-gray-50 transition-colors duration-200 flex items-center justify-center"
            @click="$router.push({ name: 'my_orders' })"
          >
            <ArrowLeftIcon class="h-5 w-5 mr-1.5" aria-hidden="true" />
            Back to Orders
          </button>
          <button
            type="button"
            class="md:w-auto w-full flex-1 justify-center rounded-md border border-transparent bg-violet-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-violet-800 dark:hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 transition-colors duration-200 flex items-center justify-center"
            @click="
              router.push({
                name: 'order_status',
                params: { order_id: `${orderDetailData?.order_id}` }
              })
            "
          >
            <ClipboardDocumentCheckIcon class="h-5 w-5 mr-1.5" aria-hidden="true" />
            Check Status
          </button>
          <button
            type="button"
            class="md:w-auto w-full flex-1 justify-center rounded-md border border-transparent bg-violet-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-violet-800 dark:hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 transition-colors duration-200 flex items-center justify-center"
            @click="handleReorder()"
          >
            <ArrowPathIcon class="h-5 w-5 mr-1.5" aria-hidden="true" />
            Reorder
          </button>
        </div>
      </div>
      
      <!-- Payment Error Message -->
      <div
        class="font-semibold block md:text-lg text-red-500 dark:text-red-400 mb-5 text-center transition-colors duration-200 max-w-3xl mx-auto bg-red-50 dark:bg-red-900/20 p-3 rounded-lg shadow-sm"
        v-if="orderDetailData?.order_status == 'FAILURE' && orderDetailData?.payment_type == 'card'"
      >
        {{ orderDetailData?.display_card_payment_error_message }}
      </div>
      
      <!-- Order Details -->
      <div class="pb-4 sm:px-2 px-1 lg:grid lg:grid-cols-12 lg:gap-x-6 lg:px-0 lg:pb-8">
        <!-- Left Column: Order Items -->
        <dl
          class="divide-y divide-gray-200 dark:divide-gray-700 text-sm lg:col-span-6 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-sm sm:p-4 px-2 py-3 md:p-6 transition-colors duration-200"
        >
          <div class="pb-3 border-b border-gray-200 dark:border-gray-700">
            <h2 class="text-lg font-semibold text-gray-900 dark:text-gray-100 transition-colors duration-200">Order Items</h2>
          </div>
          <table class="min-w-full divide-y divide-gray-300 dark:divide-gray-700 transition-colors duration-200">
            <thead>
              <tr>
                <th
                  scope="col"
                  class="pb-3.5 pl-0 md:pr-3 sm:pr-2 pr-1 text-left md:text-lg text-md font-semibold text-gray-900 dark:text-gray-100 transition-colors duration-200"
                >
                  Item Name
                </th>
                <th
                  scope="col"
                  class="md:px-3 sm:px-2 px-1 pb-3.5 text-center md:text-lg text-md font-semibold text-gray-900 dark:text-gray-100 lg:table-cell transition-colors duration-200"
                >
                  Quantity
                </th>
                <th
                  scope="col"
                  class="md:px-3 sm:px-2 px-1 pb-3.5 text-right md:text-lg text-md font-semibold text-gray-900 dark:text-gray-100 transition-colors duration-200"
                >
                  Total Price
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 dark:divide-gray-700 transition-colors duration-200">
              <tr v-for="order in orderDetailData?.order_items" :key="order.item_id" class="hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors duration-150">
                <td class="py-3 md:pr-3 sm:pr-2 pr-1 text-sm font-medium text-gray-900 dark:text-gray-100 pl-0 transition-colors duration-200">
                  <span class="font-semibold block">{{ order.item_name }}</span>
                  <span class="font-normal block text-gray-600 dark:text-gray-400 transition-colors duration-200" v-html="order.order_item_html"></span>
                </td>
                <td
                  class="whitespace-nowrap md:px-3 sm:px-2 px-1 py-3 text-sm text-center text-gray-900 dark:text-gray-300 sm:table-cell transition-colors duration-200"
                >
                  {{ order.quantity }}
                </td>
                <td
                  class="whitespace-nowrap md:px-3 sm:px-2 px-1 py-3 text-sm text-right text-gray-900 dark:text-gray-300 lg:table-cell transition-colors duration-200"
                >
                  £{{ order.price }}
                </td>
              </tr>
            </tbody>
          </table>
          
          <!-- Order Summary -->
          <div class="flex items-center justify-between ms:py-4 py-3">
            <dt class="text-gray-900 dark:text-gray-100 font-semibold transition-colors duration-200">Subtotal</dt>
            <dd class="font-medium text-gray-900 dark:text-gray-100 md:px-3 sm:px-2 px-1 transition-colors duration-200">
              £{{ orderDetailData?.order_subtotal }}
            </dd>
          </div>
          <div
            v-if="orderDetailData?.discount_amount > 0"
            class="flex items-center justify-between ms:py-4 py-3"
          >
            <dt class="text-red-600 dark:text-red-400 font-semibold transition-colors duration-200">Discount</dt>
            <dd class="font-medium text-red-600 dark:text-red-400 md:px-3 sm:px-2 px-1 transition-colors duration-200">
              £-{{ orderDetailData?.discount_amount }}
            </dd>
          </div>
          <div
            v-if="orderDetailData?.order_type == 'delivery'"
            class="flex items-center justify-between ms:py-4 py-3"
          >
            <dt class="text-gray-900 dark:text-gray-100 font-semibold transition-colors duration-200">Delivery Fee</dt>
            <dd class="font-medium text-gray-900 dark:text-gray-100 md:px-3 sm:px-2 px-1 transition-colors duration-200">
              £{{ orderDetailData?.delivery_amount }}
            </dd>
          </div>
          <div
            v-if="orderDetailData?.service_charge_fee > 0"
            class="flex items-center justify-between ms:py-4 py-3"
          >
            <dt class="text-gray-900 dark:text-gray-100 font-semibold transition-colors duration-200">Service Charge</dt>
            <dd class="font-medium text-gray-900 dark:text-gray-100 md:px-3 sm:px-2 px-1 transition-colors duration-200">
              £{{ orderDetailData?.service_charge_fee }}
            </dd>
          </div>
          <div class="flex items-center justify-between ms:py-4 py-3 border-t-2 border-gray-200 dark:border-gray-700">
            <dt class="text-gray-900 dark:text-gray-100 font-semibold text-lg transition-colors duration-200">Total</dt>
            <dd class="font-medium text-gray-900 dark:text-gray-100 md:px-3 sm:px-2 px-1 text-lg transition-colors duration-200">
              <span class="font-bold">£{{ orderDetailData?.amount }}</span>
            </dd>
          </div>
        </dl>
        
        <hr class="lg:hidden block border-gray-200 dark:border-gray-700 transition-colors duration-200 my-6" />
        
        <!-- Right Column: Customer Information -->
        <dl
          class="md:mt-8 mt-4 divide-y divide-gray-200 dark:divide-gray-700 text-sm lg:col-span-6 lg:mt-0 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-sm sm:p-4 px-2 py-3 md:p-6 transition-colors duration-200"
        >
          <div class="pb-3 border-b border-gray-200 dark:border-gray-700">
            <h2 class="text-lg font-semibold text-gray-900 dark:text-gray-100 transition-colors duration-200">Order Information</h2>
          </div>
          
          <!-- Customer Information -->
          <div class="flex items-center justify-between py-3">
            <dt class="text-gray-700 dark:text-gray-300 font-semibold md:text-lg text-md transition-colors duration-200">Customer Name</dt>
            <dd class="sm:font-medium font-normal text-gray-900 dark:text-gray-100 capitalize text-base transition-colors duration-200">
              {{
                orderDetailData?.customer?.first_name + ' ' + orderDetailData?.customer?.last_name
              }}
            </dd>
          </div>
          
          <!-- Order Status -->
          <div class="flex items-center justify-between py-3">
            <dt class="text-gray-700 dark:text-gray-300 font-semibold md:text-lg text-md transition-colors duration-200">Order Status</dt>
            <dd
              v-if="orderDetailData?.order_status == 'FAILURE'"
              class="font-medium text-red-600 dark:text-red-400 transition-colors duration-200"
            >
              <span class="font-semibold block md:text-base"
                >{{ orderDetailData?.display_card_payment_error_message }}
              </span>
              <span class="font-semibold block md:text-base">You have not been charged.</span>
            </dd>
            <dd
              v-else
              class="font-medium transition-colors duration-200"
              :class="{
                'text-red-600 dark:text-red-400':
                  orderDetailData?.order_status == 'AUTHORIZED' ||
                  orderDetailData?.order_status == 'CANCELLED',
                'text-green-600 dark:text-green-400': orderDetailData?.order_status == 'SUCCESS'
              }"
            >
              {{ orderDetailData?.order_status }}
            </dd>
          </div>
          
          <!-- Order Type -->
          <div class="flex items-center justify-between py-3">
            <dt class="text-gray-700 dark:text-gray-300 font-semibold md:text-lg text-md transition-colors duration-200">Order Type</dt>
            <dd class="sm:font-medium font-normal text-gray-900 dark:text-gray-100 capitalize transition-colors duration-200">
              <span
                class="flex items-center px-4 py-1 text-white rounded-full transition-colors duration-200"
                :class="[
                  orderDetailData?.order_type == 'delivery' 
                    ? 'bg-green-600 dark:bg-green-700' 
                    : 'bg-violet-600 dark:bg-violet-700'
                ]"
              >
                <span v-if="orderDetailData?.order_type == 'collection'" class="mr-1.5">
                  <ShoppingBagIcon class="h-4 w-4 text-white mx-auto" aria-hidden="true" />
                </span>
                <span v-if="orderDetailData?.order_type == 'delivery'" class="mr-1.5">
                  <svg
                    class="h-4 w-4 text-white fill-current"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      d="M20 11c-.18 0-.36.03-.53.05L17.41 9H20V6l-3.72 1.86L13.41 5H9v2h3.59l2 2H11l-4 2-2-2H0v2h4c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4l2 2h3l3.49-6.1 1.01 1.01c-.91.73-1.5 1.84-1.5 3.09 0 2.21 1.79 4 4 4s4-1.79 4-4-1.79-4-4-4zM4 17c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm16 0c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
                    ></path>
                  </svg>
                </span>
                {{ orderDetailData?.order_type }}
              </span>
            </dd>
          </div>
          
          <!-- Payment Type -->
          <div class="flex items-center justify-between py-3">
            <dt class="text-gray-700 dark:text-gray-300 font-semibold md:text-lg text-md transition-colors duration-200">Payment Type</dt>
            <dd class="sm:font-medium font-normal text-gray-900 dark:text-gray-100 capitalize transition-colors duration-200">
              <span
                class="flex items-center px-4 py-1 text-white rounded-full transition-colors duration-200"
                :class="[
                  orderDetailData?.payment_type == 'cash' 
                    ? 'bg-yellow-600 dark:bg-yellow-700' 
                    : 'bg-purple-600 dark:bg-purple-700'
                ]"
              >
                <span v-if="orderDetailData?.payment_type == 'cash'" class="mr-1.5">
                  <BanknotesIcon class="h-4 w-4 text-white mx-auto" aria-hidden="true" />
                </span>
                <span v-if="orderDetailData?.payment_type == 'card'" class="mr-1.5">
                  <CreditCardIcon class="h-4 w-4 text-white mx-auto" aria-hidden="true" />
                </span>
                {{ orderDetailData?.payment_type }}
              </span>
            </dd>
          </div>
          
          <!-- Cooking Note -->
          <div v-if="orderDetailData?.note" class="py-3 bg-gray-50 dark:bg-gray-800/80 rounded-lg mt-2 mb-2 transition-colors duration-200">
            <div class="border-l-4 border-amber-500 dark:border-amber-400 pl-3 transition-colors duration-200">
              <dt class="text-gray-700 dark:text-gray-300 font-semibold md:text-lg text-md mb-2 transition-colors duration-200 flex items-center">
                <svg 
                  class="h-5 w-5 text-amber-500 dark:text-amber-400 mr-2 transition-colors duration-200" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path 
                    stroke-linecap="round" 
                    stroke-linejoin="round" 
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" 
                  />
                </svg>
                Cooking Note
              </dt>
              <dd class="sm:font-medium font-normal text-gray-900 dark:text-gray-100 transition-colors duration-200 p-2 ml-7">
                <p class="text-base">{{ orderDetailData?.note }}</p>
              </dd>
            </div>
          </div>
          
          <!-- Refunded Amount -->
          <div
            v-if="orderDetailData.refunded_amount > 0"
            class="flex items-center justify-between py-3"
          >
            <dt class="text-gray-700 dark:text-gray-300 font-semibold md:text-lg text-md transition-colors duration-200">Refunded amount</dt>
            <dd class="sm:font-medium font-normal text-red-600 dark:text-red-400 transition-colors duration-200">
              £{{ orderDetailData?.refunded_amount }}
            </dd>
          </div>
          
          <!-- Voucher Code -->
          <div
            v-if="orderDetailData?.voucher?.code"
            class="flex items-center justify-between py-3"
          >
            <dt class="text-gray-700 dark:text-gray-300 font-semibold md:text-lg text-md transition-colors duration-200">Voucher code</dt>
            <dd class="sm:font-medium font-normal text-gray-900 dark:text-gray-100 text-base transition-colors duration-200">
              {{ orderDetailData?.voucher?.code }}
            </dd>
          </div>
          
          <!-- Delivery Address (if applicable) -->
          <div 
            v-if="orderDetailData?.order_type === 'delivery' && orderDetailData?.customer?.address" 
            class="py-3 bg-gray-50 dark:bg-gray-800/80 rounded-lg mt-2 mb-2 transition-colors duration-200"
          >
            <div class="border-l-4 border-green-500 dark:border-green-400 pl-3 transition-colors duration-200">
              <dt class="text-gray-700 dark:text-gray-300 font-semibold md:text-lg text-md mb-2 transition-colors duration-200 flex items-center">
                <svg
                  class="h-5 w-5 text-green-500 dark:text-green-400 mr-2 transition-colors duration-200"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
                    fill="currentColor"
                  ></path>
                </svg>
                Delivery Address
              </dt>
              <dd class="sm:font-medium font-normal text-gray-900 dark:text-gray-100 transition-colors duration-200 p-2 ml-7">
                <p class="text-base">{{ orderDetailData?.customer?.address?.address_line1 }}</p>
                <p v-if="orderDetailData?.customer?.address?.address_line2" class="text-base">{{ orderDetailData?.customer?.address?.address_line2 }}</p>
                <p v-if="orderDetailData?.customer?.address?.city" class="text-base">{{ orderDetailData?.customer?.address?.city }}</p>
                <p class="text-base font-medium">{{ orderDetailData?.customer?.address?.post_code }}</p>
              </dd>
            </div>
          </div>
          
          <!-- Delivery Instructions (if applicable) -->
          <div 
            v-if="orderDetailData?.order_type === 'delivery' && orderDetailData?.delivery_instructions" 
            class="py-3 bg-gray-50 dark:bg-gray-800/80 rounded-lg mt-2 mb-2 transition-colors duration-200"
          >
            <div class="border-l-4 border-green-500 dark:border-green-400 pl-3 transition-colors duration-200">
              <dt class="text-gray-700 dark:text-gray-300 font-semibold md:text-lg text-md mb-2 transition-colors duration-200 flex items-center">
                <svg 
                  class="h-5 w-5 text-green-500 dark:text-green-400 mr-2 transition-colors duration-200" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path 
                    stroke-linecap="round" 
                    stroke-linejoin="round" 
                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" 
                  />
                </svg>
                Delivery Instructions
              </dt>
              <dd class="sm:font-medium font-normal text-gray-900 dark:text-gray-100 transition-colors duration-200 p-2 ml-7">
                <p class="text-base italic">{{ orderDetailData?.delivery_instructions }}</p>
              </dd>
            </div>
          </div>
        </dl>
      </div>
    </div>
  </AppLayout>
</template>