<script>
import AppLayout from '@/layouts/AppLayout.vue'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { CheckCircleIcon, XCircleIcon, EnvelopeIcon, ExclamationCircleIcon } from '@heroicons/vue/20/solid'
import { forgotPasswordFn } from '@/services/api/restApiCalls'
import LoadingSpinner from '@/components/common/LoadingSpinner/LoadingSpinner.vue'

export default {
  name: 'ForgotPasswordView',
  components: {
    AppLayout,
    CheckCircleIcon,
    XCircleIcon,
    EnvelopeIcon,
    ExclamationCircleIcon,
    LoadingSpinner
  },
  setup() {
    const router = useRouter()
    const userEmail = ref('')
    const submitted = ref(false)
    const emailValid = ref(false)
    const emailInvalid = ref(false)
    const isEmailSent = ref(false)
    const isLoading = ref(false)
    const errorMsg = ref('')

    const validationRules = {
      userEmail: {
        required,
        email
      }
    }

    const v$ = useVuelidate(validationRules, {
      userEmail
    })

    const handleForgotPassword = async () => {
      submitted.value = true
      v$.value.$validate()
      if (v$.value.$invalid) {
        return
      }
      if (isLoading.value) return
      isLoading.value = true
      try {
        let forgotPasswordFormData = new FormData()
        forgotPasswordFormData.append('email', userEmail.value)
        const forgotPasswordRes = await forgotPasswordFn(forgotPasswordFormData)
        if (forgotPasswordRes?.status == 400) {
          errorMsg.value = forgotPasswordRes?.status_msg
        } else {
          isEmailSent.value = true
        }
      } catch (err) {
        console.error(err)
      }
      isLoading.value = false
      submitted.value = false
    }

    return {
      router,
      v$,
      userEmail,
      submitted,
      emailValid,
      emailInvalid,
      handleForgotPassword,
      isEmailSent,
      errorMsg,
      isLoading
    }
  }
}
</script>
<template>
  <AppLayout>
    <div
      class="flex flex-col items-center justify-center sm:px-6 px-3 sm:py-12 py-6 mx-auto pt:mt-0"
    >
      <!-- Card -->
      <div class="w-full max-w-md p-8 bg-white dark:bg-gray-800 rounded-xl shadow-lg transform transition-all duration-200 hover:shadow-xl">
        <div class="text-center mb-8">
          <h2 class="text-3xl font-bold text-gray-900 dark:text-gray-100">Reset Password</h2>
          <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
            Enter your email and we'll send you a link to reset your password
          </p>
        </div>

        <div v-if="!isEmailSent">
          <div v-if="errorMsg" class="mb-6 p-4 rounded-lg bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 dark:border-red-400 transition-all duration-200">
            <div class="flex items-start">
              <div class="flex-shrink-0 pt-0.5">
                <ExclamationCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div class="ml-3">
                <div class="text-sm font-medium text-red-600 dark:text-red-400">{{ errorMsg }}</div>
              </div>
            </div>
          </div>

          <form class="space-y-6" @submit.prevent="handleForgotPassword">
            <div>
              <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                >Email address</label
              >
              <div class="relative">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <EnvelopeIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
                </div>
                <input
                  type="email"
                  name="email"
                  id="email"
                  class="block w-full appearance-none rounded-lg border border-gray-300 dark:border-gray-600 pl-10 px-4 py-3 placeholder-gray-400 dark:placeholder-gray-500 shadow-sm focus:border-violet-500 focus:ring focus:ring-violet-200 dark:focus:ring-violet-800/20 focus:ring-opacity-50 focus:outline-none text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 transition-all duration-200"
                  :class="{ 'border-red-600 dark:border-red-500 focus:border-red-600 focus:ring-red-200 dark:focus:ring-red-800/20': submitted && v$.userEmail.$error }"
                  placeholder="name@company.com"
                  v-model="userEmail"
                />
              </div>
              <div v-for="(error, index) of v$.userEmail.$errors" :key="index" class="mt-1">
                <div class="text-sm font-medium text-red-600 dark:text-red-400">{{ error.$message }}</div>
              </div>
            </div>

            <button
              type="submit"
              class="w-full justify-center rounded-lg border border-transparent bg-violet-700 py-3 px-4 text-sm font-medium text-white shadow-sm hover:bg-violet-800 dark:hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 transition-all duration-200 transform hover:-translate-y-0.5"
            >
              Send Reset Link
            </button>

            <div class="relative">
              <div class="absolute inset-0 flex items-center" aria-hidden="true">
                <div class="w-full border-t border-gray-200 dark:border-gray-700"></div>
              </div>
              <div class="relative flex justify-center text-sm font-medium leading-6">
                <span class="bg-white dark:bg-gray-800 px-6 text-gray-500 dark:text-gray-400">Or</span>
              </div>
            </div>

            <div class="text-center">
              <router-link
                class="inline-block w-full py-3 text-center font-medium text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white transition-colors duration-200"
                :to="{ name: 'login' }"
              >
                Back to Login
              </router-link>
            </div>
          </form>
        </div>

        <div v-else class="text-center">
          <div
            class="mx-auto flex items-center justify-center h-20 w-20 rounded-full bg-green-100 dark:bg-green-900 mb-6"
          >
            <svg
              class="h-10 w-10 text-green-600 dark:text-green-300"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
          <h3 class="mt-3 text-xl font-medium text-gray-900 dark:text-gray-100">Check Your Email</h3>
          <p class="mt-4 text-sm text-gray-600 dark:text-gray-400">
            We've sent a password reset link to your email.<br>
            Please check your inbox and follow the instructions.
          </p>
          <div class="mt-8">
            <button
              type="button"
              class="w-full justify-center rounded-lg border border-transparent bg-violet-700 py-3 px-4 text-sm font-medium text-white shadow-sm hover:bg-violet-800 dark:hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 transition-all duration-200 transform hover:-translate-y-0.5"
              @click="$router.push({ name: 'login' })"
            >
              Return to Login
            </button>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
  <LoadingSpinner :isLoading="isLoading" />
</template>
