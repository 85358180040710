<script setup lang="ts">
import { useThemeStore } from '@/stores/themeStore'
import { computed } from 'vue'
import { SunIcon, MoonIcon } from '@heroicons/vue/24/outline'

const themeStore = useThemeStore()

const currentTheme = computed(() => themeStore.theme)

const toggleTheme = () => {
  themeStore.toggleTheme()
}
</script>

<template>
  <div class="flex items-center space-x-2 p-2">
    <button 
      @click="toggleTheme" 
      class="p-2 rounded-full transition-colors bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600"
      aria-label="Toggle theme"
    >
      <SunIcon v-if="currentTheme === 'dark'" class="h-5 w-5 text-yellow-400" />
      <MoonIcon v-else class="h-5 w-5 text-gray-700 dark:text-gray-300" />
    </button>
    
    <div class="text-xs text-gray-500 dark:text-gray-400 hidden sm:block">
      {{ currentTheme === 'dark' ? 'Dark' : 'Light' }}
    </div>
  </div>
</template> 