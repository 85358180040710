<script setup>
import { onMounted, onBeforeUnmount, ref, onBeforeMount, inject, computed } from 'vue'
import WOW from 'wow.js'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import Contact from '@/components/contact/Contact.vue'
import { XMarkIcon } from '@heroicons/vue/20/solid'
import { MoonIcon, SunIcon, Bars3Icon } from '@heroicons/vue/24/outline'

const IS_MOBILE_APP = inject('IS_MOBILE_APP')
const restoStore = useRestoGLobalStore()
const dynamicContent = restoStore?.restoInfo?.dynamic_site_content
const address = `${restoStore?.restoInfo?.address}`
const name = `${restoStore?.restoInfo?.name}`
const query = `${name} ${address}`
const mapSrc = `https://maps.google.com/maps?q=` + encodeURI(query) + `&output=embed`

const date = new Date()
const currentYear = date.getFullYear()
const isSticky = ref(false)
const showMobileHeader = ref(false)
const bgColor1 = ref('')
const menuIconColourClass = ref('text-slate-900 dark:text-white')

// Theme handling
const isDarkMode = ref(false)

// Check if user has a theme preference in localStorage or prefers dark mode
const initializeTheme = () => {
  // Try to get theme from localStorage using the new JSON structure
  const storedTheme = localStorage.getItem('theme')
  let themePreference = null
  
  if (storedTheme) {
    try {
      const themeObj = JSON.parse(storedTheme)
      themePreference = themeObj.theme
    } catch (e) {
      // Handle parsing error - fall back to dark mode as default
      console.error('Error parsing theme from localStorage')
      themePreference = 'dark'
    }
  }
  
  // Default to dark mode unless explicitly set to light
  if (themePreference === 'light') {
    isDarkMode.value = false
    document.documentElement.classList.remove('dark')
  } else {
    // Default to dark mode
    isDarkMode.value = true
    document.documentElement.classList.add('dark')
    
    // Save dark mode preference if not already stored
    if (!storedTheme) {
      console.log('Setting default dark theme')
      localStorage.setItem('theme', JSON.stringify({ theme: 'dark' }))
    }
  }
}

// Toggle between light and dark mode
const toggleDarkMode = () => {
  isDarkMode.value = !isDarkMode.value
  
  // Update localStorage with JSON structure and document classes
  if (isDarkMode.value) {
    document.documentElement.classList.add('dark')
    localStorage.setItem('theme', JSON.stringify({ theme: 'dark' }))
  } else {
    document.documentElement.classList.remove('dark')
    localStorage.setItem('theme', JSON.stringify({ theme: 'light' }))
  }
}

const handleScroll = () => {
  isSticky.value = window.scrollY > 50
}
const handleShowHeader = () => {
  showMobileHeader.value = !showMobileHeader.value
}

const handleNavLinkClick = () => {
  showMobileHeader.value = false
}

onBeforeMount(() => {
  initializeTheme()
  
  if (dynamicContent?.mobile_style == 1) {
    import('@/layouts/mobileAppSite/template2/css/style1.css')
    bgColor1.value = '#0a4363'
  } else if (dynamicContent?.mobile_style == 2) {
    import('@/layouts/mobileAppSite/template2/css/style2.css')
    bgColor1.value = '#ce1212'
  } else if (dynamicContent?.mobile_style == 3) {
    import('@/layouts/mobileAppSite/template2/css/style3.css')
    bgColor1.value = '#df6853'
  } else if (dynamicContent?.mobile_style == 4) {
    import('@/layouts/mobileAppSite/template2/css/style4.css')
    bgColor1.value = '#ffa323'
  } else {
    import('@/layouts/mobileAppSite/template2/css/style1.css')
    bgColor1.value = '#0a4363'
  }
})
onMounted(() => {
  window.addEventListener('scroll', handleScroll)
  new WOW().init()
  const favicon = document.querySelector('link[rel="icon"]')
  if (favicon) {
    favicon.href = `${dynamicContent?.info?.logo}`
  } else {
    const newFavicon = document.createElement('link')
    newFavicon.rel = 'icon'
    newFavicon.type = 'image/x-icon'
    newFavicon.href = `${dynamicContent?.info?.logo}`
    document.head.appendChild(newFavicon)
  }

  var topHeader = document.getElementById('topBanner')
  if (topHeader) {
    topHeader.style.backgroundPosition = 'center center'
    window.addEventListener('scroll', function () {
      var scrollTop = window.scrollY
      topHeader.style.backgroundPosition = `center calc(50% + ${scrollTop * 0.4}px)`
    })
  }
  
  // Listen for system theme changes
  const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
  mediaQuery.addEventListener('change', initializeTheme)
})
onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
  
  // Remove event listener for theme changes
  const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
  mediaQuery.removeEventListener('change', initializeTheme)
})
</script>
<template>
  <div class="temp-w2 transition-colors duration-300 bg-white dark:bg-gray-800" ref="mobileMenuRef">
    <!-- Header -->
    <header
      :class="{ 'sticky-header': isSticky }"
      class="bg-white dark:bg-gray-800 sticky top-0 z-50 relative pt-safe transition-colors duration-300 shadow-md"
    >
      <div class="container mx-auto flex items-center justify-between py-4 px-4 sm:px-6 lg:px-8">
        <!-- Logo -->
        <div class="flex items-center">
          <img :src="dynamicContent?.info?.logo" alt="Logo" class="h-12 w-12 mr-2" />
        </div>

        <!-- Navigation Links -->
        <nav class="hidden md:flex items-center space-x-8">
          <a href="#home" class="uppercase font-medium text-gray-900 hover:text-black transition-colors duration-200 dark:text-gray-200 dark:hover:text-white">Home</a>
          <a href="#aboutUs" class="uppercase font-medium text-gray-900 hover:text-black transition-colors duration-200 dark:text-gray-200 dark:hover:text-white">About Us</a>
          <router-link
            class="uppercase font-medium text-gray-900 hover:text-black transition-colors duration-200 dark:text-gray-200 dark:hover:text-white"
            :to="{ name: 'menu', params: { menu_slug: restoStore?.MENU_URL } }"
            >Online Order</router-link
          >
          <a href="#contact" class="uppercase font-medium text-gray-900 hover:text-black transition-colors duration-200 dark:text-gray-200 dark:hover:text-white">Contact</a>
          <router-link
            v-if="dynamicContent?.info?.is_reservation_active"
            class="btn mr-2 px-5 py-1 rounded-sm text-white md:text-xl text-base transition-colors duration-200"
            :to="{ name: 'reservation', params: { resto_id: restoStore?.RESTO_ID } }"
            >Book Table</router-link
          >
        </nav>
        
        <!-- Theme Toggle Button -->
        <button 
          @click="toggleDarkMode" 
          class="hidden md:flex items-center justify-center p-2 rounded-full bg-gray-200 dark:bg-gray-700 transition-colors duration-300 hover:bg-gray-300 dark:hover:bg-gray-600 mr-3"
          aria-label="Toggle dark mode"
        >
          <SunIcon v-if="isDarkMode" class="h-5 w-5 text-yellow-400" />
          <MoonIcon v-else class="h-5 w-5 text-gray-700" />
        </button>

        <!-- Mobile Menu Button -->
        <div class="md:hidden flex items-center">
          <button 
            @click="toggleDarkMode" 
            class="p-2 rounded-full bg-gray-200 dark:bg-gray-700 transition-colors duration-300 hover:bg-gray-300 dark:hover:bg-gray-600 mr-3"
            aria-label="Toggle dark mode"
          >
            <SunIcon v-if="isDarkMode" class="h-5 w-5 text-yellow-400" />
            <MoonIcon v-else class="h-5 w-5 text-gray-700" />
          </button>
          
          <button class="focus:outline-none" @click="handleShowHeader()">
            <Bars3Icon v-if="!showMobileHeader" class="h-6 w-6 text-gray-900 dark:text-gray-200" aria-hidden="true" />
            <XMarkIcon v-else class="h-6 w-6 text-gray-900 dark:text-gray-200" aria-hidden="true" />
          </button>
        </div>

        <!-- Mobile Menu -->
        <div
          :class="[showMobileHeader ? 'block' : 'hidden']"
          class="mobile-menu bg-white dark:bg-gray-800 absolute left-0 right-0 top-20 pb-4 mt-safe transition-colors duration-300 shadow-md border-t border-gray-200 dark:border-gray-700"
        >
          <a href="#home" class="block uppercase py-2 px-4 font-medium text-gray-900 hover:bg-gray-100 hover:text-black dark:text-gray-200 dark:hover:bg-gray-700 dark:hover:text-white transition-colors duration-200" @click="handleNavLinkClick">Home</a>
          <a href="#aboutUs" class="block uppercase py-2 px-4 font-medium text-gray-900 hover:bg-gray-100 hover:text-black dark:text-gray-200 dark:hover:bg-gray-700 dark:hover:text-white transition-colors duration-200" @click="handleNavLinkClick"
            >About Us</a
          >
          <router-link
            class="block uppercase py-2 px-4 font-medium text-gray-900 hover:bg-gray-100 hover:text-black dark:text-gray-200 dark:hover:bg-gray-700 dark:hover:text-white transition-colors duration-200"
            :to="{ name: 'menu', params: { menu_slug: restoStore?.MENU_URL } }"
            >Online Order</router-link
          >
          <a href="#contact" class="block uppercase py-2 px-4 font-medium text-gray-900 hover:bg-gray-100 hover:text-black dark:text-gray-200 dark:hover:bg-gray-700 dark:hover:text-white transition-colors duration-200" @click="handleNavLinkClick"
            >Contact</a
          >
          <router-link
            v-if="dynamicContent?.info?.is_reservation_active"
            class="btn rounded-lg text-white text-sm uppercase py-2 px-4 mx-4 mt-3 transition-colors duration-200"
            :to="{ name: 'reservation', params: { resto_id: restoStore?.RESTO_ID } }"
            >Book Table</router-link
          >
        </div>
      </div>
    </header>
    <!-- Offer text -->
    <div
      v-if="dynamicContent?.content?.rsc_resto_note_customer_message"
      class="container-fluid pt-1 px-3 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 shadow-sm transition-colors duration-300"
    >
      <div class="top-notice-bar">
        <div class="flex">
          <div class="flex-none w-1/6 md:w-1/12 lg:w-1/12 xl:w-1/12 text-gray-800 dark:text-gray-200 font-semibold">
            Notice!
          </div>
          <div class="flex-grow w-5/6 md:w-11/12 lg:w-11/12 xl:w-11/12 text-gray-700 dark:text-gray-300">
            <marquee
              class="hover:cursor-pointer"
              onmouseover="this.stop();"
              onmouseout="this.start();"
            >
              {{ dynamicContent?.content?.rsc_resto_note_customer_message }}
            </marquee>
          </div>
        </div>
      </div>
    </div>
    <main class="px-safe dark:text-gray-200 transition-colors duration-300">
      <div id="home" class="carousel slide top_slider" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div
              id="topBanner"
              class="slider-item slider_1 bg-cover bg-center h-screen bg-gray-500 bg-no-repeat"
              :style="{
                background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${dynamicContent?.content?.rsc_slider_mobile_background_image_url})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }"
            ></div>
          </div>
        </div>

        <div class="container">
          <div class="flex justify-center items-center absolute top-0 bottom-0 mx-auto w-full">
            <div
              class="content-wrapper text-center font-sans text-white wow zoomIn general_grow animated"
            >
              <p class="subtitle md:text-2xl text-xl">Welcome to</p>
              <h1 class="mb-4 sm:text-6xl md:text-7xl text-4xl font-bold antialiased">
                {{ dynamicContent?.info?.name }}
              </h1>
              <div v-if="dynamicContent?.info?.active_website_offers.length > 0 && !IS_MOBILE_APP">
                <h2
                  v-for="(offer, index) in dynamicContent?.info?.active_website_offers"
                  :key="index"
                  class="my-2 lg:mb-1 md:text-xl text-lg"
                >
                  {{ offer }}
                </h2>
              </div>
              <div
                v-else-if="
                  dynamicContent?.info?.active_mobileapp_offers.length > 0 && IS_MOBILE_APP
                "
              >
                <h2
                  v-for="(offer, index) in dynamicContent?.info?.active_mobileapp_offers"
                  :key="index"
                  class="my-2 lg:mb-1 md:text-xl text-lg italic"
                >
                  {{ offer }}
                </h2>
              </div>

              <div class="btn-wrapper mt-6">
                <router-link
                  class="btn mr-4 px-5 py-2 rounded-sm text-white md:text-xl text-base transition-colors duration-200"
                  :to="{ name: 'menu', params: { menu_slug: restoStore?.MENU_URL } }"
                  >Order Now</router-link
                >
                <router-link
                  v-if="dynamicContent?.info?.is_reservation_active"
                  class="btn mr-2 px-5 py-2 rounded-sm text-white md:text-xl text-base transition-colors duration-200"
                  :to="{ name: 'reservation', params: { resto_id: restoStore?.RESTO_ID } }"
                  >Book Table</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- About us-1 Content -->
      <section
        v-if="dynamicContent?.content?.rsc_about_us_text_1"
        id="aboutUs"
        class="relative bg-gray-50 dark:bg-gray-800 py-16 px-4 transition-colors duration-300"
      >
        <div class="container mx-auto">
          <div class="max-w-7xl mx-auto px-0 lg:px-10 flex flex-col md:flex-row items-center">
            <div class="md:w-1/2 mb-10 md:mb-0 md:pr-10 text-gray-800 dark:text-gray-200 md:text-right text-center transition-colors duration-300">
              <h2 class="text-4xl font-bold text-gray-900 dark:text-white mb-4 section-title transition-colors duration-300">
                About <span class="sub-title">Our Restaurant</span>
              </h2>
              <hr class="my-4 border-gray-400 dark:border-gray-600 transition-colors duration-300" />
              <p
                v-html="dynamicContent?.content?.rsc_about_us_text_1"
                class="text-md text-gray-600 dark:text-gray-300 mb-6 transition-colors duration-300"
              ></p>
              <a
                :href="{ name: 'menu', params: { menu_slug: restoStore?.MENU_URL } }"
                class="btn-1 mr-2 px-5 py-2 text-white md:text-xl text-base transition-colors duration-200"
              >
                Explore Our Menu
              </a>
            </div>
            <div
              v-if="dynamicContent?.content?.rsc_about_us_image_1_link"
              class="md:w-1/2 w-full relative"
            >
              <div class="w-full h-96 md:h-[500px] overflow-hidden shadow-lg">
                <img
                  :src="dynamicContent?.content?.rsc_about_us_image_1_link"
                  alt="Restaurant Interior"
                  class="w-full h-full object-cover dark:opacity-90 transition-opacity duration-300"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Contact us section start here -->
      <section id="contact" class="bg-gray-100 dark:bg-gray-800 container-fluid mx-auto md:py-18 py-12 transition-colors duration-300">
        <div class="container mx-auto">
          <h2 class="md:text-4xl text-3xl font-bold antialiased md:mb-10 mb-8 section-title text-center text-gray-900 dark:text-white transition-colors duration-300">
            Contact Us
          </h2>
          <div class="flex flex-wrap">
            <!-- Contact Form - Set to exactly 50% width on larger screens -->
            <div class="w-full lg:w-1/2 p-4 wow zoomIn general_grow animated">
                <Contact :bgcolor="bgColor1"></Contact>
            </div>

            <!-- Map - Set to exactly 50% width on larger screens -->
            <div class="w-full lg:w-1/2 p-4 wow zoomIn general_grow animated">
              <iframe
                width="100%"
                height="490"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                :src="mapSrc"
                class="rounded-lg shadow-md dark:opacity-80 transition-opacity duration-300"
              >
              </iframe>
            </div>
          </div>
        </div>
      </section>
    </main>
    <!-- Footer section start here -->
    <footer class="bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 pt-12 transition-colors duration-300 border-t border-gray-100 dark:border-gray-800 shadow-sm">
      <div class="container mx-auto px-6">
        <div class="grid md:grid-cols-3 gap-8 mb-8">
          <!-- Location -->
          <div class="text-center md:text-left">
            <h5 class="text-xl font-bold uppercase mb-3 text-gray-900 dark:text-white transition-colors duration-300">Find us</h5>
            <p class="flex items-center justify-center md:justify-start space-x-2 text-gray-700 dark:text-gray-300 transition-colors duration-300">
              <i class="fa fa-map-marker text-gray-500 dark:text-gray-400 transition-colors duration-300"></i>
              <span>{{ dynamicContent?.info?.address || 'Address' }}</span>
            </p>
            <p class="mt-2 text-gray-700 dark:text-gray-300 transition-colors duration-300">
              <i class="fa fa-phone mr-2 text-gray-500 dark:text-gray-400 transition-colors duration-300"></i>
              <a :href="`tel:${dynamicContent?.info?.phone_number}`" class="hover:text-gray-900 dark:hover:text-white transition-colors duration-300">
                {{ dynamicContent?.info?.phone_number || 'Phone number' }}
              </a>
            </p>
          </div>

          <!-- Opening Hours -->
          <div class="text-center">
            <h5 class="text-xl font-bold uppercase mb-3 text-gray-900 dark:text-white transition-colors duration-300">Opening Hours</h5>
            <ul>
              <li
                v-for="openTime in dynamicContent?.info?.opening_times"
                :key="openTime?.day_number"
                class="mb-2 text-gray-700 dark:text-gray-300 transition-colors duration-300"
              >
                {{ openTime.day_title }}:
                <span v-for="(time, index) in openTime.times" :key="index">
                  {{ time }}<span v-if="index < openTime.times.length - 1">,&nbsp;</span>
                </span>
              </li>
            </ul>
          </div>

          <div class="text-center md:text-right">
            <h5 class="text-xl font-bold uppercase mb-3 text-gray-900 dark:text-white transition-colors duration-300">Food Hygiene rating</h5>
            <div class="">
              <div>
                <img
                  v-if="dynamicContent?.content?.rsc_food_hygiene_rating == 3"
                  src="./../../dynamicSite/common/images/food-rating-3.svg"
                  class="mb-4 inline-block w-5/6 dark:opacity-90 transition-opacity duration-300"
                />
                <img
                  v-else-if="dynamicContent?.content?.rsc_food_hygiene_rating == 4"
                  src="./../../dynamicSite/common/images/food-rating-4.svg"
                  class="mb-4 inline-block w-5/6 dark:opacity-90 transition-opacity duration-300"
                />
                <img
                  v-else
                  src="./../../dynamicSite/common/images/food-rating-5.svg"
                  class="mb-4 inline-block w-5/6 dark:opacity-90 transition-opacity duration-300"
                />
              </div>
              <div v-if="dynamicContent?.content?.rsc_allergy_text_1" class="">
                <p class="text-base text-gray-700 dark:text-gray-300 transition-colors duration-300">
                  {{ dynamicContent?.content?.rsc_allergy_text_1 }}
                </p>
                <p class="text-base text-gray-700 dark:text-gray-300 transition-colors duration-300">
                  {{ dynamicContent?.content?.rsc_allergy_text_2 }}
                </p>
                <a
                  class="font-semibold inline-block btn mt-5 px-5 rounded-sm text-white md:text-xl text-base transition-colors duration-200"
                  href="https://ratings.food.gov.uk/"
                  target="_blank"
                  >For more details</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer Bottom -->
      <div class="bg-gray-100 dark:bg-gray-800 py-4 mt-6 footer-bottom transition-colors duration-300">
        <div class="container mx-auto px-6">
          <div class="flex flex-col md:flex-row items-center justify-between">
            <!-- Logo -->
            <div class="mb-4 md:mb-0">
              <img :src="dynamicContent?.info?.logo" alt="Logo" class="h-12 w-12 mr-2" />
            </div>

            <!-- Navigation Links -->
            <ul class="flex space-x-4 text-sm uppercase mb-4 md:mb-0">
              <li><a href="#" class="text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white transition-colors duration-300">Home</a></li>
              <li><a href="#" class="text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white transition-colors duration-300">About Us</a></li>
              <li><a href="#" class="text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white transition-colors duration-300">Our Menu</a></li>
            </ul>

            <!-- Social Media -->
            <div class="flex space-x-4">
              <a
                v-if="dynamicContent?.content?.rsc_social_media_link_facebook"
                :href="dynamicContent.content.rsc_social_media_link_facebook"
                target="_blank"
              >
                <i class="fa fa-facebook fa-2x text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200 transition-colors duration-300"></i>
              </a>
              <a
                v-if="dynamicContent?.content?.rsc_social_media_link_instagram"
                :href="dynamicContent.content.rsc_social_media_link_instagram"
                target="_blank"
              >
                <i class="fa fa-instagram fa-2x text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200 transition-colors duration-300"></i>
              </a>
              <a
                v-if="dynamicContent?.content?.rsc_social_media_link_twitter"
                :href="dynamicContent.content.rsc_social_media_link_twitter"
                target="_blank"
              >
                <i class="fa fa-twitter fa-2x text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200 transition-colors duration-300"></i>
              </a>
            </div>
          </div>

          <hr class="border-gray-200 dark:border-gray-700 my-4 transition-colors duration-300" />

          <p class="text-center text-sm text-gray-500 dark:text-gray-400 transition-colors duration-300">
            &COPY; {{ new Date().getFullYear() }} - All rights reserved
          </p>
        </div>
      </div>
    </footer>

    <!-- Back to Top Button -->
    <a href="#" class="btn-1 fixed bottom-6 right-6 p-3 rounded-full shadow-lg text-white bg-opacity-80 hover:bg-opacity-100 transition-all duration-300">
      <i class="fa fa-chevron-up"></i>
    </a>
  </div>
</template>

<style scoped>
@import '@/layouts/dynamicSite/template2/css/responsive.css';
@import 'https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css';
@import 'https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,700;1,300;1,700';
@import 'https://code.jquery.com/jquery-3.6.4.min.js';

/* Add dark mode support classes */
:root {
  color-scheme: light dark;
}

/* Dark mode transitions */
.temp-w2 {
  @apply transition-colors duration-300;
}

/* Preserve theme colors for buttons */
.btn, .btn-1 {
  @apply transition-colors duration-300;
}

/* Make sure theme transitions apply to all relevant elements */
* {
  @apply transition-colors duration-300;
}

/* Header styling */
.sticky-header {
  @apply shadow-lg bg-white bg-opacity-95 dark:bg-gray-800 dark:bg-opacity-95 backdrop-blur-sm;
  transition: all 0.3s ease;
}

/* Ensure header nav links are visible in both modes */
header nav a, header .mobile-menu a {
  font-weight: 500;
  letter-spacing: 0.025em;
}

/* Notice bar styling */
.top-notice-bar {
  font-weight: 500;
}

/* Add a pulse animation to the Notice text to make it stand out */
@keyframes subtle-pulse {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.8; }
}

.top-notice-bar .flex-none {
  animation: subtle-pulse 2s infinite ease-in-out;
}

/* Add animation keyframes for wow effect */
@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

.wow.zoomIn {
  animation-name: zoomIn;
}

.general_grow {
  transition: transform 0.3s ease;
}

.general_grow:hover {
  transform: scale(1.02);
}

/* Remove the forced footer background styles to allow proper light/dark mode */
footer {
  transition: all 0.3s ease;
  border-top-width: 1px;
  position: relative;
}

/* Create a smoother transition between main content and footer with a gradient overlay */
footer::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  height: 10px;
  background: linear-gradient(to bottom, transparent, rgba(0,0,0,0.02));
  pointer-events: none;
}

/* Additional slider background settings to ensure no repetition */
.slider-item {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}
</style>
