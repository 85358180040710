<script>
import { ref, onBeforeMount, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useCartGLobalStore } from '@/stores/cartGlobalStore'
import {
  getRestoPaymentSettingFn,
  createOrderPIFn,
  placeCardOrderSCAFn,
  confirmOrderPIFn
} from '@/services/api/restApiCalls'
import { StripeElements, StripeElement } from 'vue-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

export default {
  components: {
    StripeElements,
    StripeElement
  },

  setup() {
    const router = useRouter()
    const restoStore = useRestoGLobalStore()
    const cartStore = useCartGLobalStore()

    let stripeKey = ref('')
    let stripeAccountID = ref('')
    const stripeLoaded = ref(false)
    const cardErrorMsg = ref('')
    const card = ref()
    const elms = ref()
    const payButtonDisabled = ref(false)
    let is_card_processing = ref(false)
    const instanceOptions = ref({
      // https://stripe.com/docs/js/initializing#init_stripe_js-options
    })
    const elementsOptions = ref({
      // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
    })
    const style = {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '18px',
        '::placeholder': {
          color: '#32325d'
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: '#fa755a',
        iconColor: '#fa755a'
      }
      // CardField: {
      //   padding: '5px',
      //   border: '1px solid gray'
      // }
    }
    
    // Add dynamic styles based on theme
    const updateStripeElementsTheme = () => {
      const isDarkMode = document.documentElement.classList.contains('dark')
      if (isDarkMode) {
        style.base.color = '#e5e7eb' // dark text color
        style.base['::placeholder'].color = '#9ca3af' // dark placeholder color
        elementsOptions.value.appearance = { theme: 'night' }
      } else {
        style.base.color = '#32325d' // light text color
        style.base['::placeholder'].color = '#32325d' // light placeholder color
        elementsOptions.value.appearance = { theme: 'stripe' }
      }
    }
    
    const cardOptions = ref({
      // https://stripe.com/docs/stripe.js#element-options
      style: style,
      hidePostalCode: true
    })

    // onMounted(async () => {
    //   let payment_settings = await getRestoPaymentSettingFn(restoStore?.RESTO_ID)
    //   is_card_processing.value = false
    //   // retry to get payment settings
    //   if (!payment_settings?.public_key) {
    //     payment_settings = await getRestoPaymentSettingFn(restoStore?.RESTO_ID)
    //   }

    //   if (payment_settings?.public_key) {
    //     stripeKey.value = payment_settings?.public_key
    //   }
    //   if (payment_settings?.account_id) {
    //     stripeAccountID.value = payment_settings?.account_id
    //     instanceOptions.value.stripeAccount = stripeAccountID.value
    //   }
    // })

    const handleStripePayment = async () => {
      if (is_card_processing.value) {
        return
      }
      is_card_processing.value = true
      let cardElement = card.value.stripeElement
      elms.value.instance
        .createPaymentMethod({
          type: 'card',
          card: cardElement
        })
        .then(async function (result) {
          // Handle result.error or result.paymentMethod
          if (result?.error) {
            cardErrorMsg.value = result?.error?.message
            payButtonDisabled.value = true
          } else {
            let paymentMethodId = result?.paymentMethod?.id

            const place_order = await placeCardOrderSCAFn(restoStore?.RESTO_ID, paymentMethodId)
            let orderStatus = place_order?.order_status
            if (orderStatus == 'REQUIRES_ACTION') {
              let orderId = place_order?.order_id
              let client_secret = place_order?.client_secret
              elms.value.instance.handleCardAction(client_secret).then(async function () {
                const confirm_pi_data = await confirmOrderPIFn(restoStore?.RESTO_ID, orderId)
                if (confirm_pi_data?.status == 200) {
                  let orderId = confirm_pi_data?.order_id
                  is_card_processing.value = false
                  router.push({
                    name: 'order_status',
                    params: { order_id: `${orderId}` }
                  })
                  cartStore.CART_DATA = ''
                }
              })
            } else {
              is_card_processing.value = false
              router.push({
                name: 'order_status',
                params: { order_id: `${place_order?.order_id}` }
              })
              cartStore.CART_DATA = ''
            }
          }
          is_card_processing.value = false
        })
    }

    onBeforeMount(async () => {
      is_card_processing.value = false

      const create_pi_data = await createOrderPIFn(restoStore?.RESTO_ID)

      let payment_settings = await getRestoPaymentSettingFn(restoStore?.RESTO_ID)

      // retry to get payment settings
      if (!payment_settings?.public_key) {
        payment_settings = await getRestoPaymentSettingFn(restoStore?.RESTO_ID)
      }

      if (payment_settings?.public_key) {
        stripeKey.value = payment_settings?.public_key
      }
      if (payment_settings?.account_id) {
        stripeAccountID.value = payment_settings?.account_id
        instanceOptions.value.stripeAccount = stripeAccountID.value
      }

      if (stripeKey.value) {
        // Apply current theme settings
        updateStripeElementsTheme()
        
        const stripePromise = loadStripe(stripeKey.value)
        stripePromise.then(() => {
          stripeLoaded.value = true
        })
        
        // Set up a listener for theme changes
        const handleThemeChange = () => {
          updateStripeElementsTheme()
          // If stripe is already loaded, we might need to re-mount the element
          if (stripeLoaded.value && elms.value) {
            // Force a refresh by toggling the stripeLoaded value
            stripeLoaded.value = false
            setTimeout(() => {
              stripeLoaded.value = true
            }, 10)
          }
        }
        
        // Listen for theme changes
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
        mediaQuery.addEventListener('change', handleThemeChange)
        
        // Also watch for manual theme toggles
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.attributeName === 'class' && 
                mutation.target === document.documentElement) {
              handleThemeChange()
            }
          })
        })
        
        observer.observe(document.documentElement, { attributes: true })
      } else {
        router.push({
          name: 'menu',
          params: { menu_slug: restoStore?.MENU_URL }
        })
      }
    })

    const handleCardChange = (event) => {
      payButtonDisabled.value = event.empty
      let error_msg = event.error ? event.error.message : ''
      cardErrorMsg.value = error_msg
      if (event.brand && event.brand === 'amex') {
        cardErrorMsg.value = 'Amex card is not supported'
        payButtonDisabled.value = true
      }
    }
    return {
      restoStore,
      cartStore,
      stripeLoaded,
      stripeKey,
      stripeAccountID,
      cardErrorMsg,
      card,
      elms,
      instanceOptions,
      elementsOptions,
      cardOptions,
      payButtonDisabled,
      handleStripePayment,
      handleCardChange
    }
  }
}
</script>
<template>
  <StripeElements
    v-if="stripeLoaded"
    v-slot="{ elements, instance }"
    ref="elms"
    :stripe-key="stripeKey"
    :instance-options="instanceOptions"
    :elements-options="elementsOptions"
  >
    <StripeElement
      ref="card"
      :elements="elements"
      :options="cardOptions"
      @change="handleCardChange($event)"
      class="transition-colors duration-200"
    ></StripeElement>
  </StripeElements>
  <div class="text-sm font-medium text-red-600 dark:text-red-400 lblError transition-colors duration-200">{{ cardErrorMsg }}</div>
</template>

<style>
.StripeElement {
  border-radius: 0.375rem;
  padding: 12px;
  border: 1px solid rgba(209, 213, 219, 1);
  height: 44px;
  width: 100%;
  background: white;
  transition: all 0.2s ease;
}

/* Dark mode support for Stripe elements with transition */
@media (prefers-color-scheme: dark) {
  .StripeElement {
    background: #374151; /* dark:bg-gray-700 */
    border-color: #4b5563; /* dark:border-gray-600 */
    color: #e5e7eb; /* dark:text-gray-200 */
  }
}

html.dark .StripeElement {
  background: #374151; /* dark:bg-gray-700 */
  border-color: #4b5563; /* dark:border-gray-600 */
  color: #e5e7eb; /* dark:text-gray-200 */
}

/* Add focus state styling */
.StripeElement--focus {
  border-color: #6366f1; /* indigo-500 */
  box-shadow: 0 0 0 1px rgba(99, 102, 241, 0.5);
}

html.dark .StripeElement--focus {
  border-color: #818cf8; /* dark:indigo-400 */
  box-shadow: 0 0 0 1px rgba(129, 140, 248, 0.5);
}

/* Keep error styling consistent with app theme */
.StripeElement--invalid {
  border-color: #ef4444; /* text-red-500 */
}

html.dark .StripeElement--invalid {
  border-color: #f87171; /* dark:text-red-400 */
}

.btnPay {
  background-color: #6d28d9 !important; /* violet-700 to match the theme */
  transition: background-color 0.2s ease;
}

.btnPay:hover,
.btnPay:focus {
  background-color: #5b21b6 !important; /* violet-800 */
}

html.dark .btnPay {
  background-color: #7c3aed !important; /* dark:violet-600 */
}

html.dark .btnPay:hover,
html.dark .btnPay:focus {
  background-color: #6d28d9 !important; /* dark:violet-700 */
}

.btnPay:disabled,
.btnPay:disabled:hover,
.btnPay:disabled:focus {
  background-color: #d1d5db !important; /* gray-300 */
  cursor: not-allowed;
}

html.dark .btnPay:disabled,
html.dark .btnPay:disabled:hover,
html.dark .btnPay:disabled:focus {
  background-color: #4b5563 !important; /* dark:gray-600 */
}

.lblError {
  margin: 0 !important;
  min-height: 20px;
}
</style>
