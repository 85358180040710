<script>
import AppLayout from '@/layouts/AppLayout.vue'
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { stopSmsFn } from '@/services/api/restApiCalls'

export default {
  components: {
    AppLayout
  },
  setup() {
    const restoStore = useRestoGLobalStore()
    const router = useRouter()
    const phoneNumber = ref('')
    const showThankmsg = ref(false)
    const invalidPhoneNumber = ref(false)

    const validationRules = {
      phoneNumber: {
        required
      }
    }

    const v$ = useVuelidate(validationRules, {
      phoneNumber
    })
    const submitted = ref(false)
    const handleStopSms = async () => {
      submitted.value = true
      v$.value.$validate()
      if (v$.value.$invalid) {
        return
      }
      let stopSmsFormData = new FormData()
      stopSmsFormData.append('phone_number', phoneNumber.value)
      const stopSms_res = await stopSmsFn(restoStore?.RESTO_ID, stopSmsFormData)
      if (stopSms_res?.status == 200) {
        showThankmsg.value = true
      } else if (stopSms_res?.status == 400) {
        invalidPhoneNumber.value = true
      } else {
        invalidPhoneNumber.value = true
      }
    }
    watch(phoneNumber, () => {
      invalidPhoneNumber.value = false
    })

    return {
      restoStore,
      router,
      submitted,
      phoneNumber,
      v$,
      handleStopSms,
      showThankmsg,
      invalidPhoneNumber
    }
  }
}
</script>
<template>
  <AppLayout>
    <div
      class="flex flex-col items-center justify-center sm:px-6 px-3 sm:py-8 py-4 mx-auto pt:mt-0"
    >
      <div class="w-full max-w-2xl p-6 sm:p-8 bg-white rounded-lg shadow">
        <h1 class="text-xl md:text-3xl font-bold text-gray-900 dark:text-white text-center mb-2">
          {{ restoStore?.restoInfo?.name }}
        </h1>
        <h2 class="text-lg md:text-xl font-semibold text-red-700 text-center md:mb-5 mb-3">
          Unsubscribe from Promo SMS
        </h2>
        <hr class="my-3" />
        <div v-if="!showThankmsg">
          <p class="text-lg text-center md:mb-6 mb-4 mx-auto md:w-5/6">
            If you don't want to hear from us about special discount offers, please fill the
            following request form
          </p>
          <div class="mb-4">
            <label for="phoneNumber" class="block text-sm font-medium text-gray-700"
              >Phone Number</label
            >
            <div class="mt-1">
              <input
                id="phoneNumber"
                v-model="phoneNumber"
                name="phoneNumber"
                type="text"
                autocomplete="phoneNumber"
                placeholder="Please enter mobile number to unsubscribe i.e. 075XXX"
                :class="{ 'border-red-600': submitted && v$.phoneNumber.$error }"
                class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
              />
            </div>
            <div v-for="(error, index) of v$.phoneNumber.$errors" :key="index" class="input-errors">
              <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
            </div>
            <div v-if="invalidPhoneNumber" class="text-sm font-medium text-red-600">
              Invalid phone number
            </div>
          </div>
          <button
            type="submit"
            @click="handleStopSms()"
            class="w-full rounded-md border border-transparent bg-violet-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-violet-800 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2"
          >
            Send Request
          </button>
        </div>

        <div v-else class="text-center">
          <!-- <h2 class="text-xl md:text-2xl font-bold text-green-800 text-center mb-1">Thank you!</h2> -->
          <h3 class="text-lg font-semibold text-green-800 text-center md:mb-4 mb-3">
            Thank you for letting us know, phone number has been added to stop sms list.
          </h3>
          <!-- <p class="text-lg text-center md:mb-6 mb-4 mx-auto">
            We will no longer send you SMS of special discount offers
          </p> -->
          <button
            type="submit"
            @click="
              router.push({
                name: 'menu',
                params: { menu_slug: restoStore.MENU_URL }
              })
            "
            class="w-full mx-auto rounded-md border border-transparent bg-violet-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-violet-800 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  </AppLayout>
</template>
