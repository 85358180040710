<script>
import AppLayout from '@/layouts/AppLayout.vue'
import { ref, inject } from 'vue'
import { useRouter } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useAuthGlobalStore } from '@/stores/authGlobalStore'
import { useMobilePushNotification } from '@/composables/MobileNotification'
import { EnvelopeIcon, LockClosedIcon, ExclamationCircleIcon } from '@heroicons/vue/20/solid'

export default {
  components: {
    AppLayout,
    EnvelopeIcon,
    LockClosedIcon,
    ExclamationCircleIcon
  },
  setup() {
    const { addListeners, registerNotifications } = useMobilePushNotification()
    const IS_MOBILE_APP = inject('IS_MOBILE_APP')
    const restoGlobalStore = useRestoGLobalStore()
    const authStore = useAuthGlobalStore()
    const router = useRouter()
    const userEmail = ref('')
    const password = ref('')
    const errorMsg = ref('')

    const validationRules = {
      userEmail: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(5)
      }
    }

    const v$ = useVuelidate(validationRules, {
      userEmail,
      password
    })
    const submitted = ref(false)

    const handleLogin = async () => {
      // if (submitted.value) return
      submitted.value = true
      v$.value.$validate()
      if (v$.value.$invalid) {
        return
      }
      const routeParam = router.currentRoute.value.query.redirect
      const menuSlug = restoGlobalStore.restoInfo.menu_url
      authStore.login(userEmail.value, password.value).then((isLoggedIn) => {
        if (isLoggedIn) {
          if (IS_MOBILE_APP) {
            addListeners()
            registerNotifications()
          }
          if (routeParam) {
            router.push({
              path: routeParam
            })
          } else {
            router.push({ name: 'menu', params: { menu_slug: menuSlug } })
          }
        } else {
          // TODO: show error message
          errorMsg.value = 'Invalid email or password'
        }
      })
      submitted.value = false
    }

    return {
      restoGlobalStore,
      authStore,
      router,
      submitted,
      userEmail,
      password,
      v$,
      handleLogin,
      errorMsg
    }
  }
}
</script>
<template>
  <AppLayout>
    <div
      class="flex flex-col items-center justify-center sm:px-6 px-3 sm:py-12 py-6 mx-auto pt:mt-0"
    >
      <!-- Card -->
      <div class="w-full max-w-xl p-8 bg-white dark:bg-gray-800 rounded-xl shadow-lg transform transition-all duration-200 hover:shadow-xl">
        <div class="text-center mb-10">
          <h2 class="text-3xl font-bold text-gray-900 dark:text-gray-100">Welcome Back</h2>
          <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">Sign in to your account to continue</p>
        </div>
        
        <div v-if="errorMsg" class="mb-6 p-4 rounded-lg bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 dark:border-red-400 transition-all duration-200">
          <div class="flex items-start">
            <div class="flex-shrink-0 pt-0.5">
              <ExclamationCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div class="ml-3">
              <div class="text-sm font-medium text-red-600 dark:text-red-400">{{ errorMsg }}</div>
            </div>
          </div>
        </div>
        
        <form class="space-y-6" @submit.prevent="handleLogin">
          <div>
            <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-100"
              >Email address</label
            >
            <div class="relative">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <EnvelopeIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
              </div>
              <input
                type="email"
                name="email"
                id="email"
                class="block w-full appearance-none rounded-lg border border-gray-300 dark:border-gray-600 pl-10 px-4 py-3 placeholder-gray-400 dark:placeholder-gray-500 shadow-sm focus:border-violet-500 focus:ring focus:ring-violet-200 dark:focus:ring-violet-800/20 focus:ring-opacity-50 focus:outline-none text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 transition-all duration-200"
                :class="{ 'border-red-600 dark:border-red-500 focus:border-red-600 focus:ring-red-200 dark:focus:ring-red-800/20': submitted && v$.userEmail.$error }"
                placeholder="name@company.com"
                v-model="userEmail"
              />
            </div>
            <div v-for="(error, index) of v$.userEmail.$errors" :key="index" class="input-errors mt-1">
              <div class="text-sm font-medium text-red-600 dark:text-red-400">{{ error.$message }}</div>
            </div>
          </div>
          <div>
            <div class="flex items-center justify-between mb-2">
              <label for="password" class="text-sm font-medium text-gray-900 dark:text-gray-100"
                >Password</label
              >
              <router-link
                class="text-sm font-medium text-violet-700 dark:text-violet-400 hover:text-violet-800 dark:hover:text-violet-300 transition-colors duration-200"
                :to="{ name: 'forgotPassword' }"
                >Forgot password?</router-link
              >
            </div>
            <div class="relative">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <LockClosedIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
              </div>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="••••••••"
                class="block w-full appearance-none rounded-lg border border-gray-300 dark:border-gray-600 pl-10 px-4 py-3 placeholder-gray-400 dark:placeholder-gray-500 shadow-sm focus:border-violet-500 focus:ring focus:ring-violet-200 dark:focus:ring-violet-800/20 focus:ring-opacity-50 focus:outline-none text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 transition-all duration-200"
                :class="{ 'border-red-600 dark:border-red-500 focus:border-red-600 focus:ring-red-200 dark:focus:ring-red-800/20': submitted && v$.password.$error }"
                v-model="password"
              />
            </div>
            <div v-for="(error, index) of v$.password.$errors" :key="index" class="input-errors mt-1">
              <div class="text-sm font-medium text-red-600 dark:text-red-400">{{ error.$message }}</div>
            </div>
          </div>
          <div class="flex items-center">
            <input
              id="remember"
              aria-describedby="remember"
              name="remember"
              type="checkbox"
              class="w-4 h-4 text-violet-600 border-gray-300 rounded focus:ring-violet-500 dark:focus:ring-violet-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 transition-colors duration-200"
            />
            <label for="remember" class="font-medium text-gray-700 dark:text-gray-300 text-sm ml-2"
              >Remember me</label
            >
          </div>
          <button
            type="submit"
            class="w-full justify-center rounded-lg border border-transparent bg-violet-700 py-3 px-6 text-base font-medium text-white shadow-sm hover:bg-violet-800 dark:hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 transition-all duration-200 transform hover:-translate-y-0.5"
          >
            Sign in
          </button>

          <div class="relative mt-6">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-200 dark:border-gray-700"></div>
            </div>
            <div class="relative flex justify-center text-sm font-medium leading-6">
              <span class="bg-white dark:bg-gray-800 px-6 text-gray-500 dark:text-gray-400">Don't have an account?</span>
            </div>
          </div>

          <div class="text-center">
            <router-link
              class="inline-block font-medium text-violet-700 dark:text-violet-400 text-base hover:text-violet-800 dark:hover:text-violet-300 transition-colors duration-200"
              :to="{ name: 'signup' }"
              >Create a free account</router-link
            >
          </div>
        </form>
      </div>
    </div>
  </AppLayout>
</template>
